import React, { useState } from 'react';
import { Table, Input, Form, Space, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { PrivilegedButton } from './../../../../component/privileged-button';
import { useChangePrice } from '../../../../utils/miniproject-admin/stadium-display';
import { message } from 'antd';

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({ editable, children, dataIndex, record, retry, ...restProps }) => {
  const [editing, setEditing] = useState(false)
  const [backNum, setBackNum] = useState()
  const { changePrice } = useChangePrice() //修改价格

  const onChange = (e) => {
    if (e.target.value < 0) {
      message.error('请输入正确价格')
      return
    }
    setBackNum(e.target.value)
  }
  const handleSave = (id) => {
    changePrice({
      id,
      orderPrice: backNum || record[dataIndex]
    }).then(res => {
      if (res.code === 200) {
        retry()
        message.success(res.msg)
      } else {
        message.error(res.msg)
      }
    })
  }

  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <div className='flex'>
        <Input
          type='number'
          defaultValue={record[dataIndex]}
          style={{ width: '60%', marginRight: '15px' }}
          onChange={onChange}
        />
        <Space size={[10, 0]}>
          <Typography.Link onClick={() => {
            handleSave(record.id)
            setEditing(false)
          }}>保存</Typography.Link>
          <Typography.Text
            type="secondary"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setEditing(false)
            }}
          >取消</Typography.Text>
        </Space>
      </div>
    ) : (
      <div className='flex'>
        <span className="mr-10">{record[dataIndex]}</span>
        <PrivilegedButton code={'club:placePrice:edit'}>
          <div className='edit_icon'>
            <EditOutlined onClick={() => {
              setEditing(true)
            }} />
          </div>
        </PrivilegedButton>
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export const EditableTablePrice = ({ priceData, isLoading, retry }) => {

  const columns = [
    {
      title: '打位ID',
      dataIndex: 'id'
    },
    {
      title: '打位名称',
      dataIndex: 'placeName',
    },

    {
      title: '打位类型',
      dataIndex: 'placeTypeDict',
    },
    {
      title: '预约价格（元 / 0.5h）',
      dataIndex: 'orderPrice',
      editable: true,
      width: 300
    }
  ]
  const editColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        retry
      }),
    }
  })
  return (
    <div>
      <Table
        components={
          {
            body: {
              row: EditableRow,
              cell: EditableCell,
            },
          }
        }
        loading={isLoading}
        rowKey={record => record.id}
        dataSource={priceData?.rows}
        columns={editColumns}
        pagination={{ pageSize: priceData?.pageSize, total: priceData?.total, showQuickJumper: true, pageSizeOptions: [5, 10, 20, 30, 40, 50] }}
      />
    </div>
  );
}


