import { BreadComponent } from '../../component/breadcrumb';
import { Button, Form, Input, Space, Typography, Tag, Spin, message, Pagination } from "antd";
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { DrawerModal } from './../../component/drawer-modal';
import { useState } from 'react';
import { GoodsEdit } from './component/lib/goods'
import { showDeleteConfirm } from './../../utils/common';
import { useGoodsList, useDeleteGoods } from './../../utils/miniproject-admin/goods-info';
import { EmptyBox } from './../../component/lib';
import { useAuth } from '../../context/auth-context';
import { useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { PrivilegedButton } from './../../component/privileged-button';
import { RefreshIcon, TypeIcon } from './../../component/custom-icon';

export const GoodsInfo = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { user } = useAuth()
  const breadcrumbNameMap = [
    {
      name: '商品信息',
      url: ''
    },
  ]
  const [state, dispatch] = useReducer((state, action) => ({ ...state, ...action }), {
    merchantId: user?.user.merchantId,
    goodsName: '',
    page: 1,
    pageSize: 20
  })
  const { pageSize } = state
  const { data: goodsListData, isLoading, retry } = useGoodsList(state)//获取商品列表
  const { deleteGoods } = useDeleteGoods() //商品删除
  const goodsList = goodsListData?.rows
  const [modeType, setModeType] = useState() //添加，删除，类别组件区分
  const [visible, setVisible] = useState(false) //弹框显示和隐藏
  const [good, setGood] = useState()
  const onChangePage = (page, pageSize) => {
    dispatch({
      page,
      pageSize
    })
  }

  //搜索
  const onChangeGoodsName = (value) => {
    dispatch({
      goodsName: value.goodsName
    })
  }
  return (
    <div className='goods_info_main grid_display'>
      <div className='breadcrumb'>
        <BreadComponent breadcrumbNameMap={breadcrumbNameMap} />
      </div>
      <Spin size="large" spinning={isLoading}>
        <div className='page_main'>
          <div className="search_box">
            <Form form={form} layout='inline' onFinish={onChangeGoodsName} autoComplete="off">
              <Form.Item name={'goodsName'}>
                <Input placeholder="输入商品名称" allowClear />
              </Form.Item>
              <Space>
                <Button htmlType='reset'>重置</Button>
                <Button type='primary' htmlType='submit'>查询</Button>
              </Space>
            </Form>
          </div>
          <div className="page_inner">
            <div className="flex-btw table_hd">
              <h5>商品信息列表</h5>
              <Space size={20}>
                <div className='flex-center setting' onClick={() => {
                  navigate('/home/miniproject/goods_info/goods_type')
                }}>
                  <TypeIcon />
                  <span>类别</span>
                </div>
                <div className='flex-center setting' onClick={() => retry()}>
                  <RefreshIcon />
                  <span className='refresh'>刷新</span>
                </div>
                <PrivilegedButton code="wechat:goods:update">
                  <Button type='primary' icon={<PlusOutlined />} onClick={() => {
                    setModeType(1)
                    setVisible(true)
                  }}>添加商品</Button>
                </PrivilegedButton>
              </Space>
            </div>
            {
              goodsList?.length > 0 ? (
                <div>
                  <div className='goods_list'>
                    {
                      goodsList?.map((item, index) => <div className='goods_item' key={index}>
                        <div className='goods_inner'>
                          <div className='goods_item_img flex-center'>
                            <img src={item.picture ? item.picture[0]?.multimediaUrl : require('../../assets/imgs/miniproject/goods.png')} alt="" />
                          </div>
                          <div className='goods_item_title flex'>
                            {
                              item?.typeDict ? <Tag color="#48C79C">{item?.typeDict}</Tag> : null
                            }
                            <h5>商品：{item.goodsName}</h5>
                          </div>
                          <Typography.Title level={4}>￥{item.price}</Typography.Title>
                          <p className='goods_count'>库存：{item.stockNum}</p>
                        </div>
                        <PrivilegedButton code="wechat:goods:update">
                          <div className='goods_setting flex'>
                            <div className='goods_edit' onClick={() => {
                              setModeType(2)
                              setVisible(true)
                              setGood(item)
                            }}>
                              <EditOutlined />
                            </div>
                            <span className='setting_bold'>|</span>
                            <div className='goods_delete' onClick={() => {
                              showDeleteConfirm('点击删除将删除该商品全部信息').then((res => {
                                if (res.code === 'ok') {
                                  deleteGoods(item.id).then(res => {
                                    if (res.code === 200) {
                                      message.success('删除成功')
                                      retry()
                                    }
                                  })
                                }
                              }))
                            }}>
                              <DeleteOutlined />
                            </div>
                          </div>
                        </PrivilegedButton>
                      </div>)
                    }
                  </div>
                  <div className='mt-30 pagination_position'>
                    <Pagination
                      total={goodsListData?.total}
                      pageSize={pageSize}
                      showSizeChanger
                      showQuickJumper={true}
                      onChange={onChangePage}
                    />
                  </div>
                </div>
              ) : <EmptyBox />
            }

          </div>
        </div>
      </Spin>
      <DrawerModal
        title={modeType === 1 ? '添加商品' : '编辑商品'}
        visible={visible}
        setVisible={setVisible}>
        <GoodsEdit
          retry={retry}
          modeType={modeType}
          setVisible={setVisible}
          visible={visible}
          good={good}
        />
      </DrawerModal>
    </div>
  )
}