
import React, { useContext } from 'react';
import { useAsync } from '../utils/use-async';
import * as Auth from '../auth-provider'
import { PageCallback, PageLoading } from '../component/lib';
import { http } from '../utils/http';
import { useMount } from '../utils';

const AuthContext = React.createContext(undefined);

AuthContext.displayName = 'MyAuthContext'

export const bootstrapUser = async () => {
  let user = null
  const token = Auth.getToken()
  if (token) {
    const res = await http('golfonlineAuth/getLoginUser', { token })
    user = res.data
  }
  return user
}

export const AuthProvider = ({ children }) => {
  const { data: user, error, isLoading, isIdle, isError, run, setData: setUser } = useAsync()
  const login = (formData) => Auth.login(formData).then((user) => {
    setUser(user)
    return user
  }).catch(error => {
    return Promise.reject(error)
  })
  const logout = () => Auth.logout().then(() => setUser(null))

  useMount(async () => {
    run(bootstrapUser())
  })

  if (isIdle || isLoading) {
    return <PageLoading />
  }

  if (isError) {
    return <PageCallback error={error} />
  }

  return <AuthContext.Provider children={children} value={{ user, login, logout }} />
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth必须在AuthProvider中使用')
  }
  return context
}