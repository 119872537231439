
import { useAsync } from './../use-async';
import { useHttp } from '../http';
import { useEffect, useCallback } from 'react';

//获取广播设置的基础信息
export const useBroadcasts = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/clubBroadcast/list`), [client])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}

//编辑广播设置
export const useEditBroadcasts = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const editBroadcasts = (params) => {
    return run(client(`golfonlineClub/clubBroadcast`, {
      method: 'PUT',
      data: params
    }))
  }
  return {
    ...result,
    editBroadcasts
  }
}


//锁屏设置查询
export const useLockScreen = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/clubLockSet/query`), [client])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}

//提交锁屏设置
export const useEditLockScreen = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const editLockScreen = (params) => {
    return run(client(`golfonlineClub/clubLockSet`, {
      method: 'PUT',
      data: params
    }))
  }
  return {
    ...result,
    editLockScreen
  }
}

