
import { Table } from 'antd';
import { useOperationalDetail } from '../../../utils/data-count';
import { useReducer } from 'react';
import { useEffect } from 'react';
import { formatTime } from './../../manager-admin/component/club-place-status';

const expandedRowRender = ({detailList}) => {
  const expandedColumns = [
    {
      title: '编号',
      dataIndex: 'projectId',
    },
    {
      title: '详情内容',
      dataIndex: 'projectName',
    },
    {
      title: '次数 / 次',
      dataIndex: 'playTimes',
    },
    {
      title: '时长 / h',
      dataIndex: 'duration',
      render: (value) => {
        return <>
          {formatTime(value)}
        </>
      }
    },
    {
      title: '收益 (元)',
      dataIndex: 'profit',
    },
  ]
  return <Table
    columns={expandedColumns}
    dataSource={detailList}
    colSpan={0}
    pagination={false}
    bordered={true}
    rowKey={(record) => record.projectId}
    size={'small'}
  />;
}

export const OperationalDetail = ({ placeId }) => {
  const columns = [
    {
      title: '项目名称',
      dataIndex: 'typeName',
    },
    {
      title: '使用次数',
      dataIndex: 'playTimes',
    },
    {
      title: '使用时长 / h',
      dataIndex: 'duration',
      render: (value) => {
        return <>
          {formatTime(value)}
        </>
      }
    },
    {
      title: '收益 (元)',
      dataIndex: 'profit',
    }
  ]
  const [state, dispatch] = useReducer((state, action) => ({ ...state, ...action }), {
    placeId,
    page: 1,
    pageSize: 10
  })
  const { data, isLoading } = useOperationalDetail(state)
  useEffect(() => {
    dispatch({ placeId })
  }, [placeId])
  return (
    <Table
      columns={columns}
      dataSource={data?.rows}
      pagination={false}
      loading={isLoading}
      rowKey={(record) => record.typeId}
      expandable={{
        expandedRowRender: (record) => expandedRowRender(record),
        rowExpandable: (record) => {
          return record?.detailList.length
        },
      }}
    />
  )
}