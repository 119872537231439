
import { useEffect, useRef } from "react"

//这里将value为0的情况排除，如果为undefined/null/空则删除掉
export const isFaily = (value) => value === 0 ? false : !value
export const isVoid = (value) => value === undefined || value === null || value === ''

// 如果用Object，函数等也是对象，用键值对，锁住键值对的格式
export const clearObject = (object) => {
  const result = { ...object }
  Object.keys(result).forEach((key) => {
    let value = result[key]
    if (isFaily(value)) {
      if (isVoid(value)) {
        delete result[key]
      }
    }
  })
  return result
}

//组件卸载之后，停止正在执行的代码
export const useMountRef = () => {
  const mountedRef = useRef(false)
  useEffect(() => {
    mountedRef.current = true
    return () => {
      mountedRef.current = false
    }
  }, [])
  return mountedRef
}

export const useMount = (callback) => {
  return useEffect(() => {
    callback()
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [])
}