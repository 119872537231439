import { useAsync } from './../use-async';
import { useHttp } from '../http';
import { useEffect } from 'react';
import { useCallback } from 'react';

//查询订单信息列表
export const useOrderInfoList = (placeId, orderId) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/clubAppointment/order/list`, {
    data: {
      placeId,
      orderId
    }
  }), [client, placeId, orderId])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}

//根据电话查询会员
export const useMembers = (params) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/member/telephone`, {
    data: {
      ...params
    }
  }), [client, params])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}

//修改手机号
export const useChangeTelephone = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const changeTelephone = (params) => {
    return run(client(`golfonlineClub/clubAppointment/order/updateTelephone`, {
      method: 'PUT',
      data: params
    }))
  }
  return {
    ...result,
    changeTelephone
  }
}

//操作上机
export const useOpenDevice = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const openDevice = (id) => {
    return run(client(`golfonlineClub/clubAppointment/openDevice/${id}`, {
      method: 'PUT'
    }))
  }
  return {
    ...result,
    openDevice
  }
}

//操作下机
export const useCloseDevice = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const closeDevice = (id) => {
    return run(client(`golfonlineClub/clubAppointment/closeDevice/${id}`, {
      method: 'PUT'
    }))
  }
  return {
    ...result,
    closeDevice
  }
}

//操作续时
export const useContinuationDevice = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const continuationDevice = (params) => {
    return run(client(`golfonlineClub/clubAppointment/continuation/`, {
      method: 'PUT',
      data: params
    }))
  }
  return {
    ...result,
    continuationDevice
  }
}

//操作返场
export const useGoBackDevice = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const goBackDevice = (params) => {
    return run(client(`golfonlineClub/clubAppointment/encore`, {
      method: 'PUT',
      data: params
    }))
  }
  return {
    ...result,
    goBackDevice
  }
}

//查询消费列表
export const useConsumptionList = (orderId) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/clubPlayOrder/list`, {
    data: {
      orderId
    }
  }), [client, orderId])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}

//编辑打球消费消费
export const useEditConsumption = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const editConsumption = (params) => {
    return run(client(`golfonlineClub/clubPlayOrder`, {
      method: 'PUT',
      data: params
    }))
  }
  return {
    ...result,
    editConsumption
  }
}

//修改打球消费的扣费模式
export const useChangePlayMode = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const changePlayMode = (params) => {
    return run(client(`golfonlineClub/clubPlayOrder/changeMode`, {
      method: 'PUT',
      data: params
    }))
  }
  return {
    ...result,
    changePlayMode
  }
}

//商店消费列表
export const useShopBuyList = (orderId, merchantId) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/consumption/list`, {
    data: {
      orderId,
      merchantId
    }
  }), [client, orderId, merchantId])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}

//退单
export const useChangeBackNum = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const changeBackNum = (params) => {
    return run(client(`golfonlineClub/consumption`, {
      method: 'PUT',
      data: params
    }))
  }
  return {
    ...result,
    changeBackNum
  }
}

//手动下单
export const useAddGoodOrder = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const addGoodOrder = (params) => {
    return run(client(`golfonlineClub/consumption/manual`, {
      method: 'POST',
      data: params
    }))
  }
  return {
    ...result,
    addGoodOrder
  }
}

//查询商品单价
export const useGoodPrice = (id) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  useEffect(() => {
    if(id){
      run(client(`golfonlineClub/goods/${id}`))
    }    
  }, [client, run, id])
  return result
}

//查询商品名称
export const useGoodsName = (merchantId) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  useEffect(() => {
    run(client(`golfonlineClub/goods/list/dict`, {
      data: {
        merchantId
      }
    }))
  }, [merchantId, run, client])
  return result
}

//查询结账抵扣卡
export const useOrderCards = (id, visible) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  useEffect(() => {
    run(client(`golfonlineClub/member/card/order/${id}`))
  }, [id, run, client, visible])
  return result
}

//查询结账订单信息
export const useOrderSettlement = (id, visible) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  useEffect(() => {
    if (id && visible) {
      run(client(`golfonlineClub/clubAppointment/order/settlement/${id}`))
    }
  }, [id, run, client, visible])
  return result
}

//结账
export const useCheckOut = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const checkOut = (params) => {
    return run(client(`golfonlineClub/clubAppointment/order/settlement`, {
      method: 'PUT',
      data: params
    }))
  }
  return {
    ...result,
    checkOut
  }
}