import { Form, Input } from "antd"
import { UploadFile } from '../../../../component/upload-file';
import { Footer } from '../../../../component/drawer-modal'
import { useGoodsTypes, useAddGoods, useEditGoods, useAddGoodsType, useEditGoodsType, useGoodsType } from './../../../../utils/miniproject-admin/goods-info';
import { useAuth } from './../../../../context/auth-context';
import { PutErrorBox } from '../../../../component/lib'
import { IdSelect } from '../../../../component/id-select'
import { useEffect, useState } from 'react';

export const GoodsEdit = ({ modeType, retry, good, setVisible, visible }) => {
  const [form] = Form.useForm()
  const { user } = useAuth()
  const merchantId = user?.user?.merchantId
  const { addGoods } = useAddGoods() //添加商品
  const { data: goodsTypeData } = useGoodsTypes(merchantId)//获取商品类别列表
  const options = goodsTypeData?.rows
  const { editGoods } = useEditGoods() //编辑商品
  const [error, setError] = useState()
  const [pictureList, setPictureList] = useState([]) //上传图片的列表
  const [fileLists,setFileLists] = useState([]) //获取的商品图片列表

  //提交
  const onFinish = (value) => {
    let picture = []
    const oldPicture = [...pictureList]
    if (oldPicture.length) {
      if (oldPicture[0].response) {
        picture.push(oldPicture[0].response.data)
        // if (oldPicture?.fileList.length) {
        //   picture.push(oldPicture.file.response.data)
        // } else {
        //   picture = oldPicture.fileList
        // }
      } else {
        picture = oldPicture
      }
    }
    const params = {
      ...value,
      picture,
      merchantId
    }
    // console.log(params)
    if (modeType === 2) {
      editGoods(params).then(res => {
        if (res.code === 200) {
          retry()
          setVisible(false)
        } else {
          setError(res)
        }
      })
    } else {
      addGoods(params).then(res => {
        if (res.code === 200) {
          retry()
          setVisible(false)
        } else {
          setError(res)
        }
      })
    }
  }

  //编辑初始化表单
  useEffect(() => {
    if (modeType === 2) {
      form.setFieldsValue(good)
    } else {
      form.resetFields()
    }
    setError(null)
    //区分编辑和添加
    if(modeType ===2){
      setFileLists(good?.picture)
    } else {
      setFileLists([])
    }  
  }, [form, modeType, good, visible])

  return (
    <div className="goods_edit_main">
      <PutErrorBox error={error} setError={setError} />
      <Form
        autoComplete="off"
        form={form}
        layout='horizontal'
        onFinish={onFinish}
        labelCol={{
          span: 3,
        }}
      >
        <UploadFile
          accept={'.png, .jpg, .jpeg'}
          maxCount={1}
          param="goods"
          name="picture"
          fileLists={fileLists}
          visible={visible}
          label="商品图片"
          remark={true}
          aspect={176 / 176}
          fileList={pictureList}
          setFileList={setPictureList}
        />
        <Form.Item label="商品名" name="goodsName" rules={[{ required: true, message: '请输入商品名' }]}>
          <Input placeholder="请输入商品名" allowClear />
        </Form.Item>
        {
          modeType !== 1 ? <Form.Item name='id' label="商品ID">
            <Input disabled />
          </Form.Item> : null
        }
        <Form.Item label="类别" name={'type'} rules={[{ required: true, message: '请选择商品类型' }]}>
          <IdSelect options={options} />
        </Form.Item>
        <Form.Item label="价格" name='price' rules={[
          { required: true, message: '请选择商品类型' },
          () => (
            {
              validator(_, value) {
                if (value < 0) {
                  return Promise.reject(new Error('请输入正确价格'));
                } else {
                  return Promise.resolve()
                }
              }
            }
          )]}>
          <Input placeholder="请输入价格" type={'number'} allowClear />
        </Form.Item>
        <Form.Item
          label="库存"
          name='stockNum'
          rules={[
            () => (
              {
                validator(_, value) {
                  if (value < 0) {
                    return Promise.reject(new Error('请输入正确数量'));
                  } else {
                    return Promise.resolve()
                  }
                }
              }
            )]}>
          <Input placeholder="请输入库存数量" type={'number'} allowClear />
        </Form.Item>
        <Footer />
      </Form>
    </div>
  )
}


export const GoodsTypeEdit = ({ isEdit, goodsTypeId, setVisible, retry }) => {
  const [form] = Form.useForm()
  const { user } = useAuth()
  const merchantId = user.user.merchantId
  const { data: goodsType } = useGoodsType(goodsTypeId)
  const { addGoodsType } = useAddGoodsType()//添加商品类型
  const { editGoodsType } = useEditGoodsType() //删除商品类型
  const [error, setError] = useState()

  const onFinish = (value) => {
    if (isEdit) {
      editGoodsType({
        ...value,
        merchantId
      }).then(res => {
        if (res.code === 200) {
          setVisible(false)
          retry()
        } else {
          setError(res)
        }
      })
    } else {
      addGoodsType({
        ...value,
        merchantId
      }).then(res => {
        if (res.code === 200) {
          setVisible(false)
          retry()
        } else {
          setError(res)
        }
      })
    }
  }

  useEffect(() => {
    if (isEdit) {
      form.setFieldsValue(goodsType?.data)
    } else {
      form.resetFields()
    }
    setError(null)
  }, [form, isEdit, goodsType?.data])

  return <div>
    <PutErrorBox error={error} setError={setError} />
    <Form
      onFinish={onFinish}
      form={form}
      layout='horizontal'
      labelCol={{
        span: 3,
      }}>
      {
        isEdit ?
          <Form.Item label="商品ID" name='id'>
            <Input placeholder="请输入卡片类型" disabled />
          </Form.Item> : null
      }
      <Form.Item label="类别" name='goodsTypeName' >
        <Input placeholder="请输入类别名称" allowClear />
      </Form.Item>
      <Footer />
    </Form>
  </div>
}