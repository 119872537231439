import { Pie, Line } from '@ant-design/plots';

export const DemoPie = ({ title, data, color }) => {
  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    color: color ? color : null,
    radius: 0.9,
    pieStyle: {
      lineWidth: 0,

    },

    autoFit: true,
    tooltip: {
      showTitle: true,
      title: title
    },
    label: {
      type: 'spider',
      content: '{name} {percentage}',
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    legend: {
      layout: 'horizontal',
      position: 'bottom',
      itemHeight: 15,
      offsetY: 5,
      itemSpacing: 20
    },
    interactions: [
      {
        type: 'element-active',
      },
      {
        type: 'element-selected',
      },
    ],
  };
  return <Pie {...config} />;
};

export const DemoLine = ({ title, data, color,text }) => {

  const config = {
    data,
    xField: 'type',
    yField: 'value',
    yAxis:{
      title:{
        text,
        position:'end',
        autoRotate:true
      }
    },
    tooltip: {
      showTitle: true,
      formatter: (datum) => {
        return { name:text, value: datum.value};
      },
    },
    label:{

    },
    legend: {
      layout: 'horizontal',
      position: 'bottom',
      itemHeight: 15,
      offsetY: 5,
      itemSpacing: 20
    },
    point: {
      size: 3,
      shape: 'circle',
      color:'#5B8FF9',
      style: {
        fill: 'white',
        stroke: '#5B8FF9',
        lineWidth: 2,
      },
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: '#000',
          fill: 'red',
        },
      },
    },
    interactions: [
      {
        type: 'marker-active',
      },
    ],
  };
  return <Line {...config} />
}