
import { BreadComponent } from './../../component/breadcrumb';
import { Typography, Form, Space, Button, DatePicker, Table } from 'antd';
import { useReducer, useEffect } from 'react';
import { useAuth } from '../../context/auth-context';
import { useIncomeStatistics, useIncomeDetail } from './../../utils/miniproject-admin/income-statistics';
import moment from 'moment'
import { RefreshIcon } from './../../component/custom-icon';
import { onTableChange } from '../../utils/common';
const { RangePicker } = DatePicker;

export const IncomeStatistics = () => {
  const [form] = Form.useForm()
  const { user } = useAuth()
  const [state, dispatch] = useReducer((state, action) => ({ ...state, ...action }), {
    merchantId: user?.user.merchantId,
    startTime: new Date().getTime() - 24 * 3600 * 1000,
    endTime: new Date().getTime(),
    page: 1,
    pageSize: 10
  })
  const { startTime, endTime, merchantId } = state
  const { data: incomeStatistics } = useIncomeStatistics(startTime, endTime, merchantId) //获取总收益
  const { data: incomeDetail, retry, isLoading } = useIncomeDetail(state)//获取收益详情

  const breadcrumbNameMap = [
    {
      name: '预约金统计',
      url: ''
    },
  ]
  //表格
  const columns = [
    {
      title: '订单时间',
      dataIndex: 'createTime',
      sorter: (a, b) => new Date(a.createTime).getTime() - new Date(b.createTime).getTime()
    },
    {
      title: '订单编号',
      dataIndex: 'orderNo',
      sorter: (a, b) => a.orderNo.localeCompare(b.orderNo)
    },
    {
      title: '预约人',
      dataIndex: 'appointmentPerson',
      sorter: (a, b) => a.appointmentPerson.localeCompare(b.appointmentPerson)
    },
    {
      title: '预约金额',
      dataIndex: 'appointmentCapital',
    },
    {
      title: '状态',
      dataIndex: 'statusDict',
    },
  ];
  const onFinish = (value) => {
    const { date } = value
    dispatch({
      startTime: date ? new Date(date[0]).getTime() : undefined,
      endTime: date ? new Date(date[1]).getTime() : undefined
    })
  }
  useEffect(() => {
    const date = []
    const startTime = moment(state.startTime)
    const endTime = moment(state.endTime)
    date[0] = startTime
    date[1] = endTime
    form.setFieldsValue({
      date
    })
  }, [form,state])
  return (
    <div className="income_statics_main">
      <div className='breadcrumb'>
        <BreadComponent breadcrumbNameMap={breadcrumbNameMap} />
        <Typography.Title level={4}>总额：{incomeStatistics?.data.income || 0} 元</Typography.Title>
      </div>
      <div className='page_main'>
        <div className="search_box">
          <Form form={form} layout='inline' onFinish={onFinish}>
            <Form.Item name={'date'}>
              <RangePicker />
            </Form.Item>
            <Space>
              <Button htmlType='reset'>重置</Button>
              <Button type='primary' htmlType='submit'>查询</Button>
            </Space>
          </Form>
        </div>
        <div className="page_inner">
          <div className="flex-btw table_hd">
            <h5>收益详情</h5>
            <div className='flex-center setting refresh' onClick={() => retry()}>
              <RefreshIcon />
              <span>刷新</span>
            </div>
          </div>
          <Table
            loading={isLoading}
            columns={columns}
            dataSource={incomeDetail?.rows}
            rowKey={record => record.id}
            onChange={(pagination) => onTableChange(pagination, dispatch)}
            pagination={{ pageSize: state.pageSize, total: incomeDetail?.total, showQuickJumper: true, pageSizeOptions: [5, 10, 20, 30, 40, 50] }}
          />
        </div>
      </div>
    </div>
  )
}