// import { Spin } from "antd"
import { OrderUnsettleItem } from './lib/unsettle-item';

export const UnsettledList = ({ type, setVisible, isLoading, settledOrder, error }) => {
  const orderList = settledOrder?.rows

  return (
    <div className="unsettled_list_main" style={{ height: orderList?.length ? 'auto' : '100%' }}>
      {/* <Spin spinning={isLoading}> */}
      {
        orderList?.length ?
          orderList?.map((item, index) => {
            return <OrderUnsettleItem orderDetail={item} key={index} setVisible={setVisible} />
            // return type === 'index' ? <IndexUnsettleItem orderDetail={item} key={index} /> :
            //   <OrderUnsettleItem orderDetail={item} key={index} setVisible={setVisible} />
          })
          : <div className='empty_inner flex-column'>
            <img src={require('../../../assets/imgs/empty.png')} alt="" />
            <p>{error?.msg}</p>
          </div>
      }
      {/* </Spin> */}
    </div >
  )
}