import { Form, Checkbox, Tree, message } from "antd"
import { useState } from 'react';
import { Footer } from "../../../../component/drawer-modal";
import { useEffect } from 'react';
import { flatten, recurrence } from './../../../../utils/common';
import { useQueryTree, useChangeLimits } from './../../../../utils/business-admin/admin';
import { GetErrorBox, PutErrorBox } from "../../../../component/lib";

export const AuthSelect = ({ activeKey, visible, setVisible }) => {
  const [form] = Form.useForm()
  const [all, setAll] = useState() //全选/不全选
  const [checkStrictly, setCheckStrictly] = useState(true) //父子组件是否关联
  const [expandedKeys, setExpandedKeys] = useState([]); //展开的key
  const [checkedKeys, setCheckedKeys] = useState([]); //选中的key
  const { data, error, setError } = useQueryTree(activeKey, visible) //查询树结构
  const checked = data?.checked
  const menus = data?.menus
  const { changeLimits, isLoading, error: changeError, setError: setChangeError } = useChangeLimits() //修改权限

  //点击展开
  const onExpand = (expandedKeysValue) => {
    console.log('onExpand', expandedKeysValue);
    setExpandedKeys(expandedKeysValue);
  };
  //选择复选框时触发
  const onCheck = (checkedKeysValue) => {
    console.log('onCheck', checkedKeysValue);
    setCheckedKeys(checkedKeysValue);
  };

  //提交
  const onFinish = () => {
    const menuIds = checkedKeys.length ? checkedKeys : []
    const params = {
      roleId: activeKey,
      menuIds
    }
    changeLimits(params).then(res => {
      if (res.code === 200) {
        setVisible(false)
      }
    })
  }

  useEffect(() => {
    const keys = recurrence(menus)
    setAll(flatten(keys))
    setCheckedKeys(checked)
  }, [checked, menus])
  return (
    <div className='auth_select'>
      <PutErrorBox error={error || changeError} setError={setError || setChangeError} />
      <div className="flex mb-30">
        <h5 className="auth_select_title">功能权限</h5>
        <Checkbox onChange={(e) => {
          if (e.target.checked) {
            setExpandedKeys(all)
          } else {
            setExpandedKeys([])
          }
        }}>展开/折叠</Checkbox>
        <Checkbox onChange={(e) => {
          if (e.target.checked) {
            setCheckedKeys(all)
          } else {
            setCheckedKeys([])
          }
        }}>全选/全不选</Checkbox>
        <Checkbox checked={checkStrictly} onChange={(e) => {
          setCheckStrictly(e.target.checked)
        }}>父子联动</Checkbox>
      </div>
      <div className="tree_box">
        <Form form={form}>
          <Tree
            checkable
            checkStrictly={!checkStrictly}
            onExpand={onExpand}
            expandedKeys={expandedKeys}
            onCheck={onCheck}
            height={600}
            checkedKeys={checkedKeys}
            treeData={menus}
          />
          <Footer onFinish={onFinish} isLoading={isLoading} btnText="取消" onCancel={() => setVisible(false)} />
        </Form>
      </div>

    </div>
  )
}