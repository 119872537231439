import { useAsync } from './../use-async';
import { useHttp } from '../http';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { clearObject } from './../index';


//消费数据列表
export const useBuyData = (params) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/dataStatistics/queryConsumeDataList`,{
    data:{
      ...clearObject(params)
    }
  }), [client,params])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}

//登录账号
export const useLoginUser = (orderId) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/dataStatistics/querySimulattorLoginUser/${orderId}`), [client,orderId])
  useEffect(() => {
    if(orderId){
      run(fetchPromise(), { retry: fetchPromise })
    }   
  }, [client, run, fetchPromise,orderId])
  return result
}

//打球明细
export const usePlayDetail = (orderId) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/clubPlayOrder/list`,{
    data:{
      orderId
    }
  }), [client,orderId])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}

