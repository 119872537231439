
import { BreadComponent } from './../../component/breadcrumb';
import { useState } from 'react';
import { Spin, Typography } from 'antd';
import { useMerchantId } from './../../utils/common';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import { DrawerModal } from './../../component/drawer-modal';
import { useIdentityList } from '../../utils/business-admin/index'
import { IdentityEdit } from './component/lib/identity-edit'
import { PrivilegedButton } from './../../component/privileged-button';

export const IdentityAdmin = () => {
  const breadcrumbNameMap = [
    {
      name: '会员管理',
      url: '/home/business/player'
    },
    {
      name: '身份管理',
      url: ''
    },
  ]
  const [visible, setVisible] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [identity, setIdentity] = useState()
  const { data: identityData, retry, isLoading } = useIdentityList(useMerchantId()) //获取身份管理列表
  const identityList = identityData?.rows

  return (
    <div className='grid_display identity_main'>
      <div className='breadcrumb'>
        <BreadComponent breadcrumbNameMap={breadcrumbNameMap} />
      </div>
      <Spin size="large" spinning={isLoading}>
        <div className='page_main'>
          <div className='title_bg'>
            <Typography.Title level={5}>身份管理</Typography.Title>
          </div>
          <div className="page_inner">
            <ul className="cards_list">
              {
                identityList?.map((item, index) => <li key={index}>
                  <div className='card_info'>
                    <div className='flex-btw card_title'>
                      <p>{item.identityName}</p>
                      <PrivilegedButton code="club:merchantUser:cardIdentity">
                        <div className='card_edit' onClick={() => {
                          setIsEdit(true)
                          setVisible(true)
                          setIdentity(item)
                        }}>
                          <EditOutlined />
                        </div>
                      </PrivilegedButton>
                    </div>
                    <p className='card_id'>折扣：{item.discount}折</p>
                  </div>
                </li>)
              }
              <PrivilegedButton code="club:merchantUser:cardIdentity">
                <li className='add_card flex-center' onClick={() => {
                    setVisible(true)
                    setIsEdit(false)
                  }}>
                  <span className='add_position'>
                    <PlusOutlined />
                    <span>添加身份管理</span>
                  </span>
                </li>
              </PrivilegedButton>
            </ul>
          </div>
        </div>
      </Spin>
      <DrawerModal title={isEdit ? '编辑身份' : '添加身份'} visible={visible} setVisible={setVisible}>
        <IdentityEdit
          isEdit={isEdit}
          identity={identity}
          visible={visible}
          setVisible={setVisible}
          retry={retry}
        />
      </DrawerModal>
    </div>
  )
}