
import { Typography, Spin } from 'antd';
import { BreadComponent } from './../../component/breadcrumb';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { DrawerModal } from './../../component/drawer-modal';
import { useGoodsTypes } from './../../utils/miniproject-admin/goods-info';
import { GoodsTypeEdit } from './component/lib/goods'
import { PrivilegedButton } from './../../component/privileged-button';

export const GoodsType = () => {
  const breadcrumbNameMap = [
    {
      name: '商品信息',
      url: '/home/miniproject/goods_info'
    },
    {
      name: '类别',
      url: ''
    },
  ]
  const { data: goodsTypeData, retry, isLoading } = useGoodsTypes()
  const goodsTypeList = goodsTypeData?.rows
  const [isEdit, setIsEdit] = useState(false)
  const [visible, setVisible] = useState(false)
  const [goodsTypeId, setGoodsTypeId] = useState()

  return (
    <div className='grid_display goods_type'>
      <div className='breadcrumb'>
        <BreadComponent breadcrumbNameMap={breadcrumbNameMap} />
      </div>
      <Spin size="large" spinning={isLoading}>
        <div className='page_main'>
          <div className='title_bg'>
            <Typography.Title level={5}>商品类别</Typography.Title>
          </div>
          <div className="page_inner">
            <ul className="cards_list">
              {
                goodsTypeList?.map((item, index) => <li key={index}>
                  <div className='card_info'>
                    <div className='flex-btw card_title'>
                      <p>类名：{item.goodsTypeName}</p>
                      <PrivilegedButton code="wechat:goods:typeUpdate">
                        <div className='card_edit' onClick={() => {
                          setIsEdit(true)
                          setVisible(true)
                          setGoodsTypeId(item.id)
                        }}>
                          <EditOutlined />
                        </div>
                      </PrivilegedButton>
                    </div>
                    <p className='card_id'>ID：{item.id}</p>
                  </div>
                </li>)
              }
              <PrivilegedButton code="wechat:goods:typeUpdate">
                <li className='add_card flex-center' onClick={() => {
                    setIsEdit(false)
                    setVisible(true)
                  }}>
                  <span className='add_position'>
                    <PlusOutlined />
                    <span>添加类别</span>
                  </span>
                </li>
              </PrivilegedButton>
            </ul>
          </div>
        </div>
      </Spin>
      <DrawerModal title={isEdit ? '编辑类别' : '添加类别'} visible={visible} setVisible={setVisible}>
        <GoodsTypeEdit
          isEdit={isEdit}
          goodsTypeId={goodsTypeId}
          visible={visible}
          setVisible={setVisible}
          retry={retry}
        />
      </DrawerModal>
    </div>
  )
}