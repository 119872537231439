import React, { useReducer, useState, useEffect } from "react"
import styled from '@emotion/styled';
import { Button, Popover } from 'antd';
import './style/index.less'
import { ClockCircleOutlined, ShoppingOutlined, LockOutlined } from '@ant-design/icons';
import { ManagerCountIcon, BroadcastIcon, UnsettledIcon } from './../../component/custom-icon';
import { Link } from "react-router-dom";
import { useBecomeRegular, useCanBeSettledOrder, useStatistics } from "../../utils/manager-admin";
import { ClubPlaceList } from './component/club-place-list';
import { useMerchantId } from './../../utils/common';
import { DrawerModal } from './../../component/drawer-modal';
import { UnsettledList } from './component/unsettled-list';
import { PrivilegedButton } from './../../component/privileged-button';
import { useAuth } from "../../context/auth-context";

export const ManagerAdmin = () => {
  const merchantId = useMerchantId()
  const { user } = useAuth()
  //查询打位状态的参数
  const [state, dispatch] = useReducer((state, action) => ({ ...state, ...action }), {
    generalActive: '',
    luxuryActive: '',
    merchantId,
  })

  const { generalActive, luxuryActive } = state
  const managerStatus = [
    {
      name: '全部',
      label: ''
    },
    {
      name: '闲',
      label: '1'
    },
    {
      name: '忙',
      label: '2'
    }
  ]
  const { data: becomeRegularData } = useBecomeRegular(merchantId) //查询可转正订单
  const RegularData = becomeRegularData?.data

  const { data: statisticsDatas } = useStatistics(merchantId)   //查询数据统计
  const statisticsData = statisticsDatas?.data

  const [visible, setVisible] = useState(false) //推框
  const { data: settledOrder, isLoading: settleLoading, error: settleError } = useCanBeSettledOrder() //请求可结账单
  const [length, setLength] = useState(false) //是否有可结账账单
  
  useEffect(() => {
    setLength(settledOrder?.rows.length)
  }, [settledOrder])
  
  return (
    <div className="index_container">
      <HeaderTip>
        <h3 className="welcome">Hi, {user?.user.nickName} ,欢迎来到衡泰信会所管理系统！</h3>
        <div className="flex-btw">
          <div className="tips_content">
            <span>室内高尔夫专家</span>
            <span>|</span>
            <span>室内高尔夫综合解决方案服务商</span>
          </div>
          <div className="flex-btw" data-component="tabs">
            <Link to={'pointList'}><TabsItem icon={<ClockCircleOutlined />}>预约列表</TabsItem></Link>
            <Link to={'lock_screen_setting'}><TabsItem icon={<LockOutlined />}>锁屏设置</TabsItem></Link>
            <PrivilegedButton code="club:broadcast:query">
              <Link to={'broadcast_setting'}><TabsItem icon={<BroadcastIcon />}>广播设置</TabsItem></Link>
            </PrivilegedButton>
            <Link to={'cost_setting'}><TabsItem icon={<ShoppingOutlined />}>费用设置</TabsItem></Link>
            <div className="unsettled_box ml-15">
              <Button type='default' icon={<UnsettledIcon length={length} />} onClick={() => {
                setVisible(true)
              }}>可结账单</Button>
            </div>
            <Popover placement="bottomRight" title={<PopoverTitle>数据统计</PopoverTitle>} content={
              <div className="popover_content">
                <div className="active_account">
                  <p className="active_account_hd flex">
                    <img src={require('../../assets/imgs/manager/active_account.png')} alt="" />
                    <span>当前数据</span>
                  </p>
                  <p>运行打位：<span>{statisticsData?.runPlaceCount || 0}</span></p>
                  <p>空闲打位：<span>{statisticsData?.idlePlaceCount || 0}</span></p>
                  <p>当前账号：<span>{statisticsData?.currentAccountCount || 0}</span></p>
                  <p>预约订单存量：<span>{statisticsData?.nonRedeemedAppointmentCount || 0}</span></p>
                </div>

                <div className="active_account">
                  <p className="active_account_hd flex">
                    <img src={require('../../assets/imgs/manager/active_data.png')} alt="" />
                    <span>今日总计</span>
                  </p>
                  <p>账号总数：<span>{statisticsData?.currentLogOnAccountCount || 0}</span></p>
                  <p>运行总时长：<span>{statisticsData?.runTotalTime || 0}</span></p>
                  <p>消费总额：<span>{statisticsData?.totalConsumptionAmount || 0}</span></p>
                </div>
              </div>
            } trigger="hover">
              <TabsItem icon={<ManagerCountIcon />}>数据统计</TabsItem>
            </Popover>

          </div>
        </div>
      </HeaderTip>
      <ManagerList>
        {/* 普通打位 */}
        <div className="manager_general">
          <div className="flex-btw manager_header">
            <div className="manager_title">
              <span>普通打位</span>
              <span style={{ color: 'rgba(0, 0, 0, 0.65)' }}>（可转正订单 <span style={{ color: '#E34D59' }}>{RegularData?.commonBecomeRegularCount || 0}</span>）</span>
            </div>
            <div className="flex manager_status">
              {
                managerStatus?.map((item, index) => <span key={index} className={generalActive === item.label ? 'active' : ''} onClick={() => {
                  dispatch({
                    generalActive: item.label,
                  })
                }}>{item.name}</span>)
              }
            </div>
          </div>
          <ClubPlaceList
            state={state}
            type="common"
          />
        </div>
        {/* 豪华打位 */}
        <div className="manager_luxury">
          <div className="flex-btw manager_header">
            <div className="manager_title">
              <span>豪华打位</span>
              <span style={{ color: 'rgba(0, 0, 0, 0.65)' }}>（可转正订单 <span style={{ color: '#E34D59' }}>{RegularData?.luxuryBecomeRegularCount || 0}</span>）</span>
            </div>
            <div className="flex manager_status">
              {
                managerStatus?.map((item, index) => <span key={index} className={luxuryActive === item.label ? 'active' : ''} onClick={() => {
                  dispatch({
                    luxuryActive: item.label,
                  })
                }}>{item.name}</span>)
              }
            </div>
          </div>
          <ClubPlaceList
            state={state}
            type="luxury"
          />
        </div>
      </ManagerList>
      <DrawerModal unsettled={true} title={'可结账单'} visible={visible} setVisible={setVisible}>
        <UnsettledList type={'index'} setVisible={setVisible} settledOrder={settledOrder} isLoading={settleLoading} error={settleError} />
      </DrawerModal>
    </div>
  )
}

const HeaderTip = styled.div`
width:100%;
height:9.5rem;
padding:1.6rem 2.4rem 1.4rem 3.2rem;
background:#fff;
display:flex;
flex-direction: column;
`
const TabsItem = styled(Button)`
border: 1px solid #E7E7E7;
margin-left:1rem;
font-size:1.4rem;
color: rgba(0, 0, 0, 0.65);
`
const ManagerList = styled.div`
  padding:2.4rem 2.4rem 1.2rem 2.4rem;
  flex:1;
`
const PopoverTitle = styled.h4`
font-size: 1.6rem;
font-weight: 600;
color: rgba(0, 0, 0, 0.85);
`