
import { Form, DatePicker, Space, Input } from 'antd';
import { Footer } from '../../../../component/drawer-modal';
import { useState } from 'react';

import { useEffect } from 'react';
import { disabledDate, disabledDateTime } from './../../../../utils/common';
import { PutErrorBox } from '../../../../component/lib';
import { useChangePlace, usePlaceName } from '../../../../utils/manager-admin/point-list';
import { IdSelect } from '../../../../component/id-select';


export const LockEdit = ({ visible, id, changeClubPlace, setVisible, retry, changeStatusLoading, setChangeStatusError, changeStatusError }) => {
  const [form] = Form.useForm()
  const [totalTime, setTotalTime] = useState()

  const onChange = (value) => {
    const totalSpace = (new Date(value).getTime() - new Date().getTime()) / 3600 / 1000
    if (totalSpace >= 0) {
      setTotalTime(totalSpace.toFixed(2))
    }
  }
  const onFinish = (values) => {
    console.log(values)
    const unlockTime = new Date(values?.unlockTime).getTime()
    changeClubPlace({
      unlockTime,
      id,
      status: 3
    }).then(res => {
      if (res.code === 200) {
        setVisible(false)
        retry()
      }
    })
  }
  useEffect(() => {
    form.resetFields()
    setTotalTime('')
  }, [visible, form])
  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout='horizontal'
      labelCol={{
        span: 4,
      }}>
      <PutErrorBox setError={setChangeStatusError} error={changeStatusError} />
      <Form.Item name="unlockTime" label="锁定打位至" rules={[{ required: true, message: '请选择日期' }]}>
        {/* 做今日之前日期不可选 */}
        <DatePicker
          disabledDate={disabledDate}
          disabledTime={disabledDateTime}
          format="YYYY-MM-DD HH:mm"
          showTime={{ format: "HH:mm" }}
          onChange={onChange} />
      </Form.Item>
      <Form.Item label="共计">
          <span className='mr-10 ml-10'>{totalTime}</span>
          <span>小时</span>
      </Form.Item>
      <Footer onCancel={()=>{
        setTotalTime('')
      }} isLoading={changeStatusLoading} />
    </Form>
  )
}

export const ChangePlaceEdit = ({ placeType, placeData, retry, setVisible }) => {
  const [form] = Form.useForm()
  // const { data: clubPlaces } = useSelect('club_place_type')
  const { data: placeNames } = usePlaceName(placeType,placeData.id)
  const { changePlace, isLoading, error, setError } = useChangePlace()//换机
  const onFinish = (values) => {
    changePlace({ ...values, id: placeData.orderId }).then(res => {
      if (res.code === 200) {
        retry()
        setVisible(false)
      }
    })
  }
  return <Form form={form} onFinish={onFinish}>
    <PutErrorBox error={error} setError={setError} />
    <Form.Item name="placeId" label="打位选择" rules={[{ required: true, message: '请选择打位' }]}>
      <IdSelect options={placeNames?.rows} />
    </Form.Item>
    <Footer isLoading={isLoading} />
  </Form>
}