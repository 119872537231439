import { useAsync } from './../use-async';
import { useHttp } from '../http';
import { useEffect } from 'react';
import { useCallback } from 'react';

//查询管理员列表
export const useAdminList = (params) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/user/manage/list`, {
    data: params
  }), [client, params])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}

//查询级别
export const useLevel = ()=>{
  const client = useHttp()
  const { run, ...result } = useAsync()
  useEffect(()=>{
    run(client(`golfonlineClub/user/roleOptions`))
  },[client, run])
  return result
}

//管理员增加
export const useAddAdmin = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const addAdmin = (params) => {
    return run(client(`golfonlineClub/user`, {
      method: 'POST',
      data: {
        ...params
      }
    }))
  }
  return {
    ...result,
    addAdmin
  }
}

//管理员修改
export const useEditAdmin = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const editAdmin = (params) => {
    return run(client(`golfonlineClub/user/manage`, {
      method: 'PUT',
      data: {
        ...params
      }
    }))
  }
  return {
    ...result,
    editAdmin
  }
}

//管理员删除
export const useDeleteAdmin = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const deleteAdmin = (id) => {
    return run(client(`golfonlineClub/user/${id}`, {
      method: 'DELETE',
    }))
  }
  return {
    ...result,
    deleteAdmin
  }
}

//权限管理tab查询
export const useQueryTabs = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/role/list`), [client])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}

//权限树查询
export const useQueryTree = (key,visible) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/menu/roleMenuTreeselect/${key}`), [client,key])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise,visible])
  return result
}

//改变权限
export const useChangeLimits = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const changeLimits = (params) => {
    return run(client(`golfonlineClub/role`, {
      method: 'PUT',
      data: {
        ...params
      }
    }))
  }
  return {
    ...result,
    changeLimits
  }
}