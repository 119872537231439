
import { Popconfirm, Input, Table, Typography, Divider, Space, message, Button,Modal } from 'antd';
import {
  useChangeTelephone,
  useOrderInfoList,
  useOpenDevice,
  useCloseDevice,
  useChangePlayMode,
  useShopBuyList,
} from './../../../../utils/manager-admin/order';
import { useRouteId } from '../../../../utils/common';
import { useState, useContext, useEffect } from 'react';
import { EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { OrderConfirmEdit } from './order-confirm-edit';
import { OrderContext } from './../../order-info';
import { IdSelect } from './../../../../component/id-select';
import { useSelect } from './../../../../utils/use-select';
import { DrawerModal } from './../../../../component/drawer-modal';
import { HandOrder, PlayBuyEdit } from './order-edit';
import { HandlerOrder, UnsettledIcon } from './../../../../component/custom-icon';
import { useMerchantId, useIsLimit, useSearchParam } from './../../../../utils/common';
import { EditableTable } from './editableTable-order';
import { GetErrorBox } from '../../../../component/lib';
import { PrivilegedButton } from './../../../../component/privileged-button';
import { formatTime } from './../club-place-status';
import { useShutDownPlace } from './../../../../utils/manager-admin/index';

const useOrderContext = () => {
  const context = useContext(OrderContext)
  if (!context) {
    throw new Error('useAuth必须在AuthProvider中使用')
  }
  return context
}

//订单信息
export const OrderInfoTable = ({ setVisible, length,playRetry }) => {
  const { setUnsettle } = useOrderContext()
  const orderSearchId = useSearchParam()
  const [editable, setEditable] = useState(false)
  const [telephone, setTelephone] = useState()
  const { changeTelephone } = useChangeTelephone() //修改电话号码
  const placeId = useRouteId()
  const { data: orderInfoData, isLoading, retry, error } = useOrderInfoList(placeId, orderSearchId) //订单信息列表
  const orderInfoList = orderInfoData?.rows
  // console.log(orderInfoList)
  const { openDevice } = useOpenDevice() //上机
  const { closeDevice } = useCloseDevice() //下机
  const { shutDownPlace } = useShutDownPlace() //关机

  const onChange = (e) => {
    setTelephone(e.target.value)
  }
  const save = (id) => {
    changeTelephone({
      telephone,
      id
    }).then(res => {
      if (res.code === 200) {
        setEditable(false)
        message.success(res.msg)
        retry()
        playRetry()
      } else {
        message.error(res.msg)
      }
    })
  }

  const columns = [
    {
      title: '订单号',
      dataIndex: 'orderNo',
    },
    {
      title: '姓名',
      dataIndex: 'userName',
    },
    {
      title: '联系方式',
      editable: true,
      width: 250,
      render: (value) => {
        return <div>
          {
            editable ? (
              <div className='flex'>
                <Input
                  maxLength={11}
                  defaultValue={value.telephone}
                  allowClear
                  style={{ width: '60%', marginRight: '15px' }}
                  onChange={onChange}
                />
                <Space size={[10, 0]}>
                  <Typography.Link onClick={() => {
                    save(value.orderId)

                  }}>保存</Typography.Link>
                  <Typography.Text
                    type="secondary"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setEditable(false)
                    }}
                  >取消</Typography.Text>
                </Space>
              </div>

            ) : <div className='flex'>
              <span className="mr-10">{value.telephone}</span>
              <div className='edit_icon'>
                <EditOutlined onClick={() => {
                  setEditable(true)
                }} />
              </div>

            </div>
          }
        </div>
      }
    },
    {
      title: '会员号',
      dataIndex: 'memberNo',
      render: (value) => {
        return <div>
          {
            value ? value : '无'
          }
        </div>
      }
    },
    {
      title: '身份',
      dataIndex: 'identityDict',
      render: (value) => {
        return <div>
          {
            value ? value : '游客'
          }
        </div>
      }
    },
    {
      title: '开始时间',
      dataIndex: 'startTime',
    },
    {
      title: '结束时间',
      dataIndex: 'endTime',
    },
    {
      title: '操作',
      render: (value) => {
        return (
          <div className='order_info_hand flex'>
            <Popconfirm
              placement="bottomRight"
              title={`确定${value.appointmentStatus === 4 ? '结束' : '开始'}吗？`}
              okText="确定"
              cancelText="取消"
              onConfirm={() => {
                if (value.appointmentStatus === 4) {
                  //下机操作
                  closeDevice(value.orderId).then(res => {
                    if (res.code === 200) {
                      message.success(res.msg)
                      retry()
                      playRetry()
                    } else {
                      message.error(res.msg)
                    }
                  })
                } else {
                  //上机操作
                  openDevice(value.orderId).then(res => {
                    if (res.code === 200) {
                      message.success(res.msg)
                      retry()
                      playRetry()
                    } else {
                      message.error(res.msg)
                    }
                  })
                }
              }}
            >
              <Typography.Link disabled={value.appointmentStatus === 8}>{value.appointmentStatus === 4 ? '结束' : '开始'}</Typography.Link>
            </Popconfirm>
            <Divider type="vertical" />
            <OrderConfirmEdit value={value} type={1} retry={retry} playRetry={playRetry} />
            <Divider type="vertical" />
            <OrderConfirmEdit value={value} type={2} retry={retry} playRetry={playRetry} />
            {
              value?.simulatorState === 1 ? <>
              <Divider type="vertical" />
              <Typography.Link onClick={()=>{
                  Modal.confirm({
                    title: '确认关机?',
                    icon: <ExclamationCircleOutlined />,
                    okText: '确认',
                    cancelText: '取消',
                    onOk: () => {
                      shutDownPlace(placeId).then(res => {
                        if (res.code === 200) {
                          retry()
                          playRetry()
                          message.success(res.msg)
                        } else {
                          message.error(res.msg)
                        }
                      })
                    }
                  });
              }}>立即关机</Typography.Link>
              </>:null
            }
            
          </div>
        )
      }
    },
  ]
  useEffect(() => {
    if (orderInfoList?.length) {
      setTelephone(orderInfoList[0].telephone)
    }
  }, [orderInfoList])
  return <>
    <GetErrorBox error={error} />
    <div className='flex-btw table_list_title'>
      <h4>订单信息</h4>
      <div data-component="tabs">
        <div className="unsettled_box">
          <Button type='default' icon={<UnsettledIcon length={length} />} onClick={() => {
            setUnsettle(true)
            setVisible(true)
          }}>可结账单</Button>
        </div>
      </div>
    </div>
    <Table
      loading={isLoading}
      dataSource={orderInfoList}
      rowKey={record => record.orderId}
      columns={columns}
      pagination={false} />
  </>
}

//打球消费
export const OrderPlayTable = ({ ConsumptionData, retry, isLoading }) => {
  const [visible, setVisible] = useState(false)
  const consumptionList = ConsumptionData?.rows
  const { data: modeData } = useSelect('club_charging_mode') //查询模式
  const [projectData, setProjectData] = useState() //保存单条数据
  const { changePlayMode } = useChangePlayMode() //修改扣费模式
  const changeModeLimit = useIsLimit('club:cost:changeMode')
  const costEdit = useIsLimit('club:cost:edit')
  const columns = [
    {
      title: '项目ID',
      render: (value) => {
        return (
          <div>{value.projectId ? value.projectId : value.typeId}</div>
        )
      }
    },
    {
      title: '项目内容',
      render: (value) => {
        return (
          <div>{value.projectName ? value.projectName : value.typeName}</div>
        )
      }
    },
    {
      title: '扣费模式',
      width: 150,
      render: (value) => {
        return (
          <div>
            {
              value.typeId === 1 || value.typeId === 2 ? <IdSelect
                disabled={changeModeLimit}
                value={value.mode}
                style={{ width: 150 }}
                options={modeData?.data}
                onChange={(mode) => {
                  changePlayMode({ mode, id: value.id }).then(res => {
                    if (res.code === 200) {
                      retry()
                      message.success(res.msg)
                    } else {
                      message.error(res.msg)
                    }
                  })
                }}
              /> : <span>{value.modeDict || '按时间'}</span>
            }
          </div>
        )
      }
    },
    {
      title: '使用次数',
      dataIndex: 'playTimes',
    },
    {
      title: '开始时间',
      dataIndex: 'startTime',
    },
    {
      title: '结束时间',
      dataIndex: 'endTime',
    },
    {
      title: '时长',
      dataIndex: 'duration',
      render: (value) => {
        return <>{formatTime(value)}</>
      }
    },
    {
      title: '赠送时长',
      dataIndex: 'giftDuration',
      render: (value) => {
        return <>{formatTime(value)}</>
      }
    },
    {
      title: '单价',
      dataIndex: 'unitPrice',
    },
    {
      title: '原总价',
      dataIndex: 'originalTotalPrice',
    },
    {
      title: '身份折扣',
      dataIndex: 'discount',
      render: (value) => {
        return <>{value ? value + '折' : "-"} </>
      }
    },
    {
      title: '最终价',
      dataIndex: 'finalPrice',
    },
    {
      title: '操作',
      render: (value) => {
        return (
          <Typography.Link
            disabled={costEdit}
            onClick={() => {
              setVisible(true)
              setProjectData(value)
            }}>编辑</Typography.Link>
        )
      }
    }
  ]
  return <>
    <h4 className='table_list_title'>打球消费 <span className='total'>合计：{ConsumptionData?.attach?.total || 0}元</span></h4>
    <Table
      rowKey={record => record.id}
      columns={columns}
      pagination={false}
      dataSource={consumptionList}
      loading={isLoading}
    />
    <DrawerModal title={'编辑'} visible={visible} setVisible={setVisible}>
      <PlayBuyEdit projectData={projectData} visible={visible} setVisible={setVisible} retry={retry} />
    </DrawerModal>
  </>
}

//商店消费
export const OrderShopTable = () => {
  const orderSearchId = useSearchParam()
  const merchantId = useMerchantId() //商户id
  const [visible, setVisible] = useState(false)
  const { data: shopData, isLoading, retry } = useShopBuyList(orderSearchId, merchantId) //商店消费列表
  const shopBuyList = shopData?.rows

  return <>
    <div className='flex-btw table_list_title'>
      <h4>
        商店消费 <span className='total'>合计：{shopData?.attach?.total}元</span>
      </h4>
      <PrivilegedButton code="club:appointment:orderManually">
        <Button icon={<HandlerOrder />} type='primary' onClick={() => {
          setVisible(true)
        }}>点餐</Button>
      </PrivilegedButton>
    </div>

    <EditableTable shopBuyList={shopBuyList} isLoading={isLoading} retry={retry} />
    <DrawerModal title={'点餐'} visible={visible} setVisible={setVisible}>
      <HandOrder visible={visible} setVisible={setVisible} retry={retry} orderId={orderSearchId} />
    </DrawerModal>
  </>

}
