
import { Form, Input, Space } from 'antd';
import { useState, useEffect } from 'react';
import { useMerchantId } from './../../../../utils/common';
import { useAddCard, useEditCard, useCard } from '../../../../utils/business-admin/index'
import { PutErrorBox } from './../../../../component/lib';
import { Footer } from '../../../../component/drawer-modal';

export const CardEdit = ({ setVisible, visible, isEdit, retry, memberUserId, cardId }) => {
  const [form] = Form.useForm()
  const merchantId = useMerchantId()
  const [active, setActive] = useState(0)
  const cardsMode = ['green', 'red', 'yellow']
  const { addCard } = useAddCard() //添加卡片
  const { editCard } = useEditCard() //编辑卡片
  const { data: cardData } = useCard(cardId,visible) //查询单个卡片
  const card = cardData?.data
  const [error, setError] = useState()

  const onFinish = (value) => {
    const params = {
      ...value,
      merchantId,
      memberUserId,
      cardType: cardsMode[active]
    }

    if (isEdit) {
      editCard({ ...params, id: cardId }).then((res) => {
        if (res.code === 200) {
          setVisible(false)
          retry()
        } else {
          setError(res)
        }
      })
    } else {
      addCard(params).then((res) => {
        if (res.code === 200) {
          setVisible(false)
          retry()
        } else {
          setError(res)
        }
      })
    }
  }
  useEffect(() => {
    if (isEdit) {
      const index = cardsMode.findIndex(item => item === card?.cardType)
      setActive(index)
      form.setFieldsValue(card)
    } else {
      form.resetFields()
    }
    setError(null)
  }, [form, visible, isEdit, card])

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout='horizontal'
      autoComplete='off'
      labelCol={{
        span: 3,
      }}>
      <PutErrorBox error={error} setError={setError} />
      <Form.Item label="卡片" name='cardName' rules={[{ required: true, message: '请输入卡片名称' }]}>
        <Input placeholder="请输入卡片名称" allowClear />
      </Form.Item>
      <Form.Item label="余额" name='cardBalance' rules={[
        { required: true, message: '请输入余额' },
        () => (
          {
            validator(_, value) {
              if (value < 0) {
                return Promise.reject(new Error('请输入正确金额'));
              } else {
                return Promise.resolve()
              }

            }
          }
        )]}>
        <Input placeholder="请输入余额" type={'number'} allowClear />
      </Form.Item>
      <Form.Item label="卡片模板">
        <ul className='card_bg_select'>
          <Space>
            {
              cardsMode.map((item, index) => <li className={active === index ? 'active ' + item : item} key={index} onClick={() => {
                setActive(index)
              }}></li>)
            }
          </Space>
        </ul>
      </Form.Item>
      <Footer />
    </Form>
  )
}