
import { Form, Spin } from 'antd';
import styled from '@emotion/styled';
import { DrawerModal } from './../../../component/drawer-modal';
import { useState } from 'react';
import { AddUserForm } from './lib/add-user';
import { useMember } from '../../../utils/business-admin/index'
import { GetErrorBox } from '../../../component/lib';
import { PrivilegedButton } from './../../../component/privileged-button';

export const PlayerBasicsInfo = ({ memberUserId }) => {
  const [visible, setVisible] = useState(false)
  const { data: memberInfo, retry: memberRetry, isLoading, error } = useMember(memberUserId) //获取基础信息
  const memberData = memberInfo?.data

  return (
    <div className="player_basics_main">
      <div className='stadium_item'>
        <div className="flex-btw stadium_header">
          <GetErrorBox error={error} />
          <h5>基础信息</h5>
          <PrivilegedButton code="club:merchantUser:modify">
            <div className="table_edit" onClick={() => {
              setVisible(true)
            }}>编辑</div>
          </PrivilegedButton>
        </div>
        <Spin spinning={isLoading}>
          <div className="stadium_body">
            <Form
              labelCol={{
                span: 4,
              }}
            >
              <div className="flex">
                <FormItem label="编号：">
                  <span>{memberData?.id}</span>
                </FormItem>
                <FormItem label="姓名：">
                  <span>{memberData?.userName}</span>
                </FormItem>
                <FormItem label="电话：">
                  <span>{memberData?.telephone}</span>
                </FormItem>
              </div>
              <div className="flex">
                <FormItem label="身份：">
                  <span>{memberData?.identityDict}</span>
                </FormItem>
                <FormItem label="会员号：">
                  {memberData?.memberNo}
                </FormItem>
                <FormItem label="折扣：">
                  <span>{memberData?.discount}折</span>
                </FormItem>
              </div>
            </Form>
          </div>
        </Spin>
      </div>
      <DrawerModal title={'基础信息/编辑'} visible={visible} setVisible={setVisible}>
        <AddUserForm memberData={memberData} visible={visible} setVisible={setVisible} memberRetry={memberRetry} />
      </DrawerModal>
    </div>
  )
}

const FormItem = styled(Form.Item)`
flex:1
`