import { useAsync } from './../use-async';
import { useHttp } from '../http';
import { useEffect, useCallback } from 'react';

//总收益查询
export const useIncomeStatistics = (startTime, endTime, merchantId) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  useEffect(() => {
    run(client(`golfonlineClub/clubAppointment/income`, {
      data: {
        startTime,
        endTime,
        merchantId
      }
    }))
  }, [client, run, startTime, endTime, merchantId])
  return result
}

//收益详情查询
export const useIncomeDetail = (params) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/clubAppointment/income/list`, {
    data: {
      ...params
    }
  }), [client, params])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}