
import { BreadComponent } from '../../component/breadcrumb';
import { Typography, Button } from 'antd';
import { UnsettledIcon } from '../../component/custom-icon';
import { CreateOrder } from './component/lib/create-order';
import { useCanBeSettledOrder } from '../../utils/manager-admin';
import { useRouteId } from '../../utils/common';
import { useState, useEffect } from 'react';
import { DrawerModal } from './../../component/drawer-modal';
import { UnsettledList } from './component/unsettled-list';


export const CreateOrderPage = () => {
  const breadcrumbNameMap = [
    {
      name: '打位管理',
      url: '/'
    },
    {
      name: '创建订单',
      url: ''
    },
  ]
  const placeId = useRouteId()
  const [visible, setVisible] = useState(false)
  const [unsettle, setUnsettle] = useState(false) //可结账单推框按钮控制
  const { data: settledOrder, error: settleError, isLoading: settleLoading } = useCanBeSettledOrder(placeId) //请求可选账单
  const [length, setLength] = useState(false) //可结账单数量长度
 

  useEffect(() => {
    setLength(settledOrder?.rows?.length)
  }, [settledOrder])

  return (<div className='create_order_page grid_display'>
    <div className='breadcrumb'>
      <BreadComponent breadcrumbNameMap={breadcrumbNameMap} />
    </div>
    <div className='page_main'>
      <div className='page_inner'>
        <div className='flex-btw'>
          <Typography.Title level={5}>创建订单</Typography.Title>
          <div data-component="tabs">
            <div className="unsettled_box">
              <Button type='default' icon={<UnsettledIcon length={length} />} onClick={() => {
                setUnsettle(true)
                setVisible(true)
              }}>可结账单</Button>
            </div>
          </div>
        </div>
        <div className='create_order_container'>
          <CreateOrder placeId={placeId} />
        </div>
      </div>
    </div>
    <DrawerModal title={'可结账单'} unsettled={unsettle} visible={visible} setVisible={setVisible}>
      {
        <UnsettledList settledOrder={settledOrder} isLoading={settleLoading} error={settleError} setVisible={setVisible} />
      }
    </DrawerModal>   
  </div>)
}