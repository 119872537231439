
import { Form, Button, message } from 'antd';
import { BreadComponent } from './../../component/breadcrumb';
import styled from '@emotion/styled';
import { usePlaceNames, useCode, useAddQrCode } from './../../utils/miniproject-admin/records';
import { IdSelect } from '../../component/id-select';
import { useState } from 'react';
import { useEffect } from 'react';
import { PutErrorBox } from '../../component/lib';
import { useMerchantId } from './../../utils/common';

export const Records = () => {
  const breadcrumbNameMap = [
    {
      name: '二维码',
      url: ''
    },
  ]
  const [placeId, setPlaceId] = useState()
  const merchantId = useMerchantId()
  const [codeUrl, setCodeUrl] = useState()
  const { data } = usePlaceNames() //查询打位
  const { data: codeData } = useCode(placeId) //查询二维码
  const codeList = codeData?.rows
  const { addQrCode, error, setError } = useAddQrCode() //生成二维码


  const onChange = (value) => {
    setPlaceId(value)
  }
  const onAddQrCode = () => {
    addQrCode({ placeId, merchantId }).then(res => {
      if (res.code === 200) {
        if (res.data) {
          setCodeUrl(res.data.multimediaUrl)
        } else {
          message.error(res.msg)
        }
      }
    })
  }
  useEffect(() => {
    if (codeList?.length) {
      setCodeUrl(codeList[0]?.multimediaUrl)
    } else {
      setCodeUrl(null)
    }

  }, [codeList])
  return (
    <div className="records_main grid_display">
      <div className='breadcrumb'>
        <BreadComponent breadcrumbNameMap={breadcrumbNameMap} />
      </div>
      <div className='page_main'>
        <div className='page_inner'>
          <PutErrorBox error={error} setError={setError} />
          <div className='flex-column'>
            <div className='records_inner'>
              <Form.Item label="打位">
                <IdSelect options={data?.rows} onChange={onChange} />
              </Form.Item>
              <div className="flex-column">
                <div className="records_img">
                  <div className="records_img_inner flex-center">
                    {
                      codeUrl ? <img src={codeUrl} alt="" /> : '此处预览二维码'
                    }
                  </div>
                </div>
                <div className="flex-btw submit_box">
                  <Button size='large' type="primary" onClick={onAddQrCode}>生成</Button>
                  <SubmitButton size='large' danger Link href={codeUrl} target="_blank" rel="noreferrer">下载图片</SubmitButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const SubmitButton = styled(Button)`
width:48%;
background:#48C79C;
border-color:#48C79C;
color:#fff;
`