import { useAsync } from './../use-async';
import { useHttp } from '../http';
import { useEffect } from 'react';
import { useCallback } from 'react';


//查询所有球馆
export const useStadiumList = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/user/queryMerchantByCurrentUser`), [client])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}

//切换球馆
export const useChangeStadium = () =>{
  const { run, ...asyncResult } = useAsync()
  const client = useHttp()
  const changeStadium = (id) => {
    
    return run(client(`golfonlineClub/user/changeMerchant/${id}`, {
      method: 'PUT'
    }))
  }
  return {
    changeStadium,
    ...asyncResult
  }
}

//查询新订单数量
export const useNewOrderNum = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/consumption/newOrder`), [client])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}