import { Button } from "antd"
import { useNavigate } from 'react-router-dom';

export const OrderUnsettleItem = ({ orderDetail,setVisible }) => {
  const navigate = useNavigate()
  return (
    <div className="oreder_unsettled_item">
      <h4 className="flex-btw order_info">
        <span>{orderDetail?.appointmentTime}</span>
        <span>{orderDetail?.telephone}</span>
      </h4>
      <div className="unsettled_item_inner flex-btw">
        <div>
          <h3 className="name">{orderDetail?.userName}</h3>
          <p>共计：<span className="price">{orderDetail?.amount}元</span></p>
        </div>
          <Button
            shape="round"
            size="small"
            type='primary'
            onClick={() => {
               navigate(`/home/index/manager/order_info/${orderDetail?.placeId}?orderId=${orderDetail?.id}`)
               setVisible(false)
            }}
            danger>进入</Button>
      </div>
    </div>
  )
}

// export const IndexUnsettleItem = ({ orderDetail }) => {
//   const navigate = useNavigate()
//   return (
//     <div className="index_unsettled_item">
//       <div className="unsettled_item_inner flex-btw">
//         <div>
//           <h3 className="name">{orderDetail?.userName}</h3>
//           <p>共计：<span className="price">{orderDetail?.amount}元</span></p>
//         </div>
//         <Button
//           shape="round"
//           size="small"
//           type='primary'
//           danger
//           onClick={() => {
//             navigate(`/home/index/manager/order_info/${orderDetail?.placeId}?orderId=${orderDetail?.id}`)
//           }}
//         >进入</Button>
//       </div>
//     </div>
//   )
// }