
import { Button, Form, Typography } from 'antd';
import { BreadComponent } from './../../component/breadcrumb';
import { Input } from 'antd';
import styled from '@emotion/styled';
import { useState, useEffect } from 'react';
import { useRef } from 'react';
import { useAuth } from './../../context/auth-context';
import { useChangePassword } from './../../utils/business-admin/change_password';
import { PutErrorBox } from '../../component/lib';

export const ChangePassword = () => {
  const timer = useRef()
  const { logout } = useAuth()
  const [form] = Form.useForm()
  const [changed, setChanged] = useState(false)
  const { changePassword, error, setError } = useChangePassword()
  let time = 5
  const [count, setCount] = useState(time)


  const breadcrumbNameMap = [
    {
      name: '修改密码',
      url: ''
    },
  ]
  //定时器
  const goToLoginTime = () => {
    timer.current && clearInterval(timer.current)
    timer.current = setInterval(() => {
      if (time <= 1) {
        timer.current && clearInterval(timer.current)
        if (!changed) {
          logout()
        }
      } else {
        time = time - 1
        setCount(time)
      }
    }, 1000)
  }
  //修改密码
  const onFinish = (value) => {
    changePassword(value).then(res => {
      if (res.code === 200) {
        goToLoginTime()
        setChanged(true)
      }
    })
  }
  useEffect(() => {
    return () => {
      timer.current && clearInterval(timer.current)
    }
  }, [])
  return (
    <div className='change_password_main flex-column'>
      <div className='breadcrumb'>
        <BreadComponent breadcrumbNameMap={breadcrumbNameMap} />
      </div>
      <div className='page_main'>
        <div className='page_inner flex-justify-center'>
          {
            changed ? <div className='change_password_success'>
              <img src={require('../../assets/imgs/password_change.png')} alt="" />
              <Typography.Title level={3}>修改成功</Typography.Title>
              <p>修改成功，{count}秒后将自动跳转到登录页面</p>
              <Button type='primary' onClick={logout}>去登录</Button>
            </div> : <div className='change_password_inner'>
              <Form
                form={form}
                autoComplete="off"
                onFinish={onFinish}
                labelCol={{
                  span: 4,
                }}>
                <PutErrorBox error={error} setError={setError} />
                <Form.Item name={'oldPassword'} label="原密码：" rules={[{ required: true, message: '请输入原密码' }]}>
                  <Input.Password placeholder="请输入原密码" allowClear />
                </Form.Item>
                <Form.Item name={'newPassword'} label="新密码：" rules={[{ required: true, message: '请输入新密码' }]}>
                  <Input.Password placeholder="请输入新密码" allowClear />
                </Form.Item>
                <Form.Item label="确认密码：" name={'confirmPassword'} rules={[
                  {
                    required: true,
                    message: '请输入确认密码',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('两次密码不一致'));
                    },
                  }),
                ]}>
                  <Input.Password placeholder="请再次输入密码" allowClear />
                </Form.Item>
                <Submit type='primary' htmlType='submit'>提交</Submit>
              </Form>
            </div>
          }


        </div>
      </div>
    </div>
  )
}

const Submit = styled(Button)`
margin-left:8.5rem;
margin-top:1rem;
`