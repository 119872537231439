import { BreadComponent } from './../../component/breadcrumb';
import styled from '@emotion/styled';
import { SearchBox } from './component/search-box';
import { Button, Table } from 'antd';
import { PieChartOutlined } from '@ant-design/icons';
import './index.less'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { DrawerModal } from './../../component/drawer-modal';
import { OperationalDetail } from './component/operational-detail';
import { useOperationalData } from './../../utils/data-count/index';
import { useReducer } from 'react';
import { GetErrorBox } from '../../component/lib';
import { onTableChange } from '../../utils/common';
import { formatTime } from './../manager-admin/component/club-place-status';


export const DataIndex = () => {
  const breadcrumbNameMap = [
    {
      name: '运营数据',
      url: ''
    }
  ]
  const [state, dispatch] = useReducer((state, action) => ({ ...state, ...action }), {
    startTime: '',
    endTime: '',
    placeType: '',
    placeId: '',
    page: 1,
    pageSize: 10
  })
  const [placeId, setPlaceId] = useState()
  const [placeName, setPlaceName] = useState()
  const columns = [
    {
      title: '打位ID',
      dataIndex: 'placeId',
    },
    {
      title: '打位',
      dataIndex: 'placeName',
      sorter: (a, b) => a.placeName.localeCompare(b.placeName)
    },
    {
      title: '打位类型',
      dataIndex: 'placeTypeDict',
    },
    {
      title: '运行时长 / h',
      dataIndex: 'duration',
      render: (value) => {
        return <>
          {formatTime(value)}
        </>
      }
    },
    {
      title: '详细内容',
      dataIndex: '',
      render: (value) => {
        return (
          <div className='table_edit' onClick={() => {
            setVisible(true)
            setPlaceId(value.placeId)
            setPlaceName(value.placeName)
          }}>详情</div>
        )
      }
    },
    {
      title: '收益 (元)',
      dataIndex: 'profit',
    }
  ]
  const [visible, setVisible] = useState(false)
  const { data, isLoading, error } = useOperationalData(state) //查询运营数据
  const duration = data?.attach.duration / 3600 ? (data?.attach.duration / 3600).toFixed(2) : 0
  return (
    <div className='data_index_main'>
      <div className='breadcrumb flex-btw'>
        <BreadComponent breadcrumbNameMap={breadcrumbNameMap} />
        <Link to="charts"><Button icon={<PieChartOutlined />}>数据统计</Button></Link>
      </div>
      <DataPageMain>
        <SearchWrapper>
          <SearchBox type={'place'} dispatch={dispatch} />
        </SearchWrapper>
      </DataPageMain>
      <div className='page_main'>
        <div className='page_inner'>
          <GetErrorBox error={error} />
          <h4 className="table_list_title">运营数据</h4>
          <div className='table_box'>
            <Table
              scroll={{ y: '50vh', x: true }}
              columns={columns}
              loading={isLoading}
              rowKey={record => record.placeId}
              dataSource={data?.rows}
              onChange={(pagination) => onTableChange(pagination, dispatch)}
              pagination={{ pageSize: state.pageSize, total: data?.total, showQuickJumper: true, pageSizeOptions: [5, 10, 20, 30, 40, 50] }}
            />
          </div>
        </div>
      </div>
      <div className='footer flex'>
        <p>总时长：<span>{duration || 0}小时</span></p>
        <p>总额：<span>{data?.attach.profit || 0}元</span></p>
      </div>
      <DrawerModal title={placeName + '详情'} width={'50%'} visible={visible} setVisible={setVisible}>
        <OperationalDetail placeId={placeId} />
      </DrawerModal>
    </div>
  )
}

const DataPageMain = styled.div`
width:100%;
padding:1.4rem 2.4rem 0;
`
const SearchWrapper = styled.div`
width:100%;
`
export const CloseButton = styled.div`
width:3.2rem;
height:3.2rem;
cursor: pointer;
display:flex;
justify-content: center;
align-items: center;
>*{
  width:1.6rem;
  height:1.6rem;
}
`


