import { Table, Typography, message } from "antd"
import { useDefaultCostList, useTypeCostList, useChangeTypeMode, useGroundCostList } from "../../../utils/manager-admin/cost-setting"
import { DrawerModal } from './../../../component/drawer-modal';
import { useState, useReducer } from 'react';
import { CostDefaultEdit, CostProjectEdit } from "./cost-edit-modal";
import { useSelect } from "../../../utils/use-select";
import { IdSelect } from "../../../component/id-select";
import { useIdentityList } from './../../../utils/manager-admin/cost-setting';
import { onTableChange, useMerchantId, useIsLimit } from './../../../utils/common';
import { useEffect } from 'react';


//新类型默认收费
export const DefaultTable = () => {
  const [visible, setVisible] = useState(false)
  const [defaultCost, setDefaultCost] = useState()
  const { data, isLoading, retry } = useDefaultCostList()   //查询新类型默认收费
  const defaultCostList = data?.rows
  const limit = useIsLimit('*:*:*')
  const columns = [
    {
      title: '打位类型',
      dataIndex: 'placeTypeDict',
    },
    {
      title: '元 / 场',
      dataIndex: 'frequencyCost',
    },
    {
      title: '元 / 时',
      dataIndex: 'timeCost',
    },
    {
      title: '操作',
      width: 250,
      render: (value) => {
        return (
          <Typography.Link
            disabled={limit}
            onClick={() => {
              setVisible(true)
              setDefaultCost(value)
            }}>编辑</Typography.Link>
        )
      }
    },
  ]
  return (
    <div>
      <Table
        isLoading={isLoading}
        columns={columns}
        dataSource={defaultCostList}
        rowKey={record => record.id}
        size="small"
        pagination={false}
      />
      <DrawerModal title={'编辑'} visible={visible} setVisible={setVisible}>
        <CostDefaultEdit defaultCost={defaultCost} retry={retry} visible={visible} setVisible={setVisible} />
      </DrawerModal>
    </div>
  )
}

//按类型
export const TypeTable = ({ setType, type }) => {
  const [visible, setVisible] = useState(false)
  const [typeCostData, setTypeCostData] = useState()
  const { data, isLoading, retry } = useTypeCostList() //查询列表数据
  const typeData = data?.rows
  const { data: modeData } = useSelect('club_charging_mode') //查询模式
  const { changeTypeMode } = useChangeTypeMode() //改变模式
  const limit = useIsLimit('*:*:*')

  const columns = [
    {
      title: '类型ID',
      dataIndex: 'typeId',
    },
    {
      title: '名称',
      dataIndex: 'typeName',
    },
    {
      title: '普通打位',
      dataIndex: 'commonPlace',
      render: (value, pro) => {
        return <span>{value || 0} 元/{pro.mode === 1 ? '场' : '小时'}</span>
      }
    },
    {
      title: '豪华打位',
      dataIndex: 'luxuryPlace',
      render: (value, pro) => {
        return <span>{value || 0}  元/{pro.mode === 1 ? '场' : '小时'}</span>
      }
    },
    {
      title: '模式',
      width: 250,
      render: (value) => {
        return (
          <div>
            {
              value.typeId === 1 || value.typeId === 2 ? <IdSelect
                disabled={limit || value.typeId === 1}
                value={value.mode}
                style={{ width: '50%' }}
                options={modeData?.data}
                onChange={(mode) => {
                  changeTypeMode({ mode, id: value.id, typeId: value.typeId }).then(res => {
                    if (res.code === 200) {
                      retry()
                      message.success(res.msg)
                      window.location.reload()
                    } else {
                      message.error(res.msg)
                    }
                  })
                }}
              /> : <span>{value.modeDict || '按时间'}</span>
            }
          </div>
        )
      }
    },
    {
      title: '操作',
      width: 250,
      render: (value) => {
        return (
          <Typography.Link
            disabled={limit || value.typeId === 1}
            onClick={() => {
              setVisible(true)
              setType('type')
              setTypeCostData(value)
            }}>编辑</Typography.Link>
        )
      }
    },
  ];
  return (
    <div>
      <Table
        isLoading={isLoading}
        columns={columns}
        dataSource={typeData}
        rowKey={record => record.id}
        size="small"
        pagination={false} />
      <DrawerModal title={'编辑'} visible={visible} setVisible={setVisible}>
        <CostProjectEdit typeCostData={typeCostData} type={type} retry={retry} visible={visible} setVisible={setVisible} />
      </DrawerModal>
    </div>
  )
}

//按球场
export const ProjectTable = ({ pagination, setType, type, size, pageSize }) => {
  const [visible, setVisible] = useState(false)
  const [state, dispatch] = useReducer((state, action) => ({ ...state, ...action }), {
    page: 1,
    pageSize
  })
  // const { data: modeData } = useSelect('club_charging_mode') //查询模式
  const { data: groundData, isLoading, retry } = useGroundCostList(state) //查询球场列表
  const [projectData, setprojectData] = useState()
  // const { changeGroundMode } = useChangeGroundMode() //改变球场模式
  const limit = useIsLimit('*:*:*')

  const columns = [
    {
      title: '球场ID',
      dataIndex: 'projectId',
      sorter: (a, b) => a.projectId - b.projectId
    },
    {
      title: '名称',
      dataIndex: 'projectName',
      sorter: (a, b) => a.projectName.localeCompare(b.projectName)
    },
    {
      title: '普通打位',
      dataIndex: 'commonPlace',
      render: (value,pro) => {
        // console.log(pro)
        return <span>{value || 0} {pro.mode === 2 ? '元/小时' : '元/场'}</span>
      }
    },
    {
      title: '豪华打位',
      dataIndex: 'luxuryPlace',
      render: (value,pro) => {
        return <span>{value || 0} {pro.mode === 2 ? '元/小时' : '元/场'}</span>
      }
    },

    {
      title: '操作',
      width: 250,
      render: (value) => {
        return (
          <Typography.Link
            disabled={limit}
            onClick={() => {
              setVisible(true)
              setType('project')
              setprojectData(value)
            }}>编辑</Typography.Link>
        )
      }
    },
  ];
  useEffect(() => {
    dispatch({
      pageSize
    })
  }, [pageSize, size])
  return (
    <div>
      <Table
        columns={columns}
        size={size}
        dataSource={groundData?.rows}
        pagination={pagination ?
          { pageSize: state.pageSize, total: groundData?.total, showQuickJumper: true, pageSizeOptions: [5, 10, 20, 30, 40, 50] }
          : false}
        loading={isLoading}
        onChange={(pagination) => onTableChange(pagination, dispatch)}
        rowKey={record => record.id}
      />
      <DrawerModal title={'编辑'} visible={visible} setVisible={setVisible}>
        <CostProjectEdit projectData={projectData} type={type} ProjectRetry={retry} visible={visible} setVisible={setVisible} />
      </DrawerModal>
    </div>
  )
}

//按身份折扣
export const UserTable = () => {
  const merchantId = useMerchantId()
  const { data } = useIdentityList(merchantId)

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },

    {
      title: '身份',
      dataIndex: 'identityName',
    },
    {
      title: '折扣',
      dataIndex: 'discount'
    }
  ];
  return (
    <div>
      <Table
        columns={columns}
        dataSource={data?.rows}
        size="small"
        pagination={false}
        rowKey={record => record.id}
      />
    </div>
  )
}