import React, { useState } from "react"
import { BreadComponent } from './../../component/breadcrumb';
import './index.less'
import { Menu, Layout } from 'antd';
import { StadiumDisplayBasics } from './component/stadium-display-basics';
import { StadiumAppointPrice } from './component/stadium-appoint-price';
import { StadiumPriceList } from './component/stadium-price-list';
const { Content, Sider } = Layout;

export const MiniprojectStadiumDisplay = () => {
  const breadcrumbNameMap = [
    {
      name: '球馆展示',
      url: ''
    },
  ]

  const [selectedKeys, setSelectKeys] = useState(['1'])

  const onSelect = ({ selectedKeys }) => {
    setSelectKeys(selectedKeys)
  }
  return (
    <div className="stadium_display_main grid_display">
      <div className='breadcrumb'>
        <BreadComponent breadcrumbNameMap={breadcrumbNameMap} />
      </div>
      <div className="page_main">
        <div className="stadium_page_inner">
          <Layout>
            <Sider width={236} className="site-layout-background">
              <Menu
                defaultSelectedKeys={['1']}
                selectedKeys={selectedKeys}
                onSelect={onSelect}
              >
                <Menu.Item key="1">
                  基本信息
                </Menu.Item>
                <Menu.Item key="2">
                  预约价格表
                </Menu.Item>
                <Menu.Item key="3">
                  价目表
                </Menu.Item>
              </Menu>
            </Sider>
            <Content>
              {selectedKeys[0] === '1' ? <StadiumDisplayBasics /> : selectedKeys[0] === '2' ? <StadiumAppointPrice /> : <StadiumPriceList />}
            </Content>
          </Layout>
        </div>
      </div>
    </div>
  )
}