
import { Button, Spin, Typography } from 'antd';
import { BreadComponent } from './../../component/breadcrumb';
import styled from '@emotion/styled';
import { OrderInfoTable, OrderPlayTable, OrderShopTable } from './component/lib/table-columns'
import { DrawerModal } from './../../component/drawer-modal';
import React, { useState } from 'react';
import { OrderInfoDetail } from './component/lib/order-check-out';
import { UnsettledList } from './component/unsettled-list';
import { useOrderInfoList, useConsumptionList } from './../../utils/manager-admin/order';
import { useRouteId, useSearchParam } from '../../utils/common';
// import { UnsettledIcon } from '../../component/custom-icon';
import { useEffect } from 'react';
import { PrivilegedButton } from './../../component/privileged-button';
// import { CreateOrder } from './component/lib/create-order';
import { useCanBeSettledOrder } from '../../utils/manager-admin';


export const OrderContext = React.createContext(undefined);
OrderContext.displayName = 'OrderContext'

export const OrderInfo = () => {
  const breadcrumbNameMap = [
    {
      name: '打位管理',
      url: '/'
    },
    {
      name: '订单',
      url: ''
    },
  ]
  const placeId = useRouteId()
  const orderSearchId = useSearchParam()
  const { error, isLoading, retry } = useOrderInfoList(placeId, orderSearchId)//查询订单列表
  // const orderInfoList = orderInfo?.rows
  const [visible, setVisible] = useState(false)
  const [unsettle, setUnsettle] = useState(false) //可结账单推框按钮控制
  const { data: settledOrder, error: settleError, isLoading: settleLoading } = useCanBeSettledOrder(placeId) //请求可选账单
  const [length, setLength] = useState(false) //可结账单数量长度

  const { data: ConsumptionData, isLoading: playLoading, retry: playRetry } = useConsumptionList(orderSearchId) //获取打球消费列表

  useEffect(() => {
    setLength(settledOrder?.rows?.length)
  }, [settledOrder])


  return (
    <div className="order_info_main">
      <div className='breadcrumb'>
        <BreadComponent breadcrumbNameMap={breadcrumbNameMap} />
      </div>
      <Spin spinning={isLoading}>
        <div className='page_main'>
          <div className='page_inner'>
            <OrderContext.Provider value={{ setUnsettle }}>
              <div className='table_item'>
                {/* 订单消息 */}
                <OrderInfoTable playRetry={playRetry} setVisible={setVisible} length={length} />
              </div>
              <PrivilegedButton code="club:appointment:order:query">
                <div className='table_item'>
                  {/* 打球消费 */}
                  <OrderPlayTable retry={playRetry} isLoading={playLoading} ConsumptionData={ConsumptionData} />
                </div>
              </PrivilegedButton>
              <PrivilegedButton code="club:appointment:consumption">
                <div className='table_item'>
                  {/* 商店消费 */}
                  <OrderShopTable />
                </div>
              </PrivilegedButton>
            </OrderContext.Provider>
          </div>
        </div>
        {
          error ? null : <div className='total_order'>
            <div className='total_order_play flex-btw'>
              <OrderButton type='primary' size='large' onClick={() => {
                setVisible(true)
                setUnsettle(false)
              }}>结 账</OrderButton>
            </div>
          </div>
        }
      </Spin>
      <DrawerModal title={unsettle ? '可结账单' : '结账'} unsettled={unsettle} visible={visible} setVisible={setVisible}>
        {
          unsettle ? <UnsettledList settledOrder={settledOrder} isLoading={settleLoading} error={settleError} setVisible={setVisible} /> :
            <OrderInfoDetail retry={retry} orderId={orderSearchId} visible={visible} setVisible={setVisible} />
        }
      </DrawerModal>
    </div >
  )
}


const OrderButton = styled(Button)`
width:12rem;
`