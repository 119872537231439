
import { Button, Form, Input, Space, Table, Typography } from "antd";
import React, { useState, useReducer } from "react"
import { BreadComponent } from './../../component/breadcrumb';
import './index.less'
import { PlusOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { DrawerModal } from './../../component/drawer-modal';
import { AddUserForm } from './component/lib/add-user';
import { useMemberList } from './../../utils/business-admin/index';
import { useNavigate } from 'react-router-dom';
import { onTableChange, useMerchantId } from './../../utils/common';
import { PutErrorBox } from './../../component/lib';
import { RefreshIcon, IdentifyIcon } from './../../component/custom-icon';

export const PlayerAdmin = () => {
  const [form] = Form.useForm()
  const merchantId = useMerchantId()
  const navigate = useNavigate()
  const breadcrumbNameMap = [
    {
      name: '会员管理',
      url: ''
    },
  ]
  const columns = [
    {
      title: '编号',
      dataIndex: 'id',
    },
    {
      title: '昵称',
      dataIndex: 'userName',
      sorter: (a, b) => a.userName.localeCompare(b.userName)
    },
    {
      title: '身份',
      dataIndex: 'identityDict',
      sorter: (a, b) => a.identityDict.localeCompare(b.identityDict)
    },
    {
      title: '会员号',
      dataIndex: 'memberNo',
      sorter: (a, b) => a.memberNo.localeCompare(b.memberNo)
    },
    {
      title: '折扣',
      dataIndex: 'discount',
    },
    {
      title: '电话',
      dataIndex: 'telephone',
    },
    {
      title: '最近登录',
      dataIndex: 'lastLogoutTime',
    },
    {
      title: '详情',
      key: '10',
      render: (value,) => {
        return (
          <Typography.Link><Link to={`detail/${value.id}`}>更多</Link></Typography.Link>
        )
      }
    },
  ]
  const [state, dispatch] = useReducer((state, action) => ({ ...state, ...action }), {
    page: 1,
    pageSize: 10,
    userName: '',
    memberNo: '',
    merchantId
  })
  const [isAddUser, setIsAddUser] = useState(false)
  const [visible, setVisible] = useState(false)
  const { data: memberData, isLoading, retry, error, setError } = useMemberList(state) //获取玩家列表

  const onFinish = (value) => {
    dispatch({
      ...value
    })
  }

  return (
    <div className="play_admin_main grid_display">
      <div className='breadcrumb'>
        <BreadComponent breadcrumbNameMap={breadcrumbNameMap} />
      </div>
      <div className='page_main'>
        <div className="search_box">
          <PutErrorBox error={error} setError={setError} />
          <Form form={form} layout='inline' autoComplete="off" onFinish={onFinish}>
            <Form.Item label="昵称" name="userName">
              <Input placeholder="输入账号昵称" allowClear />
            </Form.Item>
            <Form.Item label="会员号" name="memberNo">
              <Input placeholder="输入会员号" allowClear />
            </Form.Item>
            <Form.Item label="电话号码" name="telephone">
              <Input placeholder="输入电话号码" allowClear />
            </Form.Item>
            <Space>
              <Button htmlType='reset'>重置</Button>
              <Button type='primary' htmlType='submit'>搜索</Button>
            </Space>
          </Form>
        </div>

        <div className="page_inner">
          <div className="flex-btw table_hd">
            <h5>用户列表</h5>
            <Space size={20}>
              <div className='flex-center setting' onClick={() => {
                navigate('/home/business/player/identity_type')
              }}>
                <IdentifyIcon />
                <span>身份管理</span>
              </div>
              <div className='flex-center setting refresh' onClick={() => retry()}>
                <RefreshIcon />
                <span>刷新</span>
              </div>
              <Button type='primary' icon={<PlusOutlined />} onClick={() => {
                setIsAddUser(true)
                setVisible(true)
              }}>添加用户</Button>
            </Space>
          </div>
          <Table
            rowKey={record => record.id}
            loading={isLoading}
            columns={columns}
            dataSource={memberData?.rows}
            onChange={(pagination) => onTableChange(pagination, dispatch)}
            pagination={{ pageSize: state.pageSize, total: memberData?.total, showQuickJumper: true, pageSizeOptions: [5, 10, 20, 30, 40, 50] }}
          />
        </div>

      </div>
      <DrawerModal title={'添加用户'} visible={visible} setVisible={setVisible}>
        <AddUserForm
          isAddUser={isAddUser}
          visible={visible}
          setVisible={setVisible}
          retry={retry}
        />
      </DrawerModal>
    </div>
  )
}