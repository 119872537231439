
import { useAsync } from './../use-async';
import { useHttp } from '../http';
import { useEffect } from 'react';
import { useCallback } from 'react';

//基本信息查询
export const useStadiumBasics = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/merchant/byuser`), [client])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}

//基本信息修改
export const useEditStadiumBasics = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const mutate = (params) => {
    return run(client(`golfonlineClub/merchant`, {
      method: 'PUT',
      data: params
    }))
  }
  return {
    mutate,
    ...result
  }
}

//预约价格表
export const useAppointPrice = (id) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/clubPlace/list?merchantId=${id}`), [client, id])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}

//修改预约价格
export const useChangePrice = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const changePrice = (params) => {
    return run(client(`golfonlineClub/clubPlace/price`, {
      method: 'PUT',
      data: params
    }))
  }
  return {
    ...result,
    changePrice
  }
}

//价目表查询
export const usePriceList = (merchantId) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/merchant/priceList/${merchantId}`), [client, merchantId])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}

//编辑价目表
export const useEditPriceRow = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const mutate = (params) => {
    return run(client(`golfonlineClub/merchant/priceList`, {
      method: 'PUT',
      data: params
    }))
  }
  return {
    ...result,
    mutate
  }
}

//价目表新增
export const useAddPriceRow = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const addPrice = (params) => {
    return run(client(`golfonlineClub/merchant/priceList`, {
      method: 'POST',
      data: params
    }))
  }
  return {
    ...result,
    addPrice
  }
}

//价目表删除
export const useDeletePriceRow = () => {
  const { run, ...asyncResult } = useAsync()
  const client = useHttp()
  const deletePrice = (id) => {
    return run(client(`golfonlineClub/merchant/priceList/${id}`, {
      method: 'DELETE',
    }))
  }
  return {
    deletePrice,
    ...asyncResult
  }
}

//编辑备注
export const useEditRemark = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const EditRemark = (params) => {
    return run(client(`golfonlineClub/merchant/remark`, {
      method: 'PUT',
      data: params
    }))
  }
  return {
    ...result,
    EditRemark
  }
}


//查询中国省份
export const useProvince = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()

  useEffect(() => {
    run(client(`golfonlineClub/district/province`))
  }, [client, run])

  return result
}

//查询城市
export const useCity = (province) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  useEffect(() => {
    run(client(`golfonlineClub/district/${province}/city`))
  }, [client, run, province])

  return result
}