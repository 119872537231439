import { Form, Input, Select } from 'antd';
import { useState, useEffect } from 'react';
import { Footer } from '../../../../component/drawer-modal';
import { useClubPlace } from '../../../../utils/stadium-info/basics-info'
import { useSelect } from './../../../../utils/use-select';
import { IdSelect } from './../../../../component/id-select';
import { useAddClubPlace, useEditClubPlace } from '../../../../utils/stadium-info/basics-info'
import { PutErrorBox } from '../../../../component/lib';

export const PositionSettingEdit = ({ setVisible, visible, isEdit, retry, id }) => {
  const [form] = Form.useForm()
  const { data } = useClubPlace(id,visible) //获取单个打位
  const clubData = data?.data
  const { data: clubPlaceType } = useSelect('club_place_type') //获取打位类型
  // const { data: clubPlaceStatus } = useSelect('club_place_status') //获取打位状态
  const { addClubPlace, isLoading: isAddLoading } = useAddClubPlace() //添加打位
  const { editClubPlace, isLoading: isEditLoading } = useEditClubPlace() //编辑打位
  const [error, setError] = useState()

  const onFinish = (values) => {
    if (isEdit) {
      editClubPlace({ ...values, id }).then((res) => {
        if (res.code === 200) {
          setVisible(false)
          retry()
        } else {
          setError(res)
        }
      })
    } else {
      addClubPlace(values).then((res) => {
        if (res.code === 200) {
          setVisible(false)
          retry()
        } else {
          setError(res)
        }
      })
    }
  }
  useEffect(() => {
    if (isEdit) {
      form.setFieldsValue(clubData)
    } else {
      form.resetFields()
    }
    setError(null)
  }, [isEdit, visible, form, clubData])

  return (
    <Form
      form={form}
      layout='horizontal'
      onFinish={onFinish}
      autoComplete="off"
      labelCol={{
        span: 3,
      }}>
      <PutErrorBox error={error} setError={setError} />
      <Form.Item label="产品名" name="productName" rules={[{ required: true, message: '请输入产品' }]}>
        <Input placeholder="请输入产品" allowClear />
      </Form.Item>
      <Form.Item label="打位名称" name="placeName" rules={[{ required: true, message: '请输入打位名称' }]}>
        <Input placeholder="请输入打位名称" allowClear />
      </Form.Item>
      <Form.Item label="lisenceID" name="roomNo" rules={[{ required: true, message: '请输入房间号' }]}>
        <Input placeholder="请输入lisenceID" allowClear />
      </Form.Item>
      <Form.Item label="中控ID" name="devId">
        <Input placeholder="请输入中控ID" allowClear />
      </Form.Item>
      <Form.Item label="类型" name="placeType" rules={[{ required: true, message: '请选择类型' }]}>
        <IdSelect options={clubPlaceType?.data} />
      </Form.Item>
      <Form.Item label="状态" name="status" rules={[{ required: true, message: '请选择状态' }]}>
        <Select>
          <Select.Option value={1}>可用</Select.Option>
          <Select.Option value={4}>不可用</Select.Option>
        </Select>
      </Form.Item>
      <Footer isLoading={isAddLoading || isEditLoading} />
    </Form>
  )
}