export const menuList = [
  {
    router: 'manager',
    id: 0,
    sub: ''
  },
  {
    router: 'operate',
    id: 1,
    sub: 'sub1'
  },
  {
    router: 'play',
    id: 2,
    sub: 'sub1'
  },
  {
    router: 'buy',
    id: 3,
    sub: 'sub1'
  },
  {
    router: 'stadium',
    id: 4,
    sub: ''
  },
  {
    router: 'player',
    id: 5,
    sub: 'sub2'
  },
  {
    router: 'administrators',
    id: 6,
    sub: 'sub2'
  },
  {
    router: 'change_password',
    id: 7,
    sub: 'sub2'
  },
  {
    router: 'display',
    id: 8,
    sub: 'sub3'
  },
  {
    router: 'goods_info',
    id: 9,
    sub: 'sub3'
  },
  {
    router: 'income_statistics',
    id: 10,
    sub: 'sub3'
  },
  {
    router: 'account_data',
    id: 11,
    sub: 'sub3'
  },
  {
    router: 'records',
    id: 12,
    sub: 'sub3'
  },
  {
    router: 'order_notice',
    id: 13,
    sub: ''
  },
  {
    router: 'email_notice',
    id: 14,
    sub: ''
  },
]