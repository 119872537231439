
import { Typography } from 'antd';
import { useAppointPrice } from './../../../utils/miniproject-admin/stadium-display';
import { useAuth } from './../../../context/auth-context';
import { EditableTablePrice } from './lib/editableTable-price';

export const StadiumAppointPrice = () => {
  const { user } = useAuth()
  const merchantId = user?.user?.merchantId
  const { data,retry,isLoading } = useAppointPrice(merchantId)

  return (
    <div className="stadium_appoint_price">
      <Typography.Title level={4}>预约价格表</Typography.Title>
      <div style={{ marginTop: '2.4rem' }}>
        <EditableTablePrice priceData={data} isLoading={isLoading} retry={retry} />
      </div>
    </div>
  )
}