import { AuthProvider } from './auth-context'
import { OrderProvider } from './order-context';

export const Providers = ({ children }) => {
  return (
    <AuthProvider>
      <OrderProvider>
        {children}
      </OrderProvider>
    </AuthProvider>
  )
}