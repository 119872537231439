import React, { useState } from "react"
import { DatePicker, Form, Input, Button } from 'antd';
import { UpOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import './search.less'
import { Row } from './../../../component/lib';
import { IdSelect } from './../../../component/id-select';
import { useSelect } from './../../../utils/use-select';
import { usePlaceNames } from './../../../utils/miniproject-admin/records';

const { RangePicker } = DatePicker;

export const SearchBox = ({ type, dispatch }) => {
  const [form] = Form.useForm()
  const [open, setOpen] = useState(false)
  const { data: clubPlaceType } = useSelect('club_place_type') //获取打位类型
  const [palceType, setPlaceType] = useState() //打位类型更改
  const { data: placeNames } = usePlaceNames(palceType) //查询打位名称
  const onOpenSelect = () => {
    setOpen(!open)
  }
  const onFinish = (values) => {
    // console.log(values)
    const { time } = values
    let startTime = undefined
    let endTime = undefined
    if (values.time) {
      startTime = new Date(time[0]).getTime()
      endTime = new Date(time[1]).getTime()
    }
    dispatch({
      ...values,
      startTime,
      endTime,
      placeType: values.placeType,
      placeId: values.placeId,
      time: ''
    })
  }
  return (
    <SearchWrapper>
      <Form
        form={form}
        layout='horizontal'
        name="userForm"
        onFinish={onFinish}
        autoComplete="off"
      >
        <SearchMain>
          <SearchLeft between={true}>
            <Form.Item name="time" label="日期:">
              <RangePicker />
            </Form.Item>
            {
              type === 'place' ?
                <Form.Item name="placeType" label={'类型'}>
                  <IdSelect
                    defaultOptionName="全部"
                    style={{ width: 200 }}
                    options={clubPlaceType?.data}
                    onSelect={setPlaceType}
                  />
                </Form.Item> :
                <Form.Item name="playerName" label='昵称:'>
                  <Input placeholder="请输入" allowClear />
                </Form.Item>
            }
            {
              type === 'place' ?
                <Form.Item name="placeId" label="打位管理:">
                  <IdSelect
                    defaultOptionName="全部"
                    style={{ width: 200 }}
                    options={placeNames?.rows}
                  />
                </Form.Item> :
                <Form.Item name="memberNo" label="会员号:">
                  <Input placeholder="请输入" allowClear />
                </Form.Item>
            }
          </SearchLeft>
          <SearchRight>
            <LongButton htmlType='reset'>
              重置
            </LongButton>
            <LongButton type="primary" htmlType="submit">
              查询
            </LongButton>
            {
              type !== 'place' ? <div className="open_close flex" onClick={onOpenSelect}>
                <span>{open ? '展开' : '收起'}</span> <div className={open ? "arrow active" : "arrow"}>
                  <UpOutlined style={{ color: '#0052D9' }} />
                </div>
              </div> : null
            }
          </SearchRight>
        </SearchMain>
        <div className={type !== 'place' && !open ? 'show search_bottom' : 'hide search_bottom'}>
          <SearchLeft between={true}>
            <FormItem name="telephone" label="电话:">
              <Input placeholder="请输入" allowClear />
            </FormItem>
            <FormItem name="orderNo" label="订单号:">
              <Input placeholder="请输入" allowClear />
            </FormItem>
          </SearchLeft>
        </div>
      </Form>
    </SearchWrapper>
  )
}
const SearchLeft = styled(Row)`
width:60%;
`
const SearchRight = styled(Row)`
width:15%;
`
const LongButton = styled(Button)`
margin-right:2rem;
`
const FormItem = styled(Form.Item)`
flex:1;
margin-right:9rem;
`

const SearchWrapper = styled.div`
width:100%;
background-color: #fff;
padding: 2.4rem 3rem 2.4rem 2.4rem;
`

const SearchMain = styled.div`
display:flex;
align-items: center;
justify-content: space-between;
width:100%;
`