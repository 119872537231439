import styled from '@emotion/styled';
import { useEffect } from 'react'
import { Button, Alert, Spin, Typography, Empty } from 'antd';

export const Row = styled.div`
  display:flex;
  align-items:center;
  justify-content:${props => props.between ? 'space-between' : undefined};
  margin-bottom:${props => props.marginBottom + 'rem'};
  >*{
    margin-top:0 !important;
    margin-bottom:0 !important;
    margin-right:${props => typeof props.gap === 'number' ? props.gap + 'rem' : props.gap ? '2rem' : undefined}
  }
`

export const ButtonNoPadding = styled(Button)`
padding:0;
`
const Pagefull = styled.div`
width:100vw;
height:100vh;
position:fixed;
display:flex;
justify-content:center;
align-items:center;`

export const PageLoading = () => {
  return <Pagefull>
    <Spin size="large" />
  </Pagefull>
}

export const PageCallback = ({ error }) => {
  return <Pagefull>
    <Typography.Text type={'danger'}>{error?.msg || '服务器错误,请刷新页面'}</Typography.Text>
  </Pagefull>
}

export const GetErrorBox = ({ error }) => {
  if (error) {
    return <div className='mb-30'>
      <Alert type="error" message={error?.msg || '服务器错误'} banner closable />
    </div>
  }
  return <></>
}

export const PutErrorBox = ({ error, setError }) => {
  if (error) {
    return <div className='mb-30'>
      <Alert type="error" message={error?.msg || '服务器错误'} banner closable onClose={() => {
        setError(null)
      }} />
    </div>
  }
  return <></>
}

export const EmptyBox = ({ error }) => {
  return <div className='empty_box flex-center'>
    {/* <Empty /> */}
    <div className='empty_inner flex-column'>
      <img src={require('../assets/imgs/empty.png')} alt="" />
      <p>{error?.msg || '暂无数据'}</p>
    </div>
  </div>
}

