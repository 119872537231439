import { useAsync } from './../use-async';
import { useHttp } from '../http';
import { useEffect } from 'react';
import { useCallback } from 'react';

//查询会员管理列表
export const useMemberList = (params) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/member/list`, {
    data: {
      ...params
    }
  }), [client, params])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}

//单个会员管理查询
export const useMember = (id) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/member/${id}`), [client, id])

  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])

  return result
}

//添加会员管理
export const useAddMember = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const addMember = (params) => {
    return run(client(`golfonlineClub/member`, {
      method: 'POST',
      data: {
        ...params
      }
    }))
  }
  return {
    ...result,
    addMember
  }
}

//编辑会员管理
export const useEditMember = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const editMember = (params) => {
    return run(client(`golfonlineClub/member`, {
      method: 'PUT',
      data: {
        ...params
      }
    }))
  }
  return {
    ...result,
    editMember
  }
}

//获取会员卡列表
export const useCardList = (params) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/member/card/list`, {
    data: {
      ...params
    }
  }), [client, params])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}

//获取单个会员卡
export const useCard = (id, visible) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/member/card/${id}`), [client, id])

  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise, visible])

  return result
}

//新增会员卡
export const useAddCard = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const addCard = (params) => {
    return run(client(`golfonlineClub/member/card`, {
      method: 'POST',
      data: {
        ...params
      }
    }))
  }
  return {
    ...result,
    addCard
  }
}

//编辑会员卡
export const useEditCard = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const editCard = (params) => {
    return run(client(`golfonlineClub/member/card`, {
      method: 'PUT',
      data: {
        ...params
      }
    }))
  }
  return {
    ...result,
    editCard
  }
}

//删除会员卡
export const useDeleteCard = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const deleteCard = (id) => {
    return run(client(`golfonlineClub/member/card/${id}`, {
      method: 'DELETE'
    }))
  }
  return {
    ...result,
    deleteCard
  }
}

//信息统计
export const useRecordData = (params) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/member/record`, {
    data: {
      ...params
    }
  }), [client, params])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}

//统计总数
export const useRecordTotalData = (params) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/member/record/total`, {
    data: {
      ...params
    }
  }), [client, params])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}

//获取身份管理列表
export const useIdentityList = (params) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/identity/list`, {
    data: {
      ...params
    }
  }), [client, params])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}

//添加身份
export const useAddIdentity = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const addIdentity = (params) => {
    return run(client(`golfonlineClub/identity`, {
      method: 'POST',
      data: {
        ...params
      }
    }))
  }
  return {
    ...result,
    addIdentity
  }
}

//编辑身份
export const useEditIdentity = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const editIdentity = (params) => {
    return run(client(`golfonlineClub/identity`, {
      method: 'PUT',
      data: {
        ...params
      }
    }))
  }
  return {
    ...result,
    editIdentity
  }
}

//获取身份
export const useIdentityOptions = (merchantId) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/identity/list/dict`, {
    data: {
      merchantId
    }
  }), [client, merchantId])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}

//查询折扣身份详情
export const useIdentityDetail = (id) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/identity/${id}`), [client, id])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}