import { Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import { Footer } from '../../../../component/drawer-modal'
import { IdSelect } from '../../../../component/id-select';
import { useEditPriceRow, useAddPriceRow } from '../../../../utils/miniproject-admin/stadium-display'
import { PutErrorBox } from './../../../../component/lib';
import { useAuth } from './../../../../context/auth-context';
import { useSelect } from './../../../../utils/use-select';


export const PriceListEdit = ({ priceData, setVisible, visible, isAdd, retry }) => {
  const { user } = useAuth()
  const id = priceData?.id
  const merchantId = user?.user.merchantId
  const { mutate, isLoading: isEditLoading } = useEditPriceRow() //编辑
  const { addPrice, isLoading: isAddLoading } = useAddPriceRow() //增加
  const { data: modeData } = useSelect('club_charging_mode')
  const [error, setError] = useState()
  const [form] = Form.useForm()

  const onFinish = (value) => {
    const params = {
      ...value,
      merchantId
    }
    if (isAdd) {
      addPrice(params).then((res) => {
        if (res.code === 200) {
          setVisible(false)
          retry()
        } else {
          setError(res)
        }
      })
    } else {
      mutate({ id, ...params }).then((res) => {
        if (res.code === 200) {
          setVisible(false)
          retry()
        } else {
          setError(res)
        }
      })
    }
  }
  useEffect(() => {
    if (isAdd) {
      form.resetFields()
    } else {
      form.setFieldsValue(priceData)
    }
    setError(null)
  }, [priceData, form, visible, isAdd])

  return (
    <Form
      form={form}
      layout='horizontal'
      onFinish={onFinish}
      autoComplete="off"
      labelCol={{
        span: 4,
      }}>
      <PutErrorBox error={error} setError={setError} />
      <Form.Item name="projectName" label="项目名称" rules={[{ required: true, message: '请输入项目名称' }]}>
        <Input placeholder="请输入项目名称" allowClear />
      </Form.Item>
      <Form.Item name="type" label="收费模式" rules={[{ required: true, message: '请选择收费模式' }]}>
        <IdSelect options={modeData?.data} />
      </Form.Item>
      <Form.Item name="workdayDiscountPrice" label="工作日价格" rules={[{ required: true, message: '请输入工作日原价' }, () => (
        {
          validator(_, value) {
            if (value < 0) {
              return Promise.reject(new Error('请输入正确价格'));
            } else {
              return Promise.resolve()
            }
          }
        }
      )]}>
        <Input placeholder="请输入价格" type={'number'} allowClear />
      </Form.Item>
      <Form.Item name="offdayDiscountPrice" label="节假日价格" rules={[{ required: true, message: '请输入节假日原价' }, () => (
        {
          validator(_, value) {
            if (value < 0) {
              return Promise.reject(new Error('请输入正确价格'));
            } else {
              return Promise.resolve()
            }
          }
        }
      )]}>
        <Input placeholder="请输入价格" type={'number'} allowClear />
      </Form.Item>
      <Footer isLoading={isEditLoading || isAddLoading} />
    </Form>
  )

}