import { useAsync } from './use-async';
import { useHttp } from './http';
import { useEffect } from 'react';

export const useSelect = (option) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  useEffect(() => {
    run(client(`golfonlineClub/dict/data/type/${option}`))
  }, [client, run, option])
  return result
}