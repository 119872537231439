
import { Dropdown, Menu, Spin, message } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { ButtonNoPadding } from '../../../component/lib'
import { DrawerModal } from './../../../component/drawer-modal';
import { useState } from 'react';
import { showDeleteConfirm } from './../../../utils/common';
import { useClubPlaceList, useDeleteClubPlace } from './../../../utils/stadium-info/basics-info';
import { PositionSettingEdit } from './lib/position-setting-edit';
import { PrivilegedButton } from './../../../component/privileged-button';
// import { UnAppointIcon,AppointIcon } from './../../../component/custom-icon';

export const PositionSettings = () => {
  const [visible, setVisible] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const { data: clubPlaceData, retry, isLoading } = useClubPlaceList() //获取打位列表
  const clubList = clubPlaceData?.rows
  const [positionId, setPositionId] = useState() //打位的id
  const { deleteClubPlace } = useDeleteClubPlace() //删除打位

  return (
    <div className='position_settings_main'>
      <Spin size='default' spinning={isLoading}>
        <div className="position_settings_list">
          {
            clubList?.map((item, index) => <div className="settings_item" key={index}>
              <div className="settings_item_header">
                <h4>{item.placeName}</h4>
                <PrivilegedButton code="club:place:edit">
                  <div className="more" onClick={(e) => { e.stopPropagation() }}>
                    <Dropdown placement="bottomRight" overlay={<Menu>
                      <Menu.Item key="1" disabled={item?.status === 2 || item?.status === 3 ? true : false}
                        icon={<EditOutlined />} onClick={() => {
                          setVisible(true)
                          setIsEdit(true)
                          setPositionId(item.id)
                        }}>编辑</Menu.Item>
                      <Menu.Item key="2" disabled={item?.status === 2 || item?.status === 3 ? true : false} icon={<DeleteOutlined />} onClick={() => {
                        showDeleteConfirm('点击删除将删除该打位全部信息').then(res => {
                          if (res.code === 'ok') {
                            deleteClubPlace(item.id).then(res => {
                              if (res.code === 200) {
                                message.success(res.msg)
                                retry()
                              }
                            })
                          }
                        })
                      }}>删除</Menu.Item>
                    </Menu>}>
                      <ButtonNoPadding type="link">
                        <img style={{ width: '25px', height: '25px' }} src={require('../../../assets/imgs/manager/more.png')} alt="" />
                      </ButtonNoPadding>
                    </Dropdown>
                  </div>
                </PrivilegedButton>
              </div>
              <ul className="settings_item_body">
                <li>产品名：<span>{item.productName}</span></li>
                <li>智能推杆：<span>{item.intelligencePutter || '无'}</span></li>
                <li>lisenceID：<span>{item.roomNo}</span> <span className={item.registerTime ? 'binding' : 'unbound'}>{item.registerTime ? '已绑定' : '未绑定'}</span></li>
                <li>中控ID：<span>{item.devId}</span> <span className={item.devId ? 'binding' : 'unbound'}>{item.devId ? '已绑定' : '未绑定'}</span></li>
                <li>注册时间：<span>{item.registerTime}</span></li>
                <li>到期时间：<span>{item.expireTime}</span></li>
                <li>类型：<span>{item.placeTypeDict}</span></li>
                <li>状态：<span>{item.status === 4 ? '不可用' : '可用'}</span></li>
              </ul>
            </div>)
          }
          <PrivilegedButton code="club:place:edit">
            <div className="settings_item setting_add flex-center" onClick={() => {
              setVisible(true)
              setIsEdit(false)
            }}>
              <span className='add_position'>
                <PlusOutlined />
                <span>添加打位</span>
              </span>
            </div>
          </PrivilegedButton>
        </div>
      </Spin>
      <DrawerModal title={isEdit ? '编辑打位' : '添加打位'} visible={visible} setVisible={setVisible}>
        <PositionSettingEdit id={positionId} visible={visible} setVisible={setVisible} isEdit={isEdit} retry={retry} />
      </DrawerModal>
    </div>
  )
}