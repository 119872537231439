import { useAsync } from './../use-async';
import { useHttp } from '../http';
import { useEffect } from 'react';
import { useCallback } from 'react';

//获取基本信息
export const useBasicInfo = (id) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/merchant/business/${id}`), [client, id])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}

//编辑基本信息
export const useEditBasicInfo = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const editBasicInfo = (params) => {
    return run(client(`golfonlineClub/merchant/business`, {
      method: 'PUT',
      data: {
        ...params
      }
    }))
  }
  return {
    editBasicInfo,
    ...result
  }
}

//打位设置列表
export const useClubPlaceList = (params) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/clubPlace/listByBusiness`, {
    data: {
      ...params
    }
  }), [client, params])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}

//打位设置详情
export const useClubPlace = (id, visible) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/clubPlace/${id}`), [client, id])

  useEffect(() => {
    if(id){
      run(fetchPromise(), { retry: fetchPromise })
    }
    
  }, [client, run, fetchPromise,visible,id])

  return result
}

//编辑打位
export const useEditClubPlace = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const editClubPlace = (params) => {
    return run(client(`golfonlineClub/clubPlace`, {
      method: 'PUT',
      data: {
        ...params
      }
    }))
  }
  return {
    ...result,
    editClubPlace
  }
}

//添加打位
export const useAddClubPlace = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const addClubPlace = (params) => {
    return run(client(`golfonlineClub/clubPlace`, {
      method: 'POST',
      data: {
        ...params
      }
    }))
  }
  return {
    ...result,
    addClubPlace
  }
}

//删除打位
export const useDeleteClubPlace = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const deleteClubPlace = (id) => {
    return run(client(`golfonlineClub/clubPlace/${id}`, {
      method: 'DELETE'
    }))
  }
  return {
    ...result,
    deleteClubPlace
  }
}