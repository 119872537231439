import { useAsync } from './../use-async';
import { useHttp } from '../http';
import { useEffect } from 'react';
import { useCallback } from 'react';

//价目商品列表
export const useGoodsList = (params) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/goods/list`, {
    data: {
      ...params
    }
  }), [client, params])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}


//添加商品
export const useAddGoods = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const addGoods = (params) => {
    return run(client(`golfonlineClub/goods`, {
      method: 'POST',
      data: params
    }))
  }
  return {
    ...result,
    addGoods
  }
}

//修改商品
export const useEditGoods = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const editGoods = (params) => {
    return run(client(`golfonlineClub/goods`, {
      method: 'PUT',
      data: params
    }))
  }
  return {
    ...result,
    editGoods
  }
}

//删除商品
export const useDeleteGoods = () => {
  const { run, ...asyncResult } = useAsync()
  const client = useHttp()
  const deleteGoods = (id) => {
    return run(client(`golfonlineClub/goods/${id}`, {
      method: 'DELETE',
    }))
  }
  return {
    deleteGoods,
    ...asyncResult
  }
}

//查询商品类别
export const useGoodsTypes = (merchantId) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/goods/type/list`, {
    data: {
      merchantId
    }
  }), [client, merchantId])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}

//查询商品类型
export const useGoodsType = (id) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  useEffect(() => {
    if(id){
      run(client(`golfonlineClub/goods/type/${id}`))
    }  
  }, [id, run, client])
  return result
}

//添加商品类别
export const useAddGoodsType = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const addGoodsType = (params) => {
    return run(client(`golfonlineClub/goods/type`, {
      method: 'POST',
      data: params
    }))
  }
  return {
    ...result,
    addGoodsType
  }
}

//编辑商品类别
export const useEditGoodsType = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const editGoodsType = (params) => {
    return run(client(`golfonlineClub/goods/type`, {
      method: 'PUT',
      data: params
    }))
  }
  return {
    ...result,
    editGoodsType
  }
}

//删除商品类别
export const useDeteleGoodsType = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const deleteGoodsType = (id) => {
    return run(client(`golfonlineClub/goods/type/${id}`, {
      method: 'DELETE',
    }))
  }
  return {
    ...result,
    deleteGoodsType
  }
}

