import { Form, Space } from "antd";
import React from "react"
import { BreadComponent } from './../../component/breadcrumb';
import './index.less'
import styled from '@emotion/styled';
import { BasicsInfo } from './component/basics-info';
// import { UsableFund } from './component/usable-fund'; 二期的功能 
import { PositionSettings } from './component/position-settings';


export const StadiumIndex = () => {
  const breadcrumbNameMap = [
    {
      name: '球馆信息',
      url: ''
    }
  ]

  return (
    <div className="stadium_info_main">
      <div className='breadcrumb'>
        <BreadComponent breadcrumbNameMap={breadcrumbNameMap} />
      </div>
      <div className='page_main'>
        <div className="basics_info stadium_item">
          <BasicsInfo />
        </div>
        {/* 这里是二期的功能 */}
        {/* <div className="stadium_item">
          <UsableFund />
        </div> */}
        <div className="stadium_item">
          <div className="stadium_header">
            <h4>打位设置</h4>
          </div>
          <div className="stadium_body">
            <PositionSettings />
          </div>
        </div>
      </div>
    </div>
  )
}

export const FormItem = styled(Form.Item)`
flex:1
`
export const SpaceBox = styled(Space)`
margin-left:13.4rem;
margin-bottom:2.4rem;
`