import React, { useState } from "react"
import styled from "@emotion/styled"
import { Button, Form, Input, Checkbox, Alert } from "antd"
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import Logo from './assets/imgs/login_bg.jpg'
import { useAuth } from './context/auth-context';
import { useAsync } from './utils/use-async';
import { JSEncrypt } from 'jsencrypt'
import { pubKey, priKey } from './utils/jsencrypt'
import { useMount } from './utils/index';
import { useCookies } from 'react-cookie';

export const UnauthenticatedApp = () => {
  const [form] = Form.useForm()
  const { login } = useAuth()
  const { run, isLoading } = useAsync(undefined, { throwError: true })
  const [error, setError] = useState(null)
  const [cookies, setCookie, removeCookie] = useCookies(['login_pass', 'remeber_status', 'username']);
  const onFinish = (values) => {
    const { username, password, remember } = values
    run(login({ username, password, remember })).then((res) => {
      jsencryptValues(username, password, remember)
    }).catch(e => {
      setError(e)
    })
  }
  //将密码和账户保存到session中
  const jsencryptValues = (username, password, remember) => {
    //设置公钥、私钥
    const encryptor = new JSEncrypt()
    encryptor.setPublicKey(pubKey)
    let Days = 7;  //cookie保存时间
    let exp = new Date();
    if (remember) {
      setCookie('login_pass', encryptor.encrypt(password), { path: '/', expires: new Date(exp.getTime() + Days * 24 * 60 * 60 * 1000) })
      setCookie('remeber_status', remember, { path: '/', expires: new Date(exp.getTime() + Days * 24 * 60 * 60 * 1000) })
      setCookie('username', username, { path: '/', expires: new Date(exp.getTime() + Days * 24 * 60 * 60 * 1000) })
    } else {
      removeCookie('login_pass', { path: '/' })
      setCookie('remeber_status', remember, { path: '/', expires: new Date(exp.getTime() + Days * 24 * 60 * 60 * 1000) })
      setCookie('username', username, { path: '/', expires: new Date(exp.getTime() + Days * 24 * 60 * 60 * 1000) })
    }
  }
  //获取cookie进行解密
  const getCookie = () => {
    const decryptor = new JSEncrypt()
    decryptor.setPrivateKey(priKey)
    const username = cookies.username
    const password = decryptor.decrypt(cookies?.login_pass) ? decryptor.decrypt(cookies?.login_pass) : undefined
    const remember = cookies.remeber_status === 'false' ? false : true
    form.setFieldsValue({
      username,
      password,
      remember
    })
  }
  useMount(getCookie)
  return (
    <Container data-component="login">
      <div className="flex-btw login_inner">
        <div className="welcome_box">
          <img src={require('./assets/imgs/login_welcome.png')} alt="" />
        </div>
        <div className="form_main">
          <div className="form_logo flex-center">
            <img src={require('./assets/imgs/login_logo.png')} alt="" />
          </div>
          <h3>欢迎登录衡泰信会所管理系统！</h3>
          <div className="form_title">
            {error ? <Alert type="error" message={error.msg} banner closable onClose={() => {
              setError(null)
            }} /> : <h4>账号密码登录</h4>}
          </div>
          <Form
            initialValues={{ remember: false }}
            onFinish={onFinish}
            autoComplete="off"
            form={form}
          >
            <Form.Item name={'username'} rules={[{ required: true, message: '请输入账号名称' }]}>
              <Input size="large"
                type="text"
                autoComplete="off"
                placeholder="请输入账户"
                prefix={<UserOutlined className="site-form-item-icon" />}
                allowClear
              />
            </Form.Item>
            <Form.Item name={'password'} rules={[{ required: true, message: '请输入账号名称' }]}>
              <Input
                size="large"
                type="password"
                placeholder="请输入密码"
                autoComplete="current-password"
                prefix={<LockOutlined className="site-form-item-icon" />}
                allowClear
              />
            </Form.Item>
            <Form.Item name={'remember'} valuePropName="checked">
              <Checkbox>记住密码</Checkbox>
            </Form.Item>
            <LongButton loading={isLoading} htmlType="submit" type='primary' size="large">登录</LongButton>
          </Form>
        </div>
      </div>
    </Container>

  )
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background:url(${Logo}) no-repeat center;
  background-size:cover;
  background-attachment:fixed;
  background-repeat:no-repeat;
  background-position:center;
`
const LongButton = styled(Button)`
  width:100%;
`