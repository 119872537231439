
import { Form, Input } from 'antd';
import { Footer } from '../../../component/drawer-modal'
import { IdSelect } from '../../../component/id-select';
import { useSelect } from './../../../utils/use-select';
import { useEffect } from 'react';
import { useEditDefaultCost } from '../../../utils/manager-admin/cost-setting';
import { PutErrorBox } from '../../../component/lib';
import { useEditTypeCost, useEditGroundCost } from './../../../utils/manager-admin/cost-setting';

//按球场和类型
export const CostProjectEdit = ({ type, setVisible, retry, ProjectRetry, visible, typeCostData, projectData }) => {
  const [form] = Form.useForm()
  // const { data: modeData } = useSelect('club_charging_mode') //查询收费模式
  // const { data: typeData } = useSelect('golf_game_type') // 查询类型

  //编辑按类型
  const { editTypeCost, isLoading: editTypeLoading, error: typeError, setError: setTypeError } = useEditTypeCost()

  //编辑按球场
  const { editGroundCost, isLoading: editGroundLoading, error: groungError, setError: setGroundError } = useEditGroundCost()

  const onFinish = (values) => {
    if (type === 'type') {
      editTypeCost({ ...values, id: typeCostData.id }).then(res => {
        if (res.code === 200) {
          retry()
          setVisible(false)
          window.location.reload()
        }
      })
    } else {
      editGroundCost({ ...values, id: projectData.id }).then(res => {
        if (res.code === 200) {
          ProjectRetry()
          setVisible(false)
          window.location.reload()
        }
      })
    }
  }

  useEffect(() => {
    if (type === 'type') {
      form.setFieldsValue(typeCostData)
    } else {
      form.setFieldsValue(projectData)
    }
    setTypeError(null)
    setGroundError(null)
  }, [type, form, visible, typeCostData, setTypeError, projectData, setGroundError])

  return (
    <div className="cost_edit_main">
      <PutErrorBox error={typeError || groungError} setError={setTypeError || setGroundError} />
      <Form
        form={form}
        onFinish={onFinish}
        layout='horizontal'
        autoComplete='off'
        labelCol={{
          span: 3,
        }}>
        <Form.Item name={type === 'type' ? 'typeId' : 'projectId'} label={type === 'type' ? '类型ID' : '项目ID'}>
          <Input disabled />
        </Form.Item>
        <Form.Item name={type === 'type' ? 'typeName' : 'projectName'} label="名称">
          <Input placeholder='请输入名称' disabled />
        </Form.Item>
        <Form.Item name="commonPlace" label="普通打位" rules={[{ required: true, message: '请输入金额' }, () => (
          {
            validator(_, value) {
              if (value < 0) {
                return Promise.reject(new Error('请输入正确金额'));
              } else {
                return Promise.resolve()
              }
            }
          }
        )]}>
          <Input placeholder='请输入金额' type={'number'} allowClear />
        </Form.Item>
        <Form.Item name="luxuryPlace" label="豪华打位" rules={[{ required: true, message: '请输入金额' }, () => (
          {
            validator(_, value) {
              if (value < 0) {
                return Promise.reject(new Error('请输入正确金额'));
              } else {
                return Promise.resolve()
              }
            }
          }
        )]}>
          <Input placeholder='请输入金额' type={'number'} allowClear />
        </Form.Item>

        <Footer isLoading={editTypeLoading || editGroundLoading} />
      </Form>
    </div >
  )
}

//按默认类型
export const CostDefaultEdit = ({ defaultCost, setVisible, retry, visible }) => {
  const [form] = Form.useForm()
  const { data: clubPlaceType } = useSelect('club_place_type') //获取打位类型
  const { editDefaultCost, isLoading, error, setError } = useEditDefaultCost() //编辑收费模式

  const onFinish = (values) => {
    editDefaultCost({ ...values, id: defaultCost.id }).then(res => {
      if (res.code === 200) {
        setVisible(false)
        retry()
        window.location.reload()
      }
    })
  }

  useEffect(() => {
    form.setFieldsValue(defaultCost)
    setError(null)
  }, [form, defaultCost, visible, setError])

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout='horizontal'
      labelCol={{
        span: 3,
      }}>
      <PutErrorBox error={error} setError={setError} />
      <Form.Item name="placeType" label="模式">
        <IdSelect disabled={true} options={clubPlaceType?.data} />
      </Form.Item>
      <Form.Item name="frequencyCost" label="元/场" rules={[{ required: true, message: '请输入金额' }]}>
        <Input placeholder='请输入金额' allowClear />
      </Form.Item>
      <Form.Item name="timeCost" label="元/时" rules={[{ required: true, message: '请输入金额' }]}>
        <Input placeholder='请输入金额' allowClear />
      </Form.Item>
      <Footer isLoading={isLoading} />
    </Form>
  )
}