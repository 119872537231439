
import { Popconfirm, message, Typography, Input, TimePicker } from 'antd';
import { useReducer } from 'react';
import { useContinuationDevice, useGoBackDevice } from './../../../../utils/manager-admin/order';

export const OrderConfirmEdit = ({ value, type, retry,playRetry }) => {
  const [state, dispatch] = useReducer((state, action) => ({ ...state, ...action }), {
    continueTime: 10,
    continuationPop: false,
    backEndTime: ''
  })
  const { continueTime, continuationPop, backEndTime } = state
  const { continuationDevice } = useContinuationDevice() //续时
  const { goBackDevice } = useGoBackDevice() //返场

  const onConfirm = () => {
    if (type === 1) {
      if (!continueTime) {
        message.error('请填写正确时长')
        return false
      }
      continuationDevice({ id: value.orderId, continueTime }).then(res => {
        if (res.code === 200) {
          message.success(res.msg)
          retry()
          playRetry()
          dispatch({ continuationPop: false })
        } else {
          message.error(res.msg)
        }
      })
    } else {
      if (!backEndTime) {
        message.error('请选择结束时间')
        return
      }
      goBackDevice({ id: value.orderId, endTime: backEndTime }).then(res => {
        if (res.code === 200) {
          retry()
          playRetry()
          message.success(res.msg)
          dispatch({ continuationPop: false })
        } else {
          message.error(res.msg)
        }
      })
    }
  }

  return (
    <Popconfirm
      placement="bottomRight"
      title={
        type === 1 ?
          <div className='confirm_delayed flex-center'>
            <span className='mr-10'>延长</span>
            <Input 
            style={{ width: '10rem' }} 
            type={'number'} size="middle" 
            value={continueTime} 
            onChange={(e) => {
              dispatch({
                continueTime: e.target.value
              })
            }} />
            <span className='ml-10'>分钟</span>
          </div> : <div className='confirm_delayed flex-center'>
            <span style={{ marginRight: '1rem' }}>结束时间</span>
            <TimePicker
              format={'HH:mm'}
              size='small'
              onChange={(time) => {
                const backEndTime = new Date(time).getTime()
                dispatch({ backEndTime })
              }} />
          </div>
      }
      visible={continuationPop}
      icon={<></>}
      okText="确定"
      cancelText="取消"
      onConfirm={onConfirm}
      onCancel={() => dispatch({ continuationPop: false })}
    >
      {
        type === 1 ? <Typography.Link
          disabled={value.appointmentStatus === 5 || value.appointmentStatus === 2 || value.appointmentStatus === 1 || value.appointmentStatus === 8}
          onClick={() => dispatch({ continuationPop: !continuationPop })}
        >加时</Typography.Link> : <Typography.Link
          disabled={value.appointmentStatus === 4 || value.appointmentStatus === 1 || value.appointmentStatus === 2}
          onClick={() => dispatch({ continuationPop: !continuationPop })}
        >返场</Typography.Link>
      }
    </Popconfirm>
  )
}

// const ConfirmInput = styled(Input)`
// width:10rem;
// margin:0 1rem;
// `