import { BreadComponent } from './../../component/breadcrumb';
import styled from '@emotion/styled';
import { SearchBox } from './component/search-box';
import { Table } from 'antd';
import { useState, useReducer } from 'react';
import { useBuyData } from './../../utils/data-count/buy-data';
import { onTableChange } from '../../utils/common';
import { DrawerModal } from './../../component/drawer-modal';
import { UsersTable, PlayDetailTable, PlaySettlementMethod, GoodsTable } from './component/buy-detail';
import { formatTime } from './../manager-admin/component/club-place-status';
import { GetErrorBox } from '../../component/lib';

//消费数据列表
export const BuyData = () => {
  const breadcrumbNameMap = [
    {
      name: '消费数据',
      url: ''
    }
  ]
  const [state, dispatch] = useReducer((state, action) => ({ ...state, ...action }), {
    page: 1,
    pageSize: 10,
    startTime: '',
    endTime: '',
    memberNo: '',
    telephone: '',
    orderNo: ''
  })
  const { data, isLoading, error } = useBuyData(state) //打球数据列表
  const [visible, setVisible] = useState(false)
  const [buyData, setBuyData] = useState() //
  const columns = [
    {
      title: '订单号',
      dataIndex: 'orderNo',
      sorter: (a, b) => a.orderNo.localeCompare(b.orderNo),
      fixed: 'left',
    },
    {
      title: '打位',
      dataIndex: 'placeName',
      sorter: (a, b) => a.placeName.localeCompare(b.placeName)
    },
    {
      title: '开始时间',
      dataIndex: 'startTime',
      key: '2',
    },
    {
      title: '结束时间',
      dataIndex: 'endTime',
      key: '3',
    },
    {
      title: '昵称',
      dataIndex: 'userName',
      sorter: (a, b) => a.memberNo.localeCompare(b.memberNo),
      render:(value)=>{
        return <>{value || '-'}</>
      }
    },
    {
      title: '身份',
      dataIndex: 'identityDict',
      render:(value)=>{
        return <>{value || '游客'}</>
      }
    },
    {
      title: '会员号',
      dataIndex: 'memberNo',
      sorter: (a, b) => a.memberNo.localeCompare(b.memberNo),
      render:(value)=>{
        return <>{value || '-'}</>
      }
    },
    {
      title: '电话',
      dataIndex: 'telephone',
      sorter: (a, b) => a.telephone - b.telephone
    },
    {
      title: '耗时',
      dataIndex: 'duration',
      render: (value) => {
        return <>{formatTime(value)}</>
      }
    },
    {
      title: '费用',
      render:(value)=>{
        // console.log(value)
        return <>{Number(value.amount) + Number(value.cardDeduction)}</>
      }
    },
    {
      title: '详情',
      render: (value) => {
        return (
          <div className='table_edit' onClick={() => {
            setVisible(true)
            setBuyData(value)
          }}>查看</div>
        )
      }
    },
  ]

  return (
    <div>
      <div className='breadcrumb'>
        <BreadComponent breadcrumbNameMap={breadcrumbNameMap} />
      </div>
      <DataPageMain>
        <SearchWrapper>
          <SearchBox type={'play'} dispatch={dispatch} />
        </SearchWrapper>
      </DataPageMain>
      <div className='page_main'>
        <GetErrorBox error={error} />
        <div className='page_inner'>
          <h4 className="table_list_title">消费数据</h4>
          <Table
            columns={columns}
            dataSource={data?.rows}
            rowKey={record => record.orderId}
            loading={isLoading}
            onChange={(pagination) => onTableChange(pagination, dispatch)}
            pagination={{ pageSize: state.pageSize, total: data?.total, showQuickJumper: true, pageSizeOptions: [5, 10, 20, 30, 40, 50] }}
          />
        </div>
      </div>

      <DrawerModal
        title={buyData?.placeName + "详情"}
        visible={visible}
        setVisible={setVisible}
        width={980}
        forceRender={true}
      >
        <div className='table_item'>
          <h4 className='table_title title-icon'>登录账号</h4>
          <UsersTable orderId={buyData?.orderId} />
        </div>
        <div className='table_item'>
          <h4 className='table_title title-icon'>打球明细</h4>
          <PlayDetailTable orderId={buyData?.orderId} />
        </div>
        <div className='table_item'>
          <h4 className='table_title title-icon'>快消明细</h4>
          <GoodsTable orderId={buyData?.orderId} />
        </div>
        <div className='table_item'>
          <h4 className='table_title title-icon'>结算方式</h4>
          <PlaySettlementMethod buyData={buyData} />
        </div>
      </DrawerModal>
    </div>
  )
}

const DataPageMain = styled.div`
width:100%;
padding:2.4rem 2.4rem 0;
`
const SearchWrapper = styled.div`
width:100%;
`