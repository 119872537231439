import { useAccountSheet } from '../../../utils/miniproject-admin/account-data'

export const AccountSheet = ({accountId}) => {
  const { data } = useAccountSheet(accountId)
  const accountData = data?.data
  return (
    <div className='flex-center'>
      <div className='sheet_box'>
        <div className='sheet_box_title flex-btw'>
          <span>单号：{accountData?.settlementNo}</span>
          <span>{accountData?.settlementRange}</span>
        </div>
        <ul className='sheet_box_content'>
          <li className='flex-center'>
            <p>商户ID</p>
            <p>{accountData?.merchantId}</p>
          </li>
          <li className='flex-center'>
            <p>商户名</p>
            <p>{accountData?.merchantName}</p>
          </li>
          <li className='flex-center'>
            <p>收款账号</p>
            <p>{accountData?.accountNumber}</p>
          </li>
          <li className='flex-center'>
            <p>收款人</p>
            <p>{accountData?.payee}</p>
          </li>
          <li className='flex-center'>
            <p>总额</p>
            <p>{accountData?.revenueAmount}元</p>
          </li>
          <li className='flex-center'>
            <p>费率</p>
            <p>{accountData?.feeRate}</p>
          </li>
          <li className='flex-center'>
            <p>结算金额</p>
            <p>{accountData?.settlementAmount}元</p>
          </li>
          <li className='flex-center'>
            <p>汇款方式</p>
            <p>{accountData?.remittanceMethodDict}</p>
          </li>
        </ul>
      </div>
    </div>
  )
}