
import { BreadComponent } from './../../component/breadcrumb';
import styled from '@emotion/styled';
import { SearchBox } from './component/search-box';
import { Table } from 'antd';
import { ExpandedRow } from './../../component/table-inner';
import { usePlayData } from './../../utils/data-count/play-data';
import { useReducer } from 'react';
import { GetErrorBox } from '../../component/lib';
import { onTableChange } from '../../utils/common';
import { formatTime } from './../manager-admin/component/club-place-status';


//打球数据
export const PlayData = () => {
  const breadcrumbNameMap = [
    {
      name: '打球数据',
      url: ''
    }
  ]
  const [state, dispatch] = useReducer((state, action) => ({ ...state, ...action }), {
    page: 1,
    pageSize: 10,
    startTime: '',
    endTime: '',
    memberNo: '',
    telephone: '',
    orderNo: ''
  })
  const columns = [
    {
      title: '订单号',
      dataIndex: 'orderNo',
      sorter: (a, b) => a.orderNo.localeCompare(b.orderNo),
      fixed: 'left',
    },
    {
      title: '打位',
      dataIndex: 'placeName',
      sorter: (a, b) => a.placeName.localeCompare(b.placeName),
    },
    {
      title: '开始时间',
      dataIndex: 'startTime',
      key: '2',
    },
    {
      title: '结束时间',
      dataIndex: 'endTime',
      key: '3',
    },
    {
      title: '昵称',
      dataIndex: 'playerName',
      sorter: (a, b) => a.placeName.localeCompare(b.placeName)
    },
    {
      title: '身份',
      dataIndex: 'identityDict',
      render: (value) => {
        return <>{value || '游客'}</>
      }
    },
    {
      title: '会员号',
      dataIndex: 'memberNo',
      sorter: (a, b) => a.memberNo.localeCompare(b.memberNo),
      render: (value) => {
        return <>{value || '-'}</>
      }
    },
    {
      title: '电话',
      dataIndex: 'telephone',
      render: (value) => {
        return <>{value || '-'}</>
      }
    },
    { title: '项目名称', dataIndex: 'itemName', key: '8', },
    { title: '球场', dataIndex: 'court', key: '9', },
    { title: '类型', dataIndex: 'type', key: '10', },
    { title: '开始洞', dataIndex: 'startHole', key: '11', },
    { title: '结束洞', dataIndex: 'endHole', key: '12', },
    { title: '总杆', dataIndex: 'totalRods', key: '13', },
    {
      title: '耗时',
      dataIndex: 'timeConsuming',
      key: '14',
      render: (value) => {
        return <>{formatTime(value)}</>
      }
    }
  ]

  const { data, isLoading, error } = usePlayData(state) //打球数据列表
  // console.log(data)
  return (
    <div>
      <div className='breadcrumb'>
        <BreadComponent breadcrumbNameMap={breadcrumbNameMap} />
      </div>
      <DataPageMain>
        <SearchBox type={'play'} dispatch={dispatch} />
      </DataPageMain>
      <div className='page_main'>
        <div className='page_inner'>
          <GetErrorBox error={error} />
          <h4 className="table_list_title">打球数据</h4>
          <Table
            columns={columns}
            dataSource={data?.rows}
            rowKey={(record) => record.id}
            loading={isLoading}
            scroll={{ x: '100vw' }}
            onChange={(pagination) => onTableChange(pagination, dispatch)}
            pagination={{ pageSize: state.pageSize, total: data?.total, showQuickJumper: true, pageSizeOptions: [5, 10, 20, 30, 40, 50] }}
            expandable={{
              expandedRowRender: (record) => {
                return ExpandedRow(record)
              },
              rowExpandable: (record) => {
                console.log(record?.detailList)
                return record?.detailList?.length
              },
              fixed: 'right'
            }}
            sticky
          />
        </div>
      </div>
    </div>
  )
}

const DataPageMain = styled.div`
width:100%;
padding:2.4rem 2.4rem 0;
`