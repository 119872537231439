import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';
import { useAsync } from './use-async';
import { useHttp } from './http'
import { useAuth } from './../context/auth-context';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

const { confirm } = Modal

export const showDeleteConfirm = (content) => {
  return new Promise(resolve => {
    confirm({
      title: '确定删除？',
      content: content,
      icon: <ExclamationCircleFilled twoToneColor="#ED7B2F" />,
      okText: '确定',
      cancelText: '取消',
      onOk() {
        resolve({ code: 'ok' })
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  })
}

export const useDeleteFile = () => {
  const { run, ...asyncResult } = useAsync()
  const client = useHttp()
  const deleteFile = (id) => {
    return run(client(`golfonlineClub/multimedia/${id}`, {
      method: 'DELETE'
    }))
  }
  return {
    deleteFile,
    ...asyncResult
  }
}

//获取商户id
export const useMerchantId = () => {
  const { user } = useAuth()
  return user?.user.merchantId
}

//获取路由
export const useRouteId = () => {
  const units = useLocation().pathname.split('/')
  return units[units.length - 1]
}

export const useSearchParam = () => {
  const units = useLocation().search.split('=')
  return units[units.length - 1]
}

//禁止今天之前的日期
export const disabledDate = (current) => {
  return current && current < moment().startOf('day');
}

function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

//当前之前的时间
export const disabledDateTime = () => {
  const date = new Date()
  const h = date.getHours()
  return {
    disabledHours: () => range(0, 24).splice(0, h)
  };
}

//列表分页
export const onTableChange = (pagination, dispatch) => {
  dispatch({
    page: pagination.current,
    pageSize: pagination.pageSize
  })
}

//数组扁平化
export const flatten = (arr) => {
  return arr.reduce((result, item) => {
    return result.concat(Array.isArray(item) ? flatten(item) : item)
  }, [])
}

//递归
export const recurrence = (arr) => {
  const temKeys = []
  const keys = arr?.map(item => {
    if (item?.hasOwnProperty('children')) {
      temKeys.push(item.key)
      return recurrence(item.children)
    }
    return item.key
  })
  return temKeys?.concat(keys)
}

//权限管理
export const useIsLimit = (code) => {
  const { user } = useAuth()
  const permissions = user?.user.permissions
  return permissions.includes('*:*:*') || permissions.includes(code) ? false :true
}