
import { BreadComponent } from './../../component/breadcrumb';
import { Tabs } from 'antd';
import { PlayerBasicsInfo } from './component/player-basics-info';
import { PlayerBasicCard } from './component/player-basics-card';
import { PlayerDetailStatistic } from './component/player-detail-statistics';
import { useRouteId } from '../../utils/common'

const { TabPane } = Tabs;

export const PlayerDetail = () => {
  const breadcrumbNameMap = [
    {
      name: '会员管理',
      url: '/home/business/player'
    },
    {
      name: '玩家详情',
      url: ''
    },
  ]
  const memberUserId = useRouteId()

  return (
    <div className='player_detail_main'>
      <div className='breadcrumb'>
        <BreadComponent breadcrumbNameMap={breadcrumbNameMap} />
      </div>
      <Tabs defaultActiveKey="1" size='large'>
        <TabPane tab="基础信息" key="1">
          <div className='page_main'>
            <PlayerBasicsInfo memberUserId={memberUserId} />
            <PlayerBasicCard memberUserId={memberUserId} />
          </div>
        </TabPane>
        <TabPane tab="统计" key="2">
          <PlayerDetailStatistic memberUserId={memberUserId} />
        </TabPane>
      </Tabs>
    </div>
  )
}