
import { Form, Input, Button, Space, Table, Typography, Divider } from 'antd';
import { BreadComponent } from './../../component/breadcrumb';
import { PlusOutlined } from '@ant-design/icons';
import { DrawerModal } from './../../component/drawer-modal';
import { useState, useReducer } from 'react';
import { AuthorityManagement } from './component/authority-management';
import { AddAdminForm } from './component/lib/add-user'
import { useAdminList, useDeleteAdmin } from './../../utils/business-admin/admin';
import { message } from 'antd';
import { onTableChange, showDeleteConfirm } from './../../utils/common';
import { GetErrorBox } from '../../component/lib';
import { LimitIcon, RefreshIcon } from './../../component/custom-icon';


export const Administrators = () => {
  const [form] = Form.useForm()
  const breadcrumbNameMap = [
    {
      name: '管理员',
      url: ''
    },
  ]
  const [state, dispatch] = useReducer((state, action) => ({ ...state, ...action }), {
    page: 1,
    pageSize: 9,
    userName: ''
  })
  const [modeType, setModeType] = useState(1)
  const [visible, setVisible] = useState(false)
  const { data, retry, isLoading, error } = useAdminList(state) //查询管理员列表
  const adminList = data?.rows
  const { deleteAdmin } = useDeleteAdmin() //删除管理员
  const [adminData, setAdminData] = useState()
  const columns = [
    {
      title: '用户名',
      dataIndex: 'userName',
    },
    {
      title: '真实姓名',
      dataIndex: 'nickName',
    },
    {
      title: '级别',
      dataIndex: 'roleName',
    },
    {
      title: '操作',
      render: (value) => {
        return (
          <div>
            <Typography.Link onClick={() => {
              setModeType(1)
              setVisible(true)
              setAdminData(value)
            }}>修改</Typography.Link>
            <Divider type='vertical' />
            <Typography.Link
              disabled={value.roleKey === 'admin'}
              onClick={() => {
                showDeleteConfirm().then(res => {
                  if (res.code === 'ok') {
                    deleteAdmin(value.userId).then(res => {
                      if (res.code === 200) {
                        message.success(res.msg)
                        retry()
                      }
                    })
                  }
                })

              }}>删除</Typography.Link>
          </div>
        )
      }
    },
  ]
  //查询
  const onFinish = (value) => {
    dispatch({
      userName: value.userName
    })
  }

  return (
    <div className='administrators_main grid_display'>
      <div className='breadcrumb'>
        <BreadComponent breadcrumbNameMap={breadcrumbNameMap} />
      </div>
      <div className='page_main'>
        <div className="search_box">
          <Form form={form} layout='inline' onFinish={onFinish} autoComplete="off">
            <Form.Item name="userName">
              <Input placeholder="输入用户名" allowClear />
            </Form.Item>
            <Space>
              <Button htmlType='reset'>重置</Button>
              <Button type='primary' htmlType='submit'>查询</Button>
            </Space>
          </Form>
        </div>
        <div className='page_inner'>
          <GetErrorBox error={error} />
          <div className="flex-btw table_hd">
            <h5>管理员列表</h5>
            <Space size={20}>
              <div className='flex-center setting' onClick={() => {
                setModeType(2)
                setVisible(true)
              }}>
                <LimitIcon />
                <span>权限管理</span>
              </div>
              <div className='flex-center setting refresh' onClick={() => retry()}>
                <RefreshIcon />
                <span>刷新</span>
              </div>
              <Button
                type='primary'
                icon={<PlusOutlined />}
                onClick={() => {
                  setModeType(3)
                  setVisible(true)
                }}>添加管理员</Button>
            </Space>
          </div>
          <Table
            columns={columns}
            dataSource={adminList}
            loading={isLoading}
            rowKey={record => record.userId}
            onChange={(pagination) => onTableChange(pagination, dispatch)}
            pagination={{ pageSize: state.pageSize, total: data?.total, showQuickJumper: true, pageSizeOptions: [5, 10, 20, 30, 40, 50] }}
          />
        </div>
      </div>
      <DrawerModal
        title={modeType === 1 ? '修改管理员' : modeType === 2 ? '权限管理' : '添加管理员'}
        visible={visible}
        setVisible={setVisible}
        width={modeType === 2 ? '50%' : '35%'}
      >
        {
          modeType === 2 ? <AuthorityManagement visible={visible} setVisible={setVisible} /> :
            <AddAdminForm modeType={modeType} visible={visible} setVisible={setVisible} adminData={adminData} retry={retry} />
        }
      </DrawerModal>
    </div>
  )
}