import { BreadComponent } from './../../component/breadcrumb';
import { Table, Typography } from 'antd'
import { DrawerModal } from './../../component/drawer-modal';
import { useState, useReducer } from 'react';
import { useAccountData } from './../../utils/miniproject-admin/account-data';
import { useAuth } from '../../context/auth-context';
import { AccountSheet } from './component/account-sheet'

export const AccountData = () => {
  const { user } = useAuth()
  const breadcrumbNameMap = [
    {
      name: '数据结算',
      url: ''
    },
  ]
  const [visible, setVisible] = useState(false)
  const [accountId, setAccountId] = useState()
  const [state, dispatch] = useReducer((state, action) => ({ ...state, ...action }), {
    page: 1,
    pageSize: 10,
    merchantId: user.user.merchantId
  })
  const { pageSize } = state
  const { data } = useAccountData(state) //结算数据查询
  const accountData = data?.rows
  const columns = [
    {
      title: '结算范围',
      dataIndex: 'settlementRange',
    },
    {
      title: '营收金额（元）',
      dataIndex: 'revenueAmount',
    },
    {
      title: '费率',
      dataIndex: 'feeRate',
    },
    {
      title: '结算金额（元）',
      dataIndex: 'settlementAmount',
    },
    {
      title: '操作',
      dataIndex: '',
      render: (value, project) => {
        return <Typography.Link onClick={() => {
          setVisible(true)
          setAccountId(value.id)
        }}>查看结算单</Typography.Link>
      }
    },
  ]
  const onTableChange = (pagination) => {
    dispatch({
      page: pagination.current,
      pageSize: pagination.pageSize
    })
  }
  return (
    <div className="account_data_main grid_display">
      <div className='breadcrumb'>
        <BreadComponent breadcrumbNameMap={breadcrumbNameMap} />
      </div>
      <div className='page_main'>
        <div className="page_inner">
          <h5 className='table_hd'>收益详情</h5>
          <Table
            onChange={onTableChange}
            rowKey={record => record.id}
            columns={columns}
            dataSource={accountData}
            pagination={{ pageSize, total: accountData?.total, showQuickJumper: true, pageSizeOptions: [5, 10, 20, 30, 40, 50] }}
          />
        </div>
      </div>
      <DrawerModal title={'结算单'} visible={visible} setVisible={setVisible}>
        <AccountSheet accountId={accountId} />
      </DrawerModal>
    </div>
  )
}