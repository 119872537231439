import { LinkButton } from './order-notice'
import { LeftCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { List, Avatar } from 'antd';
import './notice.less'
import VirtualList from 'rc-virtual-list';

export const EmailNotice = () => {
  const navigate = useNavigate()
  const listSource = [
    {
      title: '官方通告',
      content: '为迎接新春，衡泰信官方推出降价大酬宾活动，1-3月打8折'
    },
    {
      title: '官方通告',
      content: '为迎接新春，衡泰信官方推出降价大酬宾活动，1-3月打8折'
    },
    {
      title: '官方通告',
      content: '为迎接新春，衡泰信官方推出降价大酬宾活动，1-3月打8折'
    },
    {
      title: '官方通告',
      content: '为迎接新春，衡泰信官方推出降价大酬宾活动，1-3月打8折'
    },
    {
      title: '官方通告',
      content: '为迎接新春，衡泰信官方推出降价大酬宾活动，1-3月打8折'
    },
    {
      title: '官方通告',
      content: '为迎接新春，衡泰信官方推出降价大酬宾活动，1-3月打8折'
    },
    {
      title: '官方通告',
      content: '为迎接新春，衡泰信官方推出降价大酬宾活动，1-3月打8折'
    },
    {
      title: '官方通告',
      content: '为迎接新春，衡泰信官方推出降价大酬宾活动，1-3月打8折'
    },
    {
      title: '官方通告',
      content: '为迎接新春，衡泰信官方推出降价大酬宾活动，1-3月打8折'
    },
    {
      title: '官方通告',
      content: '为迎接新春，衡泰信官方推出降价大酬宾活动，1-3月打8折'
    },
    {
      title: '官方通告',
      content: '为迎接新春，衡泰信官方推出降价大酬宾活动，1-3月打8折'
    },
  ]
  return (
    <div className="email_notice_main">
      <div className='breadcrumb'>
        <LinkButton type='link' icon={<LeftCircleOutlined />} onClick={() => navigate(-1)}>返回</LinkButton>
      </div>
      <div className='page_main'>
        <div className='page_inner'>
          <h4 className="table_list_title">邮件通知</h4>
          <List>
            <VirtualList
              data={listSource}
              height={700}
              itemHeight={79}
              itemKey="email"
            >
              {item => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<div className='flex'>
                      <div className='list_dit unread'></div>
                      <Avatar shape="square" size={48} src="" />
                    </div>}
                    title={<a href="https://ant.design">{item.title}</a>}
                    description={item.content}
                  />
                  <div className='list_content flex'>
                    <div className='list_content_time'>
                      <p>通知时间</p>
                      <p>2016-06-16  14:03</p>
                    </div>
                    <div className='list_content_detial'>
                      详情
                    </div>
                  </div>
                </List.Item>
              )}
            </VirtualList>
          </List>
        </div>
      </div>
    </div>
  )
}