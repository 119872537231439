
import { useAsync } from './../use-async';
import { useHttp } from '../http';
import { useEffect } from 'react';
import { useCallback } from 'react';
import * as Auth from '../../auth-provider'


//查询订单通知
export const useNoticeList = (params) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const token = Auth.getToken()
  const fetchPromise = useCallback(() => client(`golfonlineClub/consumption/queryOrderNoticeList`, {
    data: params
  }), [client, params])
  useEffect(() => {
    if (token) {
      run(fetchPromise(), { retry: fetchPromise })
    }
  }, [client, run, fetchPromise, token])
  return result
}

//删除订单通知
export const useDeleteNotice = () => {
  const { run, ...asyncResult } = useAsync()
  const client = useHttp()
  const deleteNotice = (params) => {
    return run(client(`golfonlineClub/consumption/modifyOrderStatus`, {
      method: 'PUT',
      data: params
    }))
  }
  return {
    deleteNotice,
    ...asyncResult
  }
}

//接受订单通知
export const useEditNotice = () => {
  const { run, ...asyncResult } = useAsync()
  const client = useHttp()
  const editNotice = (params) => {
    return run(client(`golfonlineClub/consumption/modifyOrderStatus`, {
      method: 'PUT',
      data: params
    }))
  }
  return {
    editNotice,
    ...asyncResult
  }
}

//查询订单详情
export const useNoticeOrderDetail = (id) => {
  
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => {
    return client(`golfonlineClub/goods/order/${id}`)
  }, [client, id])
  useEffect(() => {
    if (id) {
      run(fetchPromise(), { retry: fetchPromise })
    }
  }, [client, run, fetchPromise, id])
  return result
}