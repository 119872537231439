
import { Button, Divider, Form, message, Switch, Modal, Input, Space } from 'antd';
import { useEffect, useState } from 'react';
import { Footer } from '../../../../component/drawer-modal';
import { Checkbox } from 'antd';
import { useOrderCards, useOrderSettlement, useCheckOut } from './../../../../utils/manager-admin/order';
import { useNavigate } from 'react-router-dom';
import { PutErrorBox } from '../../../../component/lib';
import { useIsLimit } from './../../../../utils/common';

export const OrderInfoDetail = ({ visible, orderId, setVisible, retry }) => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { data: orderSettlement } = useOrderSettlement(orderId, visible) //查询订单信息
  const { data: orderCards } = useOrderCards(orderId, visible) //查询该订单的卡
  const cardsList = orderCards?.data
  const orderSettlementData = orderSettlement?.data
  const [forGoods, setForGoods] = useState(false) //是否抵扣商品金额
  const [total, setTotal] = useState(0) //最后的金额显示
  const [selectList, setSelectList] = useState([]) //选中的卡片
  const { checkOut, isLoading, error, setError } = useCheckOut() //结账
  const [cardTotal, setCardTotal] = useState(0) //卡片扣除总额
  const [showChangeAmount, setShowChangeAmount] = useState(false)//显示修改框
  const [finalAmount, setFinalAmount] = useState(null) //修改后的金额
  const [modifyTotalAmount, setModifyTotalAmount] = useState(null) //修改后的金额
  const [finalAmountShow, setFinalAmountShow] = useState(false) //显示最终修改的金额
  const modifyAmountLimit = useIsLimit('club:cost:edit') //修改金额权限

  const onChange = (e, card) => {
    const temList = [...selectList]
    const checked = e.target.checked
    if (!checked) {
      const index = temList.findIndex(item => item.id === card.id)
      temList.splice(index, 1)

    } else {
      temList.push(card)
    }

    setSelectList(temList)
    console.log(temList)
  }
  const onFinish = () => {
    const cardIds = selectList.map(item => item.id)
    checkOut({ cardIds, id: orderId, forGoods, modifyTotalAmount }).then(res => {
      if (res.code === 200) {
        message.success(res.msg)
        navigate('/')
      }
    })

  }
  useEffect(() => {
    setError(null)
    const total = orderSettlementData?.totalPrice
    const goodsTotal = orderSettlementData?.storeOriginalPrice
    if (total < 0) {
      setTotal(total)
      return
    }

    const priceList = selectList.map(item => item.cardBalance)
    const totalPrice = priceList.reduce((previousValue, currentValue) => (previousValue + currentValue), 0) //卡券总额

    if (forGoods) {
      if (total - totalPrice > 0) {
        setTotal(Number((total - totalPrice)?.toFixed(2)))
        setCardTotal(Number(totalPrice?.toFixed(2)))
      } else {
        setCardTotal(Number(total?.toFixed(2)))
        setTotal(0)
      }
    } else {
      if ((total - goodsTotal) - totalPrice > 0) {
        setTotal(Number((total - totalPrice)?.toFixed(2)))
        setCardTotal(Number(totalPrice?.toFixed(2)))
        
      } else {
        setCardTotal(Number((total - goodsTotal)?.toFixed(2)))
        setTotal(Number(goodsTotal?.toFixed(2)))
      }
    }
    
  }, [visible, form, orderSettlementData, selectList, setError, forGoods])
  useEffect(()=>{
    setFinalAmount(total)
  },[total])

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout='horizontal'
      labelCol={{
        span: 4,
      }}>
      <PutErrorBox error={error} setError={setError} />
      <div className='check_out_main flex-center'>
        <div className='check_out_inner'>
          <ul className='first'>
            <li>
              <p>打球原价</p>
              <p>{orderSettlementData?.originalTotalPrice || 0}</p>
            </li>
            <li>
              <p>赠送时长折扣</p>
              <p>{orderSettlementData?.giftTotalPrice || 0}</p>
            </li>
            <li>
              <p>身份折扣</p>
              <p>{orderSettlementData?.discountTotalPrice || 0}</p>
            </li>
            <li>
              <p>预约押金</p>
              <p>{orderSettlementData?.appointmentCapital || 0}</p>
            </li>
          </ul>
          <Divider />
          <ul className='second'>
            <li>
              <p>商店原价</p>
              <p>{orderSettlementData?.storeOriginalPrice || 0}</p>
            </li>
            <li>
              <p>退单</p>
              <p>{orderSettlementData?.chargeback}</p>
            </li>
          </ul>
          <Divider />
          {
            cardsList?.length > 0 ? (<>
              <ul className=''>
                <li>
                  <p>是否结算商品金额</p>
                  <p>
                    <Switch checked={forGoods} onChange={setForGoods} />
                  </p>
                </li>
                {
                  cardsList?.map((item, index) => <li key={index}>
                    <p className='flex'>
                      <span>{item.cardName}({item.cardBalance})</span>
                    </p>
                    <p className='flex'>
                      <Checkbox disabled={orderSettlementData?.totalPrice < 0} onChange={(e) => onChange(e, item)}></Checkbox>
                    </p>
                  </li>)
                }

                <li>
                  <p>抵扣总金额</p>
                  <p>{cardTotal ? `-${cardTotal}` : 0}</p>
                </li>
              </ul>
              <Divider />
            </>) : null
          }
          <ul className='modify_amount_box'>
            <div className='flex'>
              <p className='mr-30'>最终消费:<span style={{ fontSize: '18px', color: ' rgba(0, 0, 0, 0.85)', marginLeft: '10px' }}>{total?.toFixed(2)}</span></p>
              <p>
                {
                  modifyAmountLimit ? null :<Button type='primary' onClick={() => {
                    setShowChangeAmount(true)
                  }}>修改金额</Button>
                }
               
              </p>
            </div>
            {
              showChangeAmount ? <div className='change_amount mt-20'>
                <Input type='number' onChange={(e) => {
                  // console.log(e.target.value)
                  setFinalAmount(e.target.value)
                }} value={finalAmount} placeholder="请输入金额数" />
                <div className='flex-center mt-20'>
                  <Space>
                    <Button onClick={() => {
                      setShowChangeAmount(false)
                    }}>取消</Button>
                    <Button type='primary' onClick={() => {
                      setModifyTotalAmount(finalAmount)
                      setShowChangeAmount(false)
                      setFinalAmountShow(true)
                    }}>确定</Button>
                  </Space>
                </div>
              </div> : (finalAmountShow && modifyTotalAmount ? <div className='mb-10'>
                <p>修改后最终消费:<span style={{ fontSize: '18px', color: ' rgba(0, 0, 0, 0.85)', marginLeft: '10px' }}>{modifyTotalAmount}</span></p>
                <p></p>
              </div> : null)
            }
          </ul>

        </div>
      </div>
      <Footer btnText="取消" isLoading={isLoading} onCancel={() => setVisible(false)} />
    </Form >
  )
}