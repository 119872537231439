import { AuthenticatedApp } from './authenticated-app';
import { UnauthenticatedApp } from './unauthenticated-app'
import { ConfigProvider } from 'antd'
import zh_CN from 'antd/lib/locale/zh_CN';
import './App.less';
import { useAuth } from './context/auth-context';

function App() {
  const { user } = useAuth()
  // console.log(user)
  return (
    <ConfigProvider locale={zh_CN}>
      <div className="App">
        {user?.access_token ? <AuthenticatedApp /> : <UnauthenticatedApp />}
      </div>
    </ConfigProvider>
  );
}

export default App;
