import React, { useState, useReducer } from 'react';
import { BreadComponent } from '../../component/breadcrumb';
import './style/point.less'
import { Button, Table, Typography, Divider, Popconfirm, message, Form, Input, Space, DatePicker } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { DrawerModal } from './../../component/drawer-modal';
import { PointEdit, NoticeEdit } from './point-edit-modal';
import { useClubsAppointmentList } from '../../utils/manager-admin/point-list';
import { useCancelAppointment } from './../../utils/manager-admin/point-list';
import { onTableChange } from '../../utils/common';
import { PrivilegedButton } from './../../component/privileged-button';
import { IdSelect } from './../../component/id-select';
import { usePlaceNames } from './../../utils/miniproject-admin/records';
import { LimitIcon, RefreshIcon } from './../../component/custom-icon';
const { RangePicker } = DatePicker;

export const PointList = () => {
  const [form] = Form.useForm()
  const [id, setId] = useState(0)
  const [state, dispatch] = useReducer((state, action) => ({ ...state, ...action }), {
    page: 1,
    pageSize: 10,
    startTime: '',
    endTime: '',
    placeId: '',
    telephone: '',
    appointmentPerson: ''
  })
  //查询预约列表
  const { data, isLoading, retry } = useClubsAppointmentList(state)
  const appointmentList = data?.rows

  const breadcrumbNameMap = [
    {
      name: '打位管理',
      url: '/'
    },
    {
      name: '预约列表',
      url: ''
    },
  ]
  const { cancelAppointment } = useCancelAppointment() //取消预约
  const columns = [
    {
      title: '订单编号',
      dataIndex: 'orderNo',
      sorter: (a, b) => a.orderNo.localeCompare(b.orderNo),
    },
    {
      title: '消费时间',
      dataIndex: 'createTime',
      sorter: (a, b) => new Date(a.createTime).getTime() - new Date(b.createTime).getTime(),
    },
    {
      title: '预约人',
      dataIndex: 'appointmentPerson',
      sorter: (a, b) => a.appointmentPerson.localeCompare(b.appointmentPerson),
    },
    {
      title: '电话',
      dataIndex: 'telephone',
      sorter: (a, b) => a.telephone - b.telephone
    },
    {
      title: '预约时间',
      dataIndex: 'appointmentTime',
      sorter: (a, b) => new Date(a.appointmentTime).getTime() - new Date(b.appointmentTime).getTime()
    },
    {
      title: '预约打位',
      sorter: (a, b) => a.placeTypeDict.localeCompare(b.placeTypeDict),
      render: (value,) => {
        return <div>{value?.placeTypeDict ? `【${value?.placeTypeDict?.substr(0, 1)}】` : null}{value?.placeName}</div>
      }
    },
    {
      title: '预约金额',
      dataIndex: 'appointmentCapital',
      sorter: (a, b) => a.appointmentCapital - b.appointmentCapital
    },
    {
      title: '资金状态',
      dataIndex: 'capitalStatusDict',
      sorter: (a, b) => a.capitalStatusDict.localeCompare(b.capitalStatusDict),
    },
    {
      title: '预约渠道',
      dataIndex: 'appointmentChannelDict',
      sorter: (a, b) => a.appointmentChannelDict.localeCompare(b.appointmentChannelDict)
    },
    {
      title: '预约状态',
      dataIndex: 'statusDict',
      sorter: (a, b) => a.statusDict.localeCompare(b.statusDict)
    },
    {
      title: '操作',
      render: (value) => {
        return (
          <div>
            {
              value?.status === 1 ? <><Typography.Link onClick={() => {
                setVisible(true)
                setTitle('预约信息/编辑')
                setId(value?.id)
                setEdit(true)
              }}>编辑</Typography.Link>
                <Divider type="vertical" />
                <Popconfirm
                  placement="bottom"
                  title={'确定取消预约？'}
                  okText="确认"
                  cancelText="取消"
                  onConfirm={() => {
                    cancelAppointment(value.id).then(res => {
                      if (res.code === 200) {
                        message.success(res.msg)
                        retry()
                      }
                    })
                  }}
                >
                  <Typography.Link>取消预约</Typography.Link>
                </Popconfirm>
              </> : null
            }
          </div>
        )
      }
    },
  ];

  //控制抽屉开关
  const [visible, setVisible] = useState(false)
  const [title, setTitle] = useState('预约信息/编辑')
  const [isEdit, setEdit] = useState()
  const { data: placeNames } = usePlaceNames() //查询打位
  const onFinish = (values) => {
    const { time } = values
    let startTime = undefined
    let endTime = undefined
    if (values.time) {
      startTime = new Date(time[0]).getTime()
      endTime = new Date(time[1]).getTime()
    }
    dispatch({
      ...values,
      startTime,
      endTime,
      placeId: values.placeId,
      time: ''
    })
  }

  return (
    <div className='point_list_contianer grid_display'>
      <div className='breadcrumb'>
        <BreadComponent breadcrumbNameMap={breadcrumbNameMap} />
      </div>
      <div className='page_main'>
        <div className='search_box'>
          <Form className='flex-btw' form={form} layout='inline' autoComplete="off" onFinish={onFinish}>
            <Space size={20}>
              <Form.Item name="time" label="预约时间:">
                <RangePicker />
              </Form.Item>
              <Form.Item label="预约人" name="appointmentPerson">
                <Input placeholder="输入预约人" allowClear />
              </Form.Item>
              <Form.Item label="电话" name="telephone">
                <Input placeholder="输入电话" allowClear />
              </Form.Item>
              <Form.Item name="placeId" label="打位管理:">
                <IdSelect
                  // defaultOptionName="全部"
                  style={{ width: 200 }}
                  options={placeNames?.rows}
                />
              </Form.Item>
            </Space>
            <Space size={15}>
              <Button htmlType='reset'>重置</Button>
              <Button type='primary' htmlType='submit'>搜索</Button>
            </Space>
          </Form>
        </div>
        <div className='page_inner'>
          <div className='flex-btw table_hd'>
            <h5>预约列表</h5>
            <div className='flex-btw'>
              <PrivilegedButton code="club:appointmentNotice:template">
                <div className='flex-center setting' onClick={() => {
                  setTitle('通知设置')
                  setVisible(true)
                }}>
                  <LimitIcon />
                  <span>通知设置</span>
                </div>
              </PrivilegedButton>
              <div className='flex-center setting refresh' onClick={() => retry()}>
                <RefreshIcon />
                <span>刷新</span>
              </div>
              <Button type='primary' icon={<PlusOutlined />} onClick={() => {
                setTitle('手动预约')
                setVisible(true)
                setEdit(false)
              }}>手动添加</Button>
            </div>
          </div>
          <Table
            loading={isLoading}
            columns={columns}
            dataSource={appointmentList}
            rowKey={record => record.id}
            onChange={(pagination) => onTableChange(pagination, dispatch)}
            pagination={{ pageSize: state.pageSize, total: data?.total, showQuickJumper: true, pageSizeOptions: [5, 10, 20, 30, 40, 50] }}
          />
        </div>
      </div>
      <DrawerModal title={title} visible={visible} setVisible={setVisible}>
        {
          title === '通知设置' ?
            <NoticeEdit
              visible={visible}
              setVisible={setVisible} /> :
            <PointEdit
              visible={visible}
              setVisible={setVisible}
              id={id}
              isEdit={isEdit}
              retry={retry}
            />
        }
      </DrawerModal>
    </div>
  )
}

