
import { BreadComponent } from './../../component/breadcrumb';
import { RightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { DefaultTable, TypeTable, ProjectTable, UserTable } from './component/cost-setting-table';

export const CostSetting = () => {
  const [type, setType] = useState('default')
  const breadcrumbNameMap = [
    {
      name: '打球管理',
      url: '/'
    },
    {
      name: '费用设置',
      url: ''
    }
  ]

  return (
    <div className="cost_setting_mian grid_display">
      <div className='breadcrumb'>
        <BreadComponent breadcrumbNameMap={breadcrumbNameMap} />
      </div>
      <div className='page_main'>
        <div className='page_inner'>
          <div className='table_item'>
            <h4 className='table_title'>新类型默认收费<span>（系统新增类型时，默认使用以下价格，请管理员及时修改价格及模式）</span></h4>
            <DefaultTable />
          </div>
          <div className='table_item'>
            <h4 className='table_title title-icon'>按类型</h4>
            <TypeTable setType={setType} type={type} />
          </div>
          <div className='table_item'>
            <div className='flex-btw'>
              <h4 className='table_title title-icon'>按球场</h4>
              <Link to={'project'}>
                <div className='all flex-center'><span style={{ marginRight: '0.5rem' }}>查看全部</span><RightOutlined /></div>
              </Link>
            </div>
            <ProjectTable pageSize={5} setType={setType} size="small" />
          </div>
          <div className='table_item'>
            <h4 className='table_title title-icon'>身份折扣<span>（此为默认值，特殊用户以用户管理内的折扣值为准）</span></h4>
            <UserTable />
          </div>
        </div>
      </div>
    </div>
  )
}