import { Form, Input } from "antd"
import { useAuth } from './../../../../context/auth-context';
import { useEffect, useState } from 'react';
import { PutErrorBox } from '../../../../component/lib'
import { Footer } from '../../../../component/drawer-modal'
import { useAddIdentity, useEditIdentity } from './../../../../utils/business-admin/index';

export const IdentityEdit = ({ isEdit, identity, setVisible, retry, visible }) => {
  const [form] = Form.useForm()
  const { user } = useAuth()
  const merchantId = user.user.merchantId
  const { addIdentity, isLoading: isEditLoading } = useAddIdentity()//添加身份管理
  const { editIdentity, isLoading: isAddLoading } = useEditIdentity() //编辑身份管理
  const [error, setError] = useState()

  const onFinish = (value) => {
    if (isEdit) {
      editIdentity({
        ...value,
        merchantId
      }).then(res => {
        if (res.code === 200) {
          setVisible(false)
          retry()
        } else {
          setError(res)
        }
      })
    } else {
      addIdentity({
        ...value,
        merchantId
      }).then(res => {
        if (res.code === 200) {
          setVisible(false)
          retry()
        } else {
          setError(res)
        }
      })
    }
  }

  useEffect(() => {
    if (isEdit) {
      form.setFieldsValue(identity)
    } else {
      form.resetFields()
    }
    setError(null)
  }, [form, isEdit, identity, visible])

  return <div>
    <PutErrorBox error={error} setError={setError} />
    <Form
      onFinish={onFinish}
      form={form}
      layout='horizontal'
      autoComplete="off"
      labelCol={{
        span: 3,
      }}>
      {
        isEdit ?
          <Form.Item label="身份ID" name='id'>
            <Input placeholder="请输入身份ID" disabled />
          </Form.Item> : null
      }
      <Form.Item label="身份名" name='identityName' rules={[{ required: true, message: '请输入身份名' }]}>
        <Input placeholder="请输入身份名" allowClear />
      </Form.Item>
      <Form.Item label="身份折扣" name='discount' rules={[() => (
        {
          validator(_, value) {
            if (!value || value < 0 || value > 10) {
              return Promise.reject(new Error('请输入0-10的值'));
            } else {
              return Promise.resolve()
            }
          }
        }
      )]}>
        <Input placeholder="请输入身份折扣" type={'number'} allowClear />
      </Form.Item>
      <Footer isLoading={isEditLoading || isAddLoading} />
    </Form>
  </div>
}