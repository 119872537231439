import { Form, DatePicker, Input } from "antd"
import { Footer } from "../../component/drawer-modal";
import { useClubAppointmentOne, usePlaceName, useEditAppointment, useAddAppointment, useNotices } from '../../utils/manager-admin/point-list';
import { IdSelect } from './../../component/id-select';
import { useSelect } from './../../utils/use-select';
import { useEffect, useState } from 'react';
import moment from "moment";
import { disabledDate } from './../../utils/common';
import { clearObject } from './../../utils/index';
import { useEditNotices } from './../../utils/manager-admin/point-list';
import { PutErrorBox } from '../../component/lib'


//预约列表编辑
export const PointEdit = ({ isEdit, id, visible, retry, setVisible }) => {
  const [form] = Form.useForm()
  const { data: appointmentStatusOptions } = useSelect('appointment_status')//查询预约状态下拉框
  const { data: clubPlaces } = useSelect('club_place_type')  //查询打位类型
  const [placeType, setPlaceType] = useState() //打位类型更改
  const { data: placeNames } = usePlaceName(placeType) //查询打位名称
  const { data: appointments } = useClubAppointmentOne(id)//查询预约信息
  const appointmentData = appointments?.data
  const { editAppointment, isLoading: isEditLoading } = useEditAppointment()//编辑预约
  const { addAppointment, isLoading: isAddLoading } = useAddAppointment()  //添加预约
  const [error, setError] = useState()

  const onFinish = (values) => {
    const startTime = new Date(values.startTime).getTime()
    const endTime = new Date(values.endTime).getTime()
    const params = {
      ...values,
      startTime,
      endTime
    }
    if (isEdit) {
      editAppointment({ id, ...params }).then(res => {
        if (res.code === 200) {
          setVisible(false)
          retry()
        } else {
          setError(res)
        }
      })
    } else {
      addAppointment(params).then(res => {
        if (res.code === 200) {
          setVisible(false)
          retry()
        } else {
          setError(res)
        }
      })
    }
  }

  useEffect(() => {
    const startTime = isEdit ? moment(new Date(appointmentData?.startTime).getTime()) : moment(new Date().getTime())
    const endTime = moment(new Date(appointmentData?.endTime).getTime())
    const params = {
      ...appointmentData,
      startTime,
      endTime
    }
    if (isEdit) {
      form.setFieldsValue(params)
      setPlaceType(appointmentData?.placeType)
    } else {
      form.resetFields()
      form.setFieldsValue({ startTime })
    }
    setError(null)
  }, [isEdit, visible, form, appointmentData])

  return (
    <div className="point_edit_main">
      <PutErrorBox error={error} setError={setError} />
      <Form
        form={form}
        layout='horizontal'
        autoComplete="off"
        onFinish={onFinish}
        labelCol={{
          span: 4,
        }}>
        {
          !isEdit ? <>
            <Form.Item label="预约人" name='appointmentPerson' rules={[{ required: true, message: '请输入姓名' }]}>
              <Input placeholder="请输入姓名" allowClear />
            </Form.Item>
            <Form.Item label="手机号码" name='telephone'>
              <Input maxLength={11} placeholder="请输入手机号码" allowClear />
            </Form.Item></> : null
        }
        {
          isEdit ? <>
            <Form.Item name="status" label="预约类型">
              <IdSelect disabled={true} options={appointmentStatusOptions?.data || []} />
            </Form.Item>
          </> : <></>
        }
        <Form.Item name="placeType" label="打位类型" rules={[{ required: true, message: '请选择预约类型' }]}>
          <IdSelect options={clubPlaces?.data || []} onChange={(value) => {
            setPlaceType(value)
            form.setFieldsValue({
              placeId: ''
            })
          }} />
        </Form.Item>
        <Form.Item name="placeId" label="打位名称" rules={[{ required: true, message: '请选择打位名称' }]}>
          <IdSelect options={placeNames?.rows || []} />
        </Form.Item>
        <Form.Item name="startTime" label="开始时间" rules={[{ required: true, message: '请选择开始时间' }]}>
          <DatePicker
            style={{ width: '100%' }}
            howNow={false}
            disabledDate={disabledDate}
            showTime={{ format: "HH:mm" }}
            format="YYYY-MM-DD HH:mm" />
        </Form.Item>
        <Form.Item name="endTime" label="结束时间" rules={[{ required: true, message: '请选择结束时间' }]}>
          <DatePicker
            style={{ width: '100%' }}
            disabledDate={disabledDate}
            showTime={{ format: "HH:mm" }}
            format="YYYY-MM-DD HH:mm" />
        </Form.Item>
        {
          !isEdit ? <>
            <Form.Item label="预约金额" name='appointmentCapital' rules={[() => (
              {
                validator(_, value) {
                  if (value < 0) {
                    return Promise.reject(new Error('请输入正确金额'));
                  } else {
                    return Promise.resolve()
                  }
                }
              }
            )]}>
              <Input placeholder="请填写金额" type={'number'} allowClear />
            </Form.Item>
          </> : null
        }
        <Footer isLoading={isEditLoading || isAddLoading} />
      </Form>
    </div>
  )
}


//预约列表通知设置
export const NoticeEdit = ({ visible, setVisible }) => {
  const [form] = Form.useForm()
  const [state, setSate] = useState({
    appointmentReceive: '',
    appointmentCancel: ''
  })
  const { data: noticesData, error: noticesError, setError: setNoticesError, retry } = useNotices(visible) //查询信息模板
  const notices = noticesData?.rows[0]
  const appointmentReceive = notices?.appointmentReceive
  const appointmentCancel = notices?.appointmentCancel
  const telephoneList = notices?.telephoneList
  const [temList, setTemList] = useState([]) //电话号码临时数组
  // const [changePhone, setChangePhone] = useState([])
  const { editNotices, error, setError, isLoading } = useEditNotices()

  //提交通知设置表单
  const onFinish = (values) => {
    const telephoneList = []
    const newValues = clearObject(values)
    const valuesKeys = Object.keys(newValues)
    valuesKeys.forEach(item => {
      telephoneList.push(newValues[item])
    })
    const id = notices?.id
    editNotices({ telephoneList, id }).then(res => {
      if (res.code === 200) {
        setVisible(false)
        retry()
      } else {
        setError(res)
      }
    })
  }
  //表单变化
  // const onFieldsChange = (changedFields, allFields) => {
  //   console.log(allFields)
  //   const obj = {}
  //   allFields.forEach(item => {
  //     const key = item.name[0]
  //     obj[key] = item.value
  //   })
  //   setChangePhone(obj)
  // }

  useEffect(() => {
    setSate({
      appointmentReceive,
      appointmentCancel
    })

    //表单数据初始
    const temObj = {}
    const temList = []
    if (telephoneList?.length) {
      telephoneList?.forEach((item, index) => {
        temObj['telephone' + index] = item
        temList.push(index)
      })
    } else {
      temObj['telephone0'] = ''
      temList.push(0)
    }
    setTemList(temList)
    form.setFieldsValue(temObj)
    setError(null)
  }, [appointmentReceive, appointmentCancel, telephoneList, form, visible, setError])

  return (
    <div className="notice_edit_main">
      <PutErrorBox error={error || noticesError} setError={setNoticesError || setError} />
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        // onFieldsChange={onFieldsChange}
        autoComplete="off"
      >
        <Form.Item label="信息模板/收到预约时">
          <Input.TextArea value={state?.appointmentReceive} disabled />
        </Form.Item>
        <Form.Item label="信息模板/取消预约时">
          <Input.TextArea value={state?.appointmentCancel} disabled />
        </Form.Item>
        {
          temList?.map((item, index) => <div className="flex">
            <Form.Item
              label={index === 0 ? '接收号码' : null}
              style={{ flex: 1 }}
              name={'telephone' + item}
              rules={[
                () => (
                  {
                    validator(_, value) {
                      if (value?.match(/^1(3|4|5|6|7|8|9)\d{9}$/)) {
                        return Promise.resolve()
                      } else {
                        return Promise.reject(new Error('请输入正确手机号码'))
                      }
                    }
                  }
                )]}
            >
              <Input placeholder="请输入手机号码" allowClear />
            </Form.Item>
            <Form.Item label={index === 0 ? ' ' : null}>
              <img
                className="reduce_img"
                src={index === 0 ? require('../../assets/imgs/manager/increase.png') : require('../../assets/imgs/manager/reduce.png')}
                onClick={() => {
                  if (index === 0) {
                    const index = temList.length ? temList[temList.length - 1] : 0
                    const list = [...temList]
                    list.push(index + 1)
                    console.log(list)
                    setTemList(list)
                  } else {
                    const list = [...temList]
                    list.splice(index, 1)
                    console.log(list)
                    setTemList(list)
                  }
                }}
                alt=""
              />
            </Form.Item>
          </div>)
        }
        <Footer isLoading={isLoading} />
      </Form>
    </div>
  )
}