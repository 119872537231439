

import { Button, Drawer, Space } from 'antd';
import styled from '@emotion/styled';

export const DrawerModal = ({ title, visible, setVisible, children, width, unsettled }) => {
  const onClose = () => {
    setVisible(false)
  }
  return (
    <Drawer
      forceRender={true}
      bodyStyle={{ backgroundColor: unsettled ? '#F7F7F7' : '' }}
      title={title}
      placement={'right'}
      width={width ? width : '35%'}
      closable={false}
      visible={visible}
      onClose={onClose}
      extra={<CloseButton onClick={onClose}>
        <img src={require('../assets/imgs/manager/close.png')} alt="" />
      </CloseButton>}
    >
      {children}
    </Drawer>
  )
}

export const Footer = ({ btnText, confirm, isLoading, onFinish, onCancel }) => {
  return (
    <div className='ant-drawer-footer'>
      <Space>
        <Button
          type='primary'
          htmlType='submit'
          loading={isLoading}
          onClick={onFinish}
        >{confirm ? confirm : '确定'}</Button>
        <Button
          htmlType='reset'
          onClick={onCancel}
        >{btnText ? btnText : '重置'}</Button>
      </Space>
    </div>
  )
}

const CloseButton = styled.div`
width:3.2rem;
height:3.2rem;
cursor: pointer;
display:flex;
justify-content: center;
align-items: center;
>*{
  width:1.6rem;
  height:1.6rem;
}
`