
import { Typography, Button, message } from 'antd';
import { BreadComponent } from './../../component/breadcrumb';
import { GetErrorBox, PutErrorBox } from './../../component/lib';
import { Form } from 'antd';
import { Switch } from 'antd';
import { IdSelect } from './../../component/id-select';
import { useSelect } from './../../utils/use-select';
import { useEditLockScreen, useLockScreen } from './../../utils/manager-admin/broadcast-setting';
import { useEffect } from 'react';

export const LockScreenSetting = () => {
  const breadcrumbNameMap = [
    {
      name: '打球管理',
      url: '/'
    },
    {
      name: '锁屏设置',
      url: ''
    }
  ]
  const [form] = Form.useForm()
  const { data: shutdownTimeData } = useSelect('shutdown_time') //多久关机时长列表
  const { data: lockScreenData, error,retry } = useLockScreen() //查新锁屏初始化信息
  const { editLockScreen,error:editError,setError:setEditError,isLoading } = useEditLockScreen() //锁屏设置
  // console.log(lockScreenData)

  useEffect(() => {
    form.setFieldsValue({ ...lockScreenData?.data })
  }, [lockScreenData?.data, form])

  const onFinish = (values) => {
    console.log(values)
    editLockScreen(values).then(res=>{
      if(res.code === 200){
        message.success(res.msg)
        retry()
      }
    })
  }
  return <div className="lock_screen_container grid_display">
    <div className='breadcrumb'>
      <BreadComponent breadcrumbNameMap={breadcrumbNameMap} />
    </div>
    <div className='page_main'>
      <div className='page_inner'>
        <GetErrorBox error={error} />
        <PutErrorBox error={editError} setError={setEditError} />
        <Typography.Title level={5}>锁屏设置</Typography.Title>
        <Form className='lock_screen_form' labelCol={{
          span: 3,
        }} labelAlign="left" form={form} onFinish={onFinish} autoComplete="off">
          <div>
            <Form.Item name={'powerUpLock'} label="开机锁屏" valuePropName="checked">
              <Switch size='default' />
            </Form.Item>
            <Form.Item name={'shutdownLock'} label="订单结束锁屏" valuePropName="checked">
              <Switch size='default' />
            </Form.Item>
            <Form.Item name={'shutdownTime'} label="订单结束锁屏后多久关机">
              <IdSelect style={{ width: 150 }} options={shutdownTimeData?.data} />
            </Form.Item>
          </div>
          <div className='flex-center lock_screen_btn'>
            <Button isLoading={isLoading} type='primary' htmlType='submit'>保存</Button>
          </div>
        </Form>
      </div>
    </div>
  </div>
}