import { Button, Table, Alert, Typography, Divider, message } from "antd"
import { LeftCircleOutlined } from '@ant-design/icons'
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { onTableChange, showDeleteConfirm } from './../../utils/common';
import { useState } from 'react';
import { DrawerModal } from './../../component/drawer-modal';
import { OrderDetail } from './component/order-detail';
import { useDeleteNotice } from '../../utils/index/order-notice'
import { GetErrorBox, PutErrorBox } from "../../component/lib";
import { useOrder } from "../../context/order-context";


export const OrderNotice = () => {
  const navigate = useNavigate()
  const { noticeList, noticeRetry: retry, noticeIsLoading: isLoading, dispatch, noticeError } = useOrder() //改变订单通知的状态
  const [visible, setVisible] = useState(false)
  const { deleteNotice, error, setError } = useDeleteNotice() //删除通知
  const [selectedRowKeys, setSelectedRowKeys] = useState([]) //批量删除
  const [orderData, setOrderData] = useState() //单个订单信息

  const columns = [
    {
      title: '打位',
      dataIndex: 'placeName',
      render: (value) => {
        return (
          <div className="flex">
            <span className="circle red"></span>
            {value}
          </div>
        )
      }
    },
    {
      title: '订单内容',
      dataIndex: 'orderContent',
    },
    {
      title: '下单人',
      dataIndex: 'buyer',
    },
    {
      title: '操作',
      render: (value) => {
        return (
          <div>
            <Typography.Link
              onClick={() => {
                showDeleteConfirm().then(res => {
                  if (res.code === 'ok') {
                    deleteNotice({ status: 1, orderIds: value.orderId }).then(res => {
                      if (res.code === 200) {
                        message.success(res.msg)
                        retry()
                      }
                    })
                  }
                })
              }}>删除</Typography.Link>
            <Divider type="vertical" />
            <Typography.Link
              onClick={() => {
                setVisible(true)
                setOrderData(value)
              }}>接受订单</Typography.Link>
          </div>
        )
      }
    },
  ]

  return (
    <div className="order_notice_main grid_display">
      <div className='breadcrumb'>
        <LinkButton type='link' icon={<LeftCircleOutlined />} onClick={() => navigate(-1)}>返回</LinkButton>
      </div>
      <div className='page_main'>
        <div className='page_inner'>
          <h4 className="table_list_title">订单通知</h4>
          <PutErrorBox error={error} setError={setError} />
          <GetErrorBox error={noticeError} />
          {
            selectedRowKeys.length ? <div style={{ marginBottom: '2rem' }}>
              <Alert
                action={
                  <div
                    onClick={() => {
                      const orderIds = selectedRowKeys.join(',')
                      showDeleteConfirm().then(res => {
                        if (res.code === 'ok') {
                          deleteNotice({ status: 1, orderIds }).then(res => {
                            if (res.code === 200) {
                              message.success(res.msg)
                              retry()
                            }
                          })
                        }
                      })
                    }}
                    style={{
                      color: '#0052D9', cursor: 'pointer'
                    }}>删除</div>
                }
                message={<div>已选择 <span style={{ color: '#0052D9' }}>{selectedRowKeys?.length}</span> 项</div>}
                type="info"
                showIcon />
            </div> : null
          }

          <Table
            loading={isLoading}
            rowSelection={{
              type: 'checkbox',
              onChange: (values) => {
                setSelectedRowKeys(values)
              }
            }}
            columns={columns}
            dataSource={noticeList}
            rowKey={record => record.orderId}
            onChange={(pagination) => onTableChange(pagination, dispatch)}
          />
        </div>
      </div>
      <DrawerModal
        title={'订单详情'}
        forceRender={true}
        visible={visible}
        setVisible={setVisible}
        confirm={'接受'}
        btnText={'取消'}>
        <OrderDetail orderData={orderData} visible={visible} setVisible={setVisible} retry={retry} />
      </DrawerModal>
    </div>
  )
}

export const LinkButton = styled(Button)`
font-size:1.4rem;
color: rgba(0, 0, 0, 0.65);
padding:0;
`