import { Routes, Route, Navigate } from 'react-router'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { Menu, Dropdown, Button, Badge, Space } from 'antd';
import { AdminIndex } from './screen/index'
import './App.less'
import { useAuth } from './context/auth-context';
import { useRef, useEffect, useState } from 'react';
import { handleUserResponse } from './auth-provider';
import { useIsLimit, useMerchantId } from './utils/common';
import { useOrder } from './context/order-context';
// import { useNoticeList } from './utils/index/order-notice';
import { useChangeStadium, useStadiumList, useNewOrderNum } from './utils/index/stadium-switching';
import { useAsync } from './utils/use-async';
import { useHttp } from './utils/http';
import { message } from 'antd';
import { NoticeIcon } from './component/custom-icon';


export const AuthenticatedApp = () => {
  const { user, logout } = useAuth()
  const client = useHttp()
  const { run } = useAsync()
  const timer = useRef() //获取订单通知定时器
  const audio = useRef() //音频
  const { dot, setDot, noticeRetry } = useOrder() //订单通知
  const merchantId = useMerchantId()
  const { data: orderNum } = useNewOrderNum() //查询新订单
  const [open, setOpen] = useState(false) //球馆选择按钮
  const stadiumLimit = useIsLimit('*:*:*') //球馆切换权限
  const { data: stadiumData } = useStadiumList() //获取所有球馆列表
  const stdiumList = stadiumData?.rows
  const [subTitle, setSubTitle] = useState() //当前球馆的名称
  const { changeStadium } = useChangeStadium() //改变球馆

  useEffect(() => {
    timer.current && clearInterval(timer.current)
    timer.current = setInterval(() => {
      run(client(`golfonlineClub/consumption/newOrder`)).then(res => {
        if (res.code === 200) {
          const data = res?.data
          if (data?.new) {
            audio.current.play()
          }
          setDot(data?.count > 0)
        } else {
          message.error('新订单获取失败')
        }
      })
    }, 1000 * 60)
    return () => {
      timer.current && clearInterval(timer.current)
    }
  }, [run, client, dot, setDot])

  useEffect(() => {
    if (orderNum?.data?.new) {
      audio.current.play()
    }
    setDot(orderNum?.data?.count > 0)
  }, [orderNum?.data, setDot])

  //球馆初始化
  useEffect(() => {
    const subTitle = stdiumList?.find(item => Number(item.dictValue) === merchantId)?.dictLabel
    setSubTitle(subTitle)
  }, [merchantId, stdiumList])

  return (
    <Container>
      <Router>
        <PageHeader>
          <div className='logo_box flex-btw'>
            <img src={require('./assets/imgs/header/logo.png')} alt="" />
            <h3>衡泰信会所管理</h3>
          </div>
          <div className='flex header_right'>
            <Space size={24}>
              {
                !stadiumLimit ? <div className='tadium_switching flex'>
                  <div className='stadium_icon'>
                    <img src={require('./assets/imgs/header/stadium_icon.png')} alt="" />
                  </div>
                  <Dropdown
                    overlay={
                      <Menu
                        triggerSubMenuAction="click"
                      >
                        {
                          stdiumList?.map(item => <Menu.Item
                            key={item.dictValue}
                            onClick={() => {
                              changeStadium(item.dictValue).then(res => {
                                if (res.code === 200) {
                                  handleUserResponse(res.data)
                                  window.location.reload()
                                }
                              })
                            }}
                          >{item.dictLabel}</Menu.Item>)
                        }
                      </Menu>
                    }
                    trigger={['click']}
                    onVisibleChange={(visible) => {
                      setOpen(!open)
                    }}
                  >
                    <div>{subTitle}</div>
                  </Dropdown>

                  <div className={open ? 'drop_down active' : 'drop_down'}>
                    <img src={require('./assets/imgs/header/drop_down.png')} alt="" />
                  </div>
                </div> : null
              }
              <Link to={'/home/index/order_notice'}>
                <div className='notice_icon flex-center' onClick={() => {
                  noticeRetry()

                }}>
                  <Badge dot={dot}>
                    <NoticeIcon />
                  </Badge>
                  <audio ref={audio} src={require('./assets/imgs/header/order_tip.mp3')}></audio>
                </div>
              </Link>
              {/* <Link to={'/home/index/email_notice'}>
                <div className='flex-center'>
                  <Badge size='small' count={5}>
                    <img src={require('./assets/imgs/header/email.png')} alt="" />
                  </Badge>
                </div>
              </Link> */}
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item key={'logout'} onClick={logout}><Button type={'link'}>登出</Button></Menu.Item>
                  </Menu>}>
                <div className='avatar'>
                  <img src={require('./assets/imgs/header/avatar.png')} alt="" />
                  <UserButton type={'link'}>{user?.user.username}</UserButton>
                </div>
              </Dropdown>
            </Space>
          </div>
        </PageHeader>
        <Routes>
          <Route path={'/'} element={<Navigate to={'/home/index/manager'} />} />
          <Route path={'/home/index'} element={<Navigate to={'/home/index/manager'} />} />
          <Route path={'/home'} element={<Navigate to={'/home/index/manager'} />} />
          <Route path={'/home/*'} element={<AdminIndex />} />
        </Routes>
      </Router>
    </Container>
  )
}

const PageHeader = styled.div`
color:#203251;
display:flex;
justify-content: space-between;
align-item:center;
border-bottom:1px solid #E7E7E7;
`
const Container = styled.div`
  display:grid;
  grid-template-rows:6rem 1fr;
  min-height:100vh;
`

const UserButton = styled(Button)`
color:#000;
height:6rem;
padding:0;
`