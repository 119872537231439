import { Button, Form, Input, Select, Spin, Typography } from "antd";
import { FormItem, SpaceBox } from '../index'
import { useState, useEffect } from 'react';
import { useMerchantId } from './../../../utils/common';
import { useBasicInfo, useEditBasicInfo } from '../../../utils/stadium-info/basics-info'
import { useSelect } from './../../../utils/use-select';
import { IdSelect } from '../../../component/id-select'
import { GetErrorBox, PutErrorBox } from "../../../component/lib";
import { PrivilegedButton } from './../../../component/privileged-button';

const { Option } = Select;

export const BasicsInfo = () => {
  const [form] = Form.useForm()
  const [isNoEdit, setIsNoEdit] = useState(true)
  const merchantId = useMerchantId()
  const { data: basicInfo, retry, error, isLoading } = useBasicInfo(merchantId) //查询基本信息
  const basicInfoData = basicInfo?.data
  const { data: trainingPopulation } = useSelect('training_population') //查询培训人群
  const { data: outdoor } = useSelect('outdoor_type') //查询户外人群
  const { editBasicInfo, error: editError, setError: setEditError, isLoading: isEditLoading } = useEditBasicInfo() //编辑基础信息

  useEffect(() => {
    form.setFieldsValue(basicInfoData)
  }, [basicInfoData, form])

  //编辑
  const onFinish = (value) => {
    editBasicInfo({ ...value, id: merchantId }).then(res => {
      if (res.code === 200) {
        setIsNoEdit(true)
        retry()
      }
    })
  }
  useEffect(() => {
    setEditError(null)
  }, [isNoEdit, setEditError])
  return (
    <div className="basic_info_main">
      <div className="stadium_header">
        <GetErrorBox error={error} />
        <PutErrorBox error={editError} setError={setEditError} />
        <div className="flex-btw">
          <h4>基础信息</h4>
          <PrivilegedButton code="club:merchant:update">
            <Typography.Link onClick={() => { 
              setIsNoEdit(false)
            }}>编辑</Typography.Link>
          </PrivilegedButton>
        </div>
      </div>
      <Spin spinning={isLoading}>
        <div className="stadium_body">
          <Form
            form={form}
            labelCol={{
              span: 6,
            }}
            onFinish={onFinish}
          >
            <div className="flex">
              <FormItem label="商户名称：">
                <span>{basicInfoData?.merchantName}</span>
              </FormItem>
              <FormItem label="商户类型：">
                <span>{basicInfoData?.merchantTypeDict}</span>
              </FormItem>
              <FormItem label="商户ID：">
                <span>{basicInfoData?.id}</span>
              </FormItem>
            </div>
            <div className="flex">
              <FormItem label="扣费模式：">
                <span>{basicInfoData?.deductionModeDict}</span>
              </FormItem>
              <FormItem label="负责人：">
                <span>{basicInfoData?.merchantDirector}</span>
              </FormItem>
              <FormItem label="联系电话：">
                <span>{basicInfoData?.directorPhone}</span>
              </FormItem>
            </div>
            <div className="flex">
              <FormItem label="邮箱：" name='directorEmail' rules={[{ required: true, message: '请输入邮箱地址' }]}>
                <Input type={'email'} placeholder="请输入邮箱地址" disabled={isNoEdit} allowClear />
              </FormItem>
              <FormItem label="收款人：" name="payee" rules={[{ required: true, message: '请输入收款人' }]}>
                <Input placeholder="请输入收款人" disabled={isNoEdit} allowClear />
              </FormItem>
              <FormItem label="营业执照税号：" name="dutyParagraph" rules={[{ required: true, message: '请输入正确的营业执照税号' }]}>
                <Input placeholder="请输入正确的营业执照税号" disabled={isNoEdit} allowClear />
              </FormItem>
            </div>
            <div className="flex">
              <FormItem label="收款账号：" name="accountNumber" rules={[{ required: true, message: '请输入收款账号' }]}>
                <Input placeholder="请输入收款账号" disabled={isNoEdit} allowClear />
              </FormItem>
              <FormItem label="地址：" name="address" rules={[{ required: true, message: '请输入地址' }]}>
                <Input placeholder="请输入地址" disabled={isNoEdit} allowClear />
              </FormItem>
              <FormItem label="户外场地：" name="outdoor">
                <IdSelect options={outdoor?.data} disabled={isNoEdit} />
              </FormItem>
            </div>
            <div className="flex">
              <FormItem label="对外经营：" name="businessForOpen">
                <Select disabled={isNoEdit}>
                  <Option value={true}>是</Option>
                  <Option value={false}>否</Option>
                </Select>
              </FormItem>
              <FormItem label="展厅：" name="exhibition">
                <Select disabled={isNoEdit}>
                  <Option value={true}>是</Option>
                  <Option value={false}>否</Option>
                </Select>
              </FormItem>
              <FormItem label="纯经营高尔夫：" name="onlyGolf">
                <Select disabled={isNoEdit}>
                  <Option value={true}>是</Option>
                  <Option value={false}>否</Option>
                </Select>
              </FormItem>
            </div>
            <div className="flex">
              <FormItem label="培训人群：" name="trainingPopulation">
                <IdSelect options={trainingPopulation?.data} disabled={isNoEdit} />
              </FormItem>
              <FormItem>
              </FormItem>
              <FormItem>
              </FormItem>
            </div>
            {
              isNoEdit ? null : <SpaceBox>
                <Button type="primary" htmlType="submit" loading={isEditLoading}>保存</Button>
                <Button onClick={() => {
                  setIsNoEdit(true)
                  retry()
                }}>取消</Button>
              </SpaceBox>
            }
          </Form>
        </div>
      </Spin>
    </div>
  )
}