import { useAsync } from './../use-async';
import { useHttp } from '../http';
import { useEffect } from 'react';

//结算数据
export const useAccountData = (params) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  useEffect(() => {
    run(client(`golfonlineClub/settlement/list`, {
      data: {
        ...params
      }
    }))
  }, [client, run, params])
  return result
}

export const useAccountSheet = (id) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  useEffect(() => {
    run(client(`golfonlineClub/settlement/${id}`))
  }, [client, run, id])
  return result
}
