
import { useEffect, useCallback } from "react"
import { useAsync } from './../use-async';
import { useHttp } from './../http';

//查询可转正订单
export const useBecomeRegular = (merchantId) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  useEffect(() => {
    run(client('golfonlineClub/clubPlace/findBecomeRegular', {
      data: {
        merchantId
      }
    }))
  }, [client, run, merchantId])
  return result
}

//查询数据统计
export const useStatistics = (merchantId) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  useEffect(() => {
    run(client('golfonlineClub/clubPlace/findStatistics', {
      data: {
        merchantId
      }
    }))
  }, [client, run, merchantId])
  return result
}

//查询打位列表（普通）
export const useCommonClubPlaceList = (params) => {
  const { generalActive, merchantId } = params
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/clubPlace/list/1`, {
    data: {
      merchantId,
      status: generalActive,
    }
  }), [client, merchantId, generalActive])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}

//查询打位列表（豪华）
export const useLuxuryClubPlaceList = (params) => {
  const { luxuryActive, merchantId } = params
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/clubPlace/list/2`, {
    data: {
      merchantId,
      status: luxuryActive,
    }
  }), [client, merchantId, luxuryActive])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}

// 锁定/解锁打位
export const useChangeClubPlaceStatus = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const changeClubPlace = (params) => {
    return run(client(`golfonlineClub/clubPlace/changeStatus`, {
      method: 'PUT',
      data: params
    }))
  }
  return {
    ...result,
    changeClubPlace
  }
}

//查询可结账订单
export const useCanBeSettledOrder = (placeId) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/clubAppointment/order/settlementList`, {
    data: {
      placeId
    }
  }), [client, placeId])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}

//关机
export const useShutDownPlace = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const shutDownPlace = (id) => {
    return run(client(`golfonlineClub/clubAppointment/shutDown/${id}`, {
      method: 'PUT'
    }))
  }
  return {
    ...result,
    shutDownPlace
  }
}

//查询当前打位
export const useClubPlaceDetail = (placeId) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/clubAppointment/usingTime`, {
    data: {
      placeId
    }
  }), [client, placeId])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}