import { useAsync } from './../use-async';
import { useHttp } from '../http';
import { useEffect } from 'react';
import { useCallback } from 'react';

//查询打位
export const usePlaceNames = (placeType) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  useEffect(() => {
    run(client(`golfonlineClub/clubPlace/placeOptions`,{
      data:{
        placeType
      }
    }))
  }, [placeType, run, client])
  return result
}

//查询二维码
export const useCode = (id) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/multimedia/qrcode/${id}`), [client,id])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}

//生成二维码
export const useAddQrCode = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const addQrCode = (params) => {
    return run(client(`golfonlineClub/multimedia/qrcode`, {
      method: 'POST',
      data: params
    }))
  }
  return {
    ...result,
    addQrCode
  }
}

//下载二维码
export const useDownloadCode = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const downloadQrCode = (params) => {
    return run(client(`golfonlineClub/multimedia/download`, {
      method: 'GET',
      data: params
    }))
  }
  return {
    ...result,
    downloadQrCode
  }
}