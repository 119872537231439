
import { useAsync } from './../use-async';
import { useHttp } from '../http';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { clearObject } from './../index';


//查询所有打位预约列表
export const useClubsAppointmentList = (params) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/clubAppointment/list`, {
    data: {
      ...clearObject(params)
    }
  }), [client, params])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}

//查询单个打位预约列表
export const useClubAppointmentList = (params) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/clubAppointment/list`, {
    data: {
      ...clearObject(params)
    }
  }), [client, params])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}

//查询单条预约信息
export const useClubAppointmentOne = (id) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  useEffect(() => {
    run(client('golfonlineClub/clubAppointment/' + id))
  }, [client, run, id])
  return result
}

//根据打位类型查询打位名称
export const usePlaceName = (placeType, id) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  useEffect(() => {
    run(client('golfonlineClub/clubPlace/placeOptions', {
      data: {
        placeType,
        id
      }
    }))
  }, [client, run, placeType, id])
  return result
}

//编辑预约
export const useEditAppointment = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const editAppointment = (params) => {
    return run(client(`golfonlineClub/clubAppointment`, {
      method: 'PUT',
      data: params
    }))
  }
  return {
    ...result,
    editAppointment,
  }
}

//添加预约
export const useAddAppointment = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const addAppointment = (params) => {
    return run(client(`golfonlineClub/clubAppointment`, {
      method: 'POST',
      data: params
    }))
  }
  return {
    ...result,
    addAppointment,
  }
}

//取消预约
export const useCancelAppointment = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const cancelAppointment = (id) => {
    return run(client(`golfonlineClub/clubAppointment/cancel/${id}`, {
      method: 'PUT'
    }))
  }
  return {
    ...result,
    cancelAppointment
  }
}

//查询通知
export const useNotices = (visible) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/appointmentNoticeTemplate/list`), [client])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise, visible])
  return result
}

//编辑通知设置
export const useEditNotices = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const editNotices = (params) => {
    return run(client(`golfonlineClub/appointmentNoticeTemplate`, {
      method: 'PUT',
      data: params
    }))
  }
  return {
    ...result,
    editNotices
  }
}

//换机
export const useChangePlace = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const changePlace = (params) => {
    return run(client(`golfonlineClub/clubAppointment/changeDevice`, {
      method: 'PUT',
      data: params
    }))
  }
  return {
    ...result,
    changePlace
  }
}