import { Select } from 'antd';

export const IdSelect = (props) => {
  const { options, defaultOptionName, ...restProps } = props
  return (
    <Select
      {...restProps}
    >
      {defaultOptionName ? <Select.Option value={0}>{defaultOptionName}</Select.Option> : null}
      {options?.map(option => <Select.Option key={option.dictValue} value={toNumber(option.dictValue)}>{option.dictLabel}</Select.Option>)}
    </Select>
  )
}

const toNumber = (value) => isNaN(Number(value)) ? value : Number(value)