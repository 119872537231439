import Icon from '@ant-design/icons';
import { ReactComponent as Statistics } from '../assets/imgs/icon/statistics.svg'
import { ReactComponent as Broadcast } from '../assets/imgs/icon/broadcast.svg'
import { ReactComponent as DataCount } from '../assets/imgs/icon/data-count.svg'
import { ReactComponent as Cost } from '../assets/imgs/icon/cost.svg'
import { ReactComponent as AppointIndex } from '../assets/imgs/icon/appoint-index.svg'
import { ReactComponent as StadiumInfo } from '../assets/imgs/icon/stadium-info.svg'
import { ReactComponent as Position } from '../assets/imgs/icon/position.svg'
import { ReactComponent as Business } from '../assets/imgs/icon/business.svg'
import { ReactComponent as Miniproject } from '../assets/imgs/icon/miniproject.svg'
import { ReactComponent as ManagerCount } from '../assets/imgs/icon/manager-count.svg'
import { ReactComponent as HandleOrder } from '../assets/imgs/icon/handle-order.svg'
import { ReactComponent as Unsettled } from '../assets/imgs/icon/unsettled.svg'
import { ReactComponent as UnsettledOn } from '../assets/imgs/icon/unsettled-on.svg'
import { ReactComponent as Lock } from '../assets/imgs/icon/lock.svg'
import { ReactComponent as UnLock } from '../assets/imgs/icon/un-lock.svg'
import { ReactComponent as DataIndex } from '../assets/imgs/icon/data-index.svg'
import { ReactComponent as UnAppointIndex } from '../assets/imgs/icon/un-appoint-index.svg'
import { ReactComponent as ChangePlace } from '../assets/imgs/icon/change-place.svg'
import { ReactComponent as UnChangePlace } from '../assets/imgs/icon/un-change-place.svg'
import { ReactComponent as Refresh } from '../assets/imgs/icon/refresh.svg'
import { ReactComponent as Type } from '../assets/imgs/icon/type.svg'
import { ReactComponent as Identify } from '../assets/imgs/icon/identify.svg'
import { ReactComponent as Limit } from '../assets/imgs/icon/limit.svg'
import { ReactComponent as Notice } from '../assets/imgs/icon/notice.svg'
import { ReactComponent as CreatIcon } from '../assets/imgs/icon/creat-order.svg'
import { ReactComponent as UnCreatIcon } from '../assets/imgs/icon/un-creat-order.svg'



export const StatisticsChartIcon = () => {
  return (
    <Icon component={Statistics} />
  )
}

export const BroadcastIcon = () => {
  return (
    <Icon component={Broadcast} style={{ fontSize: '14px' }} />
  )
}

export const CostIcon = () => {
  return (
    <Icon component={Cost} style={{ fontSize: '14px' }} />
  )
}

//打位管理首页 预约图标
export const AppointIcon = () => {
  return (
    <Icon component={AppointIndex} style={{ fontSize: '14px', marginRight: '7px' }} />
  )
}
export const UnAppointIcon = () => {
  return (
    <Icon component={UnAppointIndex} style={{ fontSize: '14px', marginRight: '7px' }} />
  )
}
//解锁
export const UnLockIcon = () => {
  return (
    <Icon component={UnLock} style={{ fontSize: '14px', marginRight: '7px' }} />
  )
}
//锁定
export const LockIcon = () => {
  return (
    <Icon component={Lock} style={{ fontSize: '14px', marginRight: '7px' }} />
  )
}

//换机
export const ChangePlaceIcon = () => {
  return (
    <Icon component={ChangePlace} style={{ fontSize: '14px', marginRight: '7px' }} />
  )
}
export const UnChangePlaceIcon = () => {
  return (
    <Icon component={UnChangePlace} style={{ fontSize: '14px', marginRight: '7px' }} />
  )
}

//数据
export const DataIcon = () => {
  return (
    <Icon component={DataIndex} style={{ fontSize: '14px', marginRight: '7px' }} />
  )
}
//創建訂單
export const CreateOrderIcon = ({status}) => {
  return (
    <Icon component={status? CreatIcon :UnCreatIcon} style={{ fontSize: '14px', marginRight: '7px' }} />
  )
}

//打位管理的数据统计图标
export const ManagerCountIcon = () => {
  return (
    <Icon component={ManagerCount} style={{ fontSize: '14px' }} />
  )
}

//菜单栏图标
export const PositionIcon = () => {
  return (
    <Icon component={Position} style={{ fontSize: '20px' }} />
  )
}
export const DataCountIcon = () => {
  return (
    <Icon component={DataCount} style={{ fontSize: '20px' }} />
  )
}
export const StadiumInfoIcon = () => {
  return (
    <Icon component={StadiumInfo} style={{ fontSize: '20px' }} />
  )
}
export const BusinessIcon = () => {
  return (
    <Icon component={Business} style={{ fontSize: '20px' }} />
  )
}

export const MiniprojectIcon = () => {
  return (
    <Icon component={Miniproject} style={{ fontSize: '20px' }} />
  )
}

export const HandlerOrder = () => {
  return (
    <Icon component={HandleOrder} style={{ fontSize: '14px' }} />
  )
}

export const UnsettledIcon = ({ length }) => {
  return (
    <Icon component={!length ? UnsettledOn : Unsettled} style={{ fontSize: '16px' }} />
  )
}


//商品信息类别
export const TypeIcon = () => {
  return (
    <Icon component={Type} style={{ fontSize: '16px', marginRight: '4px' }} />
  )
}

//刷新
export const RefreshIcon = () => {
  return (
    <Icon component={Refresh} style={{ fontSize: '16px', marginRight: '4px' }} />
  )
}

//身份管理
export const IdentifyIcon = () => {
  return <Icon component={Identify} style={{ fontSize: '16px', marginRight: '4px' }} />
}

//权限管理
export const LimitIcon = () => {
  return <Icon component={Limit} style={{ fontSize: '16px', marginRight: '4px' }} />
}

//订单通知
export const NoticeIcon = () => {
  return <Icon component={Notice} style={{ fontSize: '24px' }} />
}