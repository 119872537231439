import { useState, useEffect } from "react";
import { Upload, Form, message, Typography } from 'antd';
import { Modal } from 'antd';
import { useAuth } from '../context/auth-context';
import { apiUrl } from '../auth-provider'
import { PlusOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';

export const UploadFile = (
  { type, label, isNoEdit, param, name, fileLists, maxCount, visible, accept, remark, aspect, setFileList, fileList }
) => {
  const { user } = useAuth()
  // const [fileList, setFileList] = useState([])
  const [state, setState] = useState({
    previewVisible: false,
    previewImage: ''
  })
  const { previewVisible, previewImage } = state
  const props = {
    name: 'file',
    action: `${apiUrl}golfonlineClub/merchant/multimedia/${param}`,
    headers: {
      Authorization: user?.access_token ? `Bearer ${user?.access_token}` : '',
    },
  }
  //初始化上传文件框
  useEffect(() => {
    const tempty = []
    // console.log(fileLists)
    fileLists?.forEach(item => {
      const files = { url: '', id: '', status: 'done', }
      files.url = item.multimediaUrl
      files.id = item.id
      tempty.push(files)
    })
    setFileList(tempty)
  }, [visible, fileLists, setFileList])

  const uploadFile = ({ fileList: newFileList, file }) => {
    // console.log(newFileList)
    // setFileList(newFileList)
    if (file?.response) {
      const res = file?.response
      if (res.code !== 200) {
        message.error(res?.msg)
        newFileList[0].status = 'error'
      }
    }
    setFileList(newFileList.filter(item => item.status))
  }

  //预览
  const onPreview = (file) => {
    setState({
      previewImage: file.thumbUrl ? file.thumbUrl : file.url,
      previewVisible: true
    })
  }
  //关闭预览
  const handleCancel = () => {
    setState({
      ...state,
      previewVisible: false
    })
  }

  //上传之前校验文件大小
  const beforeUpload = (file) => {
    if (file.size >= 5 * 1024 * 1024) {
      message.error('文件大小不超过5M')
      return false
    } else {
      return true
    }
  }
  return (
    <div>
      <Form.Item
        style={{ marginBottom: remark ? '0' : '24px' }}
        label={label}
        name={name}
        rules={[() => (
          {
            validator() {
              // console.log(fileList)
              if (!fileList.length && name !== 'picture') {
                return Promise.reject(new Error('请上传图片!'));
              } else {
                return Promise.resolve()
              }
            }
          }
        )]}>
        <ImgCrop
          rotate
          modalWidth={690}
          aspect={aspect}
          grid
        >
          <Upload
            listType="picture-card"
            accept={accept}
            maxCount={maxCount}
            fileList={fileList}
            onChange={uploadFile}
            onPreview={onPreview}
            disabled={isNoEdit}
            beforeUpload={beforeUpload}
            {...props}
          >
            {
              fileList.length >= maxCount ? null : <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>{type ? type : '上传图片'}</div>
              </div>
            }
          </Upload>
        </ImgCrop>
      </Form.Item>
      {
        remark ?
          <div style={{ marginBottom: '24px' }}>
            <Typography.Text type='secondary'>* 注：上传格式为jpg，jpeg，png文件，建议尺寸176*176像素，文件大小不超过5M</Typography.Text>
          </div>
          : null
      }
      <Modal
        visible={previewVisible}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: '95%' }} src={previewImage} />
      </Modal>
    </div>
  )
}