import Green from '../../../assets/imgs/business/green.png'
import Yellow from '../../../assets/imgs/business/yellow.png'
import Red from '../../../assets/imgs/business/red.png'
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { DrawerModal } from '../../../component/drawer-modal';
import { message, Spin } from 'antd';
import { useState, useReducer } from 'react';
import { showDeleteConfirm, useMerchantId } from '../../../utils/common';
import { useCardList, useDeleteCard } from './../../../utils/business-admin/index';
import { CardEdit } from './lib/card-edit';
import { PrivilegedButton } from './../../../component/privileged-button';


export const PlayerBasicCard = ({ memberUserId }) => {
  const [isEdit, setIsEdit] = useState(false)
  const [visible, setVisible] = useState(false)
  const merchantId = useMerchantId()
  const [state,] = useReducer((state, action) => ({ ...state, ...action }), {
    merchantId,
    memberUserId
  })
  const { data: cardListData, retry, isLoading } = useCardList(state) //获取会员卡列表
  const cardList = cardListData?.rows
  const { deleteCard } = useDeleteCard()
  const [cardId, setCardId] = useState()

  return (
    <div className="player_basics_main">
      <div className='stadium_item'>
        <div className="palyer_statistics_main">
          <div className="flex-btw stadium_header">
            <h5>卡包</h5>
          </div>
          <Spin spinning={isLoading}>
            <ul className="cards_list">
              {
                cardList?.map((item, index) => <li key={index}>
                  <div className='card_bg'>
                    <img src={item.cardType === 'green' ? Green : item.cardType === 'red' ? Red : Yellow} alt="" />
                  </div>
                  <div className='card_info'>
                    <div className='flex card_title'>
                      <img src={require('../../../assets/imgs/business/card_icon.png')} alt="" />
                      <span>{item.cardName}</span>
                    </div>
                    <p>余额 {item.cardBalance}</p>
                  </div>
                  <PrivilegedButton code="club:merchantUser:card">
                    <div className='card_setting flex'>
                      <div className='card_edit' onClick={() => {
                        setIsEdit(true)
                        setVisible(true)
                        setCardId(item.id)
                      }}>
                        <EditOutlined />
                      </div>
                      <span className='setting_bold'>|</span>
                      <div className='card_delete' onClick={() => {
                        showDeleteConfirm().then(res => {
                          if (res.code === 'ok') {
                            deleteCard(item.id).then(res => {
                              if (res.code === 200) {
                                message.success(res.msg)
                                retry()
                              }
                            })
                          }
                        })
                      }}>
                        {/* <img src={require('../../../assets/imgs/business/delete.png')} alt="" /> */}
                        <DeleteOutlined />
                      </div>
                    </div>
                  </PrivilegedButton>
                </li>)
              }
              <PrivilegedButton code="club:merchantUser:card">
                <li className='add_card flex-center' onClick={() => {
                  setIsEdit(false)
                  setVisible(true)
                }}>
                  <span className='add_position'>
                    <PlusOutlined />
                    <span>添加卡片</span>
                  </span>
                </li>
              </PrivilegedButton>
            </ul>
          </Spin>
        </div>
      </div>
      <DrawerModal title={isEdit ? '编辑卡片' : '添加卡片'} visible={visible} setVisible={setVisible}>
        <CardEdit
          cardId={cardId}
          retry={retry}
          memberUserId={memberUserId}
          visible={visible}
          setVisible={setVisible}
          isEdit={isEdit} />
      </DrawerModal>
    </div>
  )
}