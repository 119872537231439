import { BreadComponent } from './../../component/breadcrumb';
import { DemoPie, DemoLine } from './charts'
import { usePlaceDuration, useModeDuration, useProfit, useMerchantTime, useProfitTime } from './../../utils/data-count/chart';
import { EmptyBox } from './../../component/lib';

export const StatisticsCharts = () => {
  const breadcrumbNameMap = [
    {
      name: '数据统计',
      url: '/home/data/operate'
    },
    {
      name: '统计图',
      url: ''
    },
  ]
  const { data: placeDurationData } = usePlaceDuration() //打位时长比列
  const placeDurationList = placeDurationData?.rows
  const { data: modeDurationData } = useModeDuration() //内容时长比列
  const modeDurationList = modeDurationData?.rows
  const { data: profitData } = useProfit() //时长-时间趋势图
  const profitList = profitData?.rows
  const { data: timeData } = useMerchantTime() //时长-时间趋势图
  const TimeList = timeData?.rows
  const { data: profitTimeData } = useProfitTime() //收益-时间趋势图
  const profitTimeList = profitTimeData?.rows
  return (
    <div className="statistics_chart_main">
      <div className='breadcrumb'>
        <BreadComponent breadcrumbNameMap={breadcrumbNameMap} />
      </div>
      <div className='page_main'>
        <div className='page_inner'>
          <h4 className="table_list_title">运营数据</h4>
          <div className='charts_box flex-btw'>
            <div className='charts_item'>
              <h4>打位-时长占比图</h4>
              {
                placeDurationList?.length ? <DemoPie
                  title={'打位-时长占比图'}
                  data={placeDurationList}
                /> : <div className='no_charts'>
                  <EmptyBox />
                </div>
              }
            </div>
            <div className='charts_item'>
              <h4>内容-时长占比图</h4>
              {
                modeDurationList?.length ?
                  <DemoPie
                    title={'内容-时长占比图'}
                    data={modeDurationList}
                    color={['#FFC927', '#6088CE', '#F19050', '#AAAAAA']}
                  /> : <div className='no_charts'><EmptyBox /></div>
              }
            </div>
            <div className='charts_item'>
              <h4>内容-收益占比图</h4>
              {
                profitList?.length ? <DemoPie
                  data={profitList}
                  title={'内容-收益占比图'}
                  color={['#ACD0E9', '#485983', '#F2CEA0', '#EC8B74']}
                /> : <div className='no_charts'><EmptyBox /></div>
              }
            </div>
          </div>
          <div className='charts_box flex-btw'>
            <div className='charts_item'>
              <h4 className='margin_bt24'>时长-时间趋势图</h4>
              {
                TimeList?.length ? <DemoLine
                  title={'时长-时间趋势图'}
                  data={TimeList}
                  text={'时长(/时)'}
                /> : <div className='no_charts'><EmptyBox /></div>
              }
            </div>
            <div className='charts_item'>
              <h4 className='margin_bt24'>收益-时间趋势图</h4>
              {
                profitTimeList?.length ? <DemoLine text={'收益(/元)'} title={'收益-时间趋势图'} data={profitTimeList} /> : null
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}