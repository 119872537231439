
import { BreadComponent } from './../../component/breadcrumb';
import { Table } from 'antd'
import { useClubsDataList } from './../../utils/manager-admin/data-list';
import { useReducer } from 'react';
import { onTableChange, useRouteId } from '../../utils/common';
import { GetErrorBox } from '../../component/lib';
import { ExpandedRow } from './../../component/table-inner';
import { useEffect } from 'react';
import { formatTime } from './component/club-place-status';

// const { RangePicker } = DatePicker;

export const DataList = () => {
  // const [form] = Form.useForm()
  const breadcrumbNameMap = [
    {
      name: '打位管理',
      url: '/'
    },
    {
      name: '数据',
      url: ''
    },
  ]
  const placeId = useRouteId()
  const [state, dispatch] = useReducer((state, action) => ({ ...state, ...action }), {
    page: 1,
    pageSize: 10,
    placeId,
    startTime:'',
    endTime:''
  })
  const { data, isLoading, error } = useClubsDataList(state)
  const columns = [
    {
      title: '开始时间',
      dataIndex: 'startTime',
    },
    {
      title: '结束时间',
      dataIndex: 'endTime',
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 3,
      },
    },
    {
      title: '昵称',
      dataIndex: 'playerName',
      sorter: {
        compare: (a, b) => a.math - b.math,
        multiple: 2,
      },
    },
    {
      title: '项目名称',
      dataIndex: 'itemName',
      sorter: {
        compare: (a, b) => a.english - b.english,
        multiple: 1,
      },
    },
    {
      title: '球场',
      dataIndex: 'court',
    },
    {
      title: '类型',
      dataIndex: 'type',
    },
    {
      title: '开始洞',
      dataIndex: 'startHole',
    },
    {
      title: '结束洞',
      dataIndex: 'endHole',
    },
    {
      title: '总杆',
      dataIndex: 'totalRods',
    },
    {
      title: '耗时',
      dataIndex: 'timeConsuming',
      render: (value) => {
        return <>{formatTime(value)}</>
      }
    },
  ];
  // const onFinish = (values) => {
  //   const { time } = values
  //   let startTime = undefined
  //   let endTime = undefined
  //   if (values.time) {
  //     startTime = new Date(time[0]).getTime()
  //     endTime = new Date(time[1]).getTime()
  //   }
  //   dispatch({
  //     ...values,
  //     startTime,
  //     endTime,
  //   })
  // }
  useEffect(() => {
    dispatch({
      placeId
    })
  }, [placeId])
  return (
    <div className="data_list_main grid_display">
      <div className='breadcrumb'>
        <BreadComponent breadcrumbNameMap={breadcrumbNameMap} />
      </div>
      <div className='page_main'>
        {/* <div className='search_box'>
          <Form className='flex-btw' form={form} layout='inline' autoComplete="off" onFinish={onFinish}>
            <Space size={20}>
              <Form.Item name="time" label="日期:">
                <RangePicker />
              </Form.Item>
              <Form.Item label="昵称" name="playerName">
                <Input placeholder="输入预约人" allowClear />
              </Form.Item>
              <Form.Item label="电话" name="telephone">
                <Input placeholder="输入电话" allowClear />
              </Form.Item>
            </Space>
            <Space size={15}>
              <Button htmlType='reset'>重置</Button>
              <Button type='primary' htmlType='submit'>搜索</Button>
            </Space>
          </Form>
        </div> */}
        <div className='page_inner'>
          <GetErrorBox error={error} />
          <h4 className='table_list_title'>打位数据列表</h4>
          <Table
            loading={isLoading}
            columns={columns}
            dataSource={data?.rows}
            rowKey={record => record.id}
            onChange={(pagination) => onTableChange(pagination, dispatch)}
            pagination={{ pageSize: state.pageSize, total: data?.total, showQuickJumper: true, pageSizeOptions: [5, 10, 20, 30, 40, 50] }}
            scroll={{ x: '100%' }}
            expandable={{
              expandedRowRender: (record) => ExpandedRow(record),
              rowExpandable: (record) => {
                return record?.detailList.length
              },
              fixed: 'left'
            }}
          />
        </div>
      </div>
    </div>
  )
}
