import { useAsync } from './../use-async';
import { useHttp } from '../http';


export const useChangePassword = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const changePassword = (params) => {
    return run(client(`golfonlineClub/user/profile/updatePwd`, {
      method: 'PUT',
      data: {
        ...params
      }
    }))
  }
  return {
    ...result,
    changePassword
  }
}