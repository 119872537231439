
import { BreadComponent } from './../../component/breadcrumb';
import { useState } from 'react';
import './style/point.less'
import { ProjectTable } from './component/cost-setting-table';

export const CostProject = () => {
  const breadcrumbNameMap = [
    {
      name: '打球管理',
      url: '/'
    },
    {
      name: '费用设置',
      url: '/home/index/manager/cost_setting'
    },
    {
      name: '按球场',
      url: ''
    }
  ]
  const [type, setType] = useState('project')

  return (
    <div className='cost_project_main grid_display'>
      <div className='breadcrumb'>
        <BreadComponent breadcrumbNameMap={breadcrumbNameMap} />
      </div>
      <div className='page_main'>
        <div className='page_inner'>
          <ProjectTable pagination={true} pageSize={10} setType={setType} type={type} />
        </div>
      </div>
    </div>
  )
}