import {  Table } from "antd"
import { Footer } from "../../../component/drawer-modal"
import { GetErrorBox, PutErrorBox } from "../../../component/lib";
import { useNoticeOrderDetail, useEditNotice } from './../../../utils/index/order-notice';

export const OrderDetail = ({ orderData, setVisible, retry }) => {

  const orderIds = orderData?.orderId
  const { data, error: orderError, isLoading: loading } = useNoticeOrderDetail(orderIds) //查询订单详情
  const { editNotice, isLoading, error, setError } = useEditNotice() //接受
  const columns = [
    {
      title: '商品',
      dataIndex: 'goodsName',
    },
    {
      title: '单价',
      dataIndex: 'goodsPrice',
    },
    {
      title: '数量',
      dataIndex: 'goodsNum',
    },
    {
      title: '金额',
      dataIndex: 'consumptionAmount',
    }
  ]
  //接受操作
  const onFinish = () => {
    editNotice({ orderIds, status: 0 }).then(res => {
      if (res.code === 200) {
        retry()
        setVisible(false)
      }
    })
  }
  const onCancel = () =>{
    setVisible(false)
  }
  return (
    <div className="order_detail_main">
      <GetErrorBox error={orderError} />
      <PutErrorBox error={error} setError={setError} />
      <div className="flex-btw order_detail_header">
        <h6>{orderData?.placeName}</h6>
        <span>{orderData?.createTime}</span>
      </div>
      <Table
        loading={loading}
        dataSource={data?.rows}
        columns={columns}
        pagination={false}
        footer={() => <div className="flex-btw">
          <p>合计</p>
          <p style={{ width: '25%', paddingLeft: '26px', color: '#E34D59' }}>{data?.attach.total}</p>
        </div>}
      />
      <div className="order_remarks_box mt-30">
        <h5>订单备注</h5>
        <div className="order_remarks">{data?.attach.remark}</div>
      </div>
      <Footer onFinish={onFinish} onCancel={onCancel} isLoading={isLoading} confirm={'接受'} btnText={'取消'} />
    </div>
  )
}