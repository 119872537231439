import { Form, Input } from 'antd';
import { Footer } from '../../../../component/drawer-modal'
import { useAddMember, useEditMember } from '../../../../utils/business-admin/index'
import { useEffect } from 'react';
import { useMerchantId } from '../../../../utils/common';
import { PutErrorBox } from '../../../../component/lib';
import { useState } from 'react';
// import { useSelect } from './../../../../utils/use-select';
import { IdSelect } from './../../../../component/id-select';
import { useEditAdmin, useLevel } from '../../../../utils/business-admin/admin';
import { useAddAdmin } from './../../../../utils/business-admin/admin';
import { useIdentityOptions, useIdentityDetail } from './../../../../utils/business-admin/index';

export const AddUserForm = ({ isAddUser, setVisible, retry, memberData, visible, memberRetry }) => {
  // console.log(memberData)
  const [form] = Form.useForm()
  const { addMember, isLoading: isAddLoading } = useAddMember() //添加
  const { editMember, isLoading: isEditLoading } = useEditMember() //编辑
  const [error, setError] = useState()
  const merchantId = useMerchantId()
  const { data } = useIdentityOptions(merchantId) //获取身份类型
  const [identityId, setIdentityId] = useState(memberData?.identity)
  const [change,setChange] = useState(false)
  const { data: identityDetail } = useIdentityDetail(identityId)

  //提交表单
  const onFinish = (value) => {
    if (isAddUser) {
      addMember({
        ...value,
        merchantId
      }).then(res => {
        if (res.code === 200) {
          setVisible(false)
          form.setFieldsValue({
            discount: ''
          })
          retry()         
        }
      })
    } else {
      editMember({
        ...value,
        merchantId
      }).then(res => {
        if (res.code === 200) {
          setVisible(false)
          memberRetry()
        } else {
          setError(res)
        }
      })
    }
  }
  const onSelectChange = (value) => {
    // console.log(value)
    setChange(!change)
    setIdentityId(value)
  }
  //初始化编辑表单
  useEffect(() => {
    if (isAddUser) {
      form.resetFields()
    } else {
      form.setFieldsValue(memberData)
    }
    setError(null)
  }, [isAddUser, form, memberData, visible])

  useEffect(() => {
    form.setFieldsValue({
      discount: identityDetail?.data?.discount
    })
  }, [identityDetail, form,change])
  
  return (
    <Form
      onFinish={onFinish}
      form={form}
      layout='horizontal'
      autoComplete='off'
      labelCol={{
        span: 3,
      }}>
      <PutErrorBox error={error} setError={setError} />
      {
        isAddUser ? null :
          <Form.Item name="id" label="编号">
            <Input disabled />
          </Form.Item>
      }
      <Form.Item name="userName" label="昵称" rules={[{ required: true, message: '请输入昵称' }]}>
        <Input placeholder="请输入昵称" allowClear />
      </Form.Item>
      <Form.Item name="telephone" label="电话" rules={[{ required: true, message: '请输入电话' }]}>
        <Input placeholder="请输入电话" maxLength={11} allowClear />
      </Form.Item>
      <Form.Item name="identity" label="身份" rules={[{ required: true, message: '请选择身份' }]}>
        <IdSelect options={data?.data} onSelect={onSelectChange} />
      </Form.Item>
      {
        isAddUser ? null : <Form.Item name="memberNo" label="会员号">
          <Input disabled />
        </Form.Item>
      }
      <Form.Item name="discount" label="折扣">
        <Input disabled placeholder="请输入折扣" type={'number'} allowClear />
      </Form.Item>
      <Footer isLoading={isAddLoading || isEditLoading} />
    </Form>
  )
}

export const AddAdminForm = ({ retry, visible, setVisible, adminData, modeType }) => {
  const [form] = Form.useForm()
  const { data } = useLevel() //查询级别
  const { editAdmin, isLoading: isAddLoading, error: editError, setError: setEditError } = useEditAdmin()
  const { addAdmin, isLoading: isEditLoading, error: addError, setError: setAddError } = useAddAdmin()

  //提交表单
  const onFinish = (values) => {
    if (modeType === 1) {
      editAdmin({ ...values, userId: adminData.userId }).then(res => {
        if (res.code === 200) {
          setVisible(false)
          retry()
        }
      })
    } else {
      addAdmin({ ...values }).then(res => {
        if (res.code === 200) {
          setVisible(false)
          retry()
        }
      })
    }
  }
  //初始化编辑表单
  useEffect(() => {
    // console.log(adminData)
    if (modeType === 1) {
      form.setFieldsValue(adminData)
    } else {
      form.resetFields()
    }
    setEditError(null)
    setAddError(null)
  }, [modeType, form, adminData, visible,setEditError,setAddError])
  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout='horizontal'
      autoComplete='off'
      labelCol={{
        span: 3,
      }}>
      <PutErrorBox error={editError || addError} setError={setAddError || setEditError} />
      <Form.Item name="userName" label="用户名" rules={[{ required: true, message: '请输入用户名' }]}>
        <Input placeholder="请输入用户名" allowClear />
      </Form.Item>
      <Form.Item name="nickName" label="真实姓名" rules={[{ required: true, message: '请输入姓名' }]}>
        <Input placeholder="请输入姓名" allowClear />
      </Form.Item>
      {
        adminData?.roleKey === 'admin' && modeType === 1 ? null : <Form.Item name="roleId" label="管理级别" rules={[{ required: true, message: '请选择管理级别' }]}>
          <IdSelect disabled={adminData?.admin && modeType === 1} options={data?.rows} />
        </Form.Item>
      }

      <Form.Item name="password" label="密码" rules={[{ required: modeType === 3, message: '请输入密码' }]}>
        <Input type='password' placeholder="请输入密码" allowClear />
      </Form.Item>
      <Footer isLoading={isAddLoading || isEditLoading} />
    </Form>
  )
}