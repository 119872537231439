import { Form, DatePicker, Input, Button } from 'antd';
// import { Footer } from '../../../../component/drawer-modal';
import { PutErrorBox } from '../../../../component/lib';
import { disabledDate } from '../../../../utils/common';
import { useAddAppointment } from '../../../../utils/manager-admin/point-list';
import { useReducer } from 'react';
import { useMerchantId } from './../../../../utils/common';
import { useEffect } from 'react';
import { useMembers } from '../../../../utils/manager-admin/order';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useRouteId } from '../../../../utils/common';
import { useClubPlaceDetail } from '../../../../utils/manager-admin';

export const CreateOrder = () => {
  const [form] = Form.useForm()
  const merchantId = useMerchantId()
  const placeId = useRouteId()
  const [state, dispatch] = useReducer((state, action) => ({ ...state, ...action }), {
    telephone: '',
    merchantId
  })
  const {data:clubPlaceDetailData} = useClubPlaceDetail(placeId) //查询某打位信息
  const clubPlaceDetail = clubPlaceDetailData?.data
  const navigate = useNavigate()
  const { data } = useMembers(state) //根据电话查询会员
  const memberData = data?.data
  const { addAppointment, isLoading, error, setError } = useAddAppointment()  //添加预约
  const onPhoneChange = (e) => {
    dispatch({
      telephone: e.target.value
    })
  }
  //创建订单 
  const onFinish = (values) => {
    // console.log(values)
    const startTime = new Date(values.startTime).getTime()
    const endTime = new Date(values.endTime).getTime()
    const params = {
      ...values,
      startTime,
      endTime,
      placeId
    }
    addAppointment(params).then(res => {
      if (res.code === 200) {
        navigate(`/home/index/manager/order_info/${placeId}?orderId=${res.data}`)
      } else {
        setError(res)
      }
    })
  }
  //根据电话号码查询身份会员号
  useEffect(() => {
    if (memberData) {
      console.log(memberData)
      const { memberNo, identityDict, userName } = memberData
      form.setFieldsValue({
        memberNo,
        identityDict,
        appointmentPerson: userName
      })
    } else {
      form.setFieldsValue({
        memberNo: '',
        identityDict: '',
        appointmentPerson: ''
      })
    }
  }, [memberData, form])

  useEffect(() => {
    let start
    if(clubPlaceDetail?.status===2){
      start = new Date(clubPlaceDetail?.endTime).getTime() + 5*60*1000
    } else {
      start = new Date().getTime()
    }
   
    const startTime = moment(start)
    const duration = form.getFieldValue('duration')
    const end = start + (duration * 60 * 1000)
    const endTime = moment(end)
    form.setFieldsValue({
      startTime,
      endTime
    })
  }, [form,clubPlaceDetail])
  return <div className='create_order_main'>
    <Form
      form={form}
      autoComplete="off"
      onFinish={onFinish}
      labelCol={{
        span: 5,
      }}
    >
      <PutErrorBox error={error} setError={setError} />
      <Form.Item name="appointmentPerson" label="姓名" rules={[{ required: true, message: '请填写姓名' }]}>
        <Input placeholder='请输入姓名' allowClear />
      </Form.Item>
      <Form.Item name="telephone" label="电话：">
        <Input placeholder='请输入电话' onChange={onPhoneChange} allowClear />
      </Form.Item>
      <Form.Item name="memberNo" label="会员号：">
        <Input placeholder='请输入会员号' disabled />
      </Form.Item>
      <Form.Item name="identityDict" label="身份：">
        <Input placeholder='请输入身份' disabled />
      </Form.Item>
      <Form.Item name="startTime" label="开始时间：" rules={[{ required: true, message: '请选择开始时间' }]}>
        <DatePicker
          style={{ width: '100%' }}
          disabledDate={disabledDate}
          showTime={{ format: "HH:mm" }}
          format="YYYY-MM-DD HH:mm"
          onChange={(date) => {
            const startTime = new Date(date).getTime()
            const duration = form.getFieldValue('duration')
            const end = startTime + (duration * 60 * 1000)
            const endTime = moment(end)
            form.setFieldsValue({
              endTime
            })
          }}
        />
      </Form.Item>
      <Form.Item name="duration" initialValue={30} label="消费时长/分钟："
        rules={[() => (
          {
            validator(_, value) {
              if (value > 1440) {
                return Promise.reject(new Error('消费时间在24小时以内'));
              } else {
                return Promise.resolve()
              }
            }
          }
        )]}
      >
        <Input
          type={'number'}
          onChange={(e) => {
            const startTime = new Date(form.getFieldValue('startTime')).getTime()
            const duration = e.target.value
            const end = startTime + (duration * 60 * 1000)
            const endTime = moment(end)
            form.setFieldsValue({
              endTime
            })
          }}
          placeholder='请输入消费时长'
        />
      </Form.Item>
      <Form.Item name="endTime" label="结束时间：" rules={[{ required: true, message: '请选择开始时间' }]}>
        <DatePicker
          secondStep={60}
          style={{ width: '100%' }}
          disabledDate={disabledDate}
          showTime={{ format: "HH:mm" }}
          format="YYYY-MM-DD HH:mm"
          onChange={(date) => {
            const endTime = new Date(date).getTime()
            const startTime = new Date(form.getFieldValue('startTime')).getTime()
            const duration = Number(((endTime - startTime) / 60 / 1000).toFixed(0))
            form.setFieldsValue({
              duration
            })
          }}
        />
      </Form.Item>
      <div className='create_btn'>
        <Button type='primary' loading={isLoading} htmlType='submit'>创建订单</Button>
      </div>
    </Form>
  </div>
}