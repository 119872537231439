

import { Form, Input, TimePicker } from 'antd';
import { Footer } from '../../../../component/drawer-modal';
import { useState, useEffect } from 'react';
import { IdSelect } from '../../../../component/id-select';
import { useSelect } from '../../../../utils/use-select';
import { useAddGoodOrder, useEditConsumption, useGoodPrice, useGoodsName } from '../../../../utils/manager-admin/order';
import { PutErrorBox } from '../../../../component/lib';
import { useMerchantId } from './../../../../utils/common';
import moment from 'moment'

//打球消费
export const PlayBuyEdit = ({ visible, setVisible, retry, projectData }) => {
  // console.log(projectData)
  const [form] = Form.useForm()
  const { data: modeData } = useSelect('club_charging_mode') //查询模式
  const { editConsumption, isLoading, error, setError } = useEditConsumption() //编辑打球消费

  const onFinish = (values) => {
    const giftDuration = new Date(values?.giftDuration).getTime()
    editConsumption({
      ...values,
      giftDuration,
      id: projectData.id
    }).then(res => {
      if (res.code === 200) {
        setVisible(false)
        retry()
      }
    })
  }
  useEffect(() => {
    const giftDuration = projectData?.giftDurationStr ? moment(new Date(projectData?.giftDurationStr).getTime()) : null
    form.setFieldsValue({ ...projectData, giftDuration })
    setError(null)
  }, [visible, form, projectData, setError])
  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout='horizontal'
      labelCol={{
        span: 4,
      }}>
      <PutErrorBox error={error} setError={setError} />
      <Form.Item name="mode" label="模式" rules={[{ required: true, message: '请选择模式' }]}>
        <IdSelect options={modeData?.data} disabled={projectData?.typeId !== 5000} />
      </Form.Item>

      <Form.Item name="unitPrice" label="单价" rules={[{ required: true, message: '请输入单价' }]}>
        <Input placeholder='请输入单价' allowClear />
      </Form.Item>
      <Form.Item name="giftDuration" label="赠送时长">
        <TimePicker format={'HH:mm:ss'} showNow={false} style={{ width: '100%' }} allowClear />
      </Form.Item>
      <Form.Item name="discount" label="身份折扣">
        <Input placeholder='请输入身份折扣' disabled />
      </Form.Item>
      <Footer isLoading={isLoading} />
    </Form>
  )
}

//手动下单
export const HandOrder = ({ visible, setVisible, retry, orderId }) => {
  const [form] = Form.useForm()
  const merchantId = useMerchantId() //商户id
  const [goodsId, setGoodsId] = useState(0) //商品id
  const [googdPrice, setGoodPrice] = useState() //商品单价
  const { data: goodsNameData } = useGoodsName(merchantId) //查询商品名称

  const { addGoodOrder, error, setError, isLoading } = useAddGoodOrder() //手动下单
  const { data: goodPriceData } = useGoodPrice(goodsId) //查询商品价格


  //选择商品
  const onChange = (value) => {
    setGoodsId(value)
  }
  //下单提交
  const onFinish = (values) => {
    console.log(values)
    addGoodOrder({
      ...values,
      orderId
    }).then(res => {
      if (res.code === 200) {
        setVisible(false)
        retry()
      }
    })
  }

  useEffect(() => {
    setGoodPrice(goodPriceData?.data.price)
    setError(null)
  }, [goodPriceData?.data, visible, setError])

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout='horizontal'
      autoComplete='off'
      labelCol={{
        span: 4,
      }}>
      <PutErrorBox error={error} setError={setError} />
      <Form.Item name="goodsId" label="商品名称" rules={[{ required: true, message: '请选择商品' }]}>
        <IdSelect options={goodsNameData?.data} onChange={onChange} />
      </Form.Item>

      <Form.Item name="goodsNum" label="数量" rules={[{ required: true, message: '请输入数量' }, () => (
        {
          validator(_, value) {
            if (value < 0) {
              return Promise.reject(new Error('请输入正确数量'));
            } else {
              return Promise.resolve()
            }
          }
        }
      )]}>
        <Input placeholder='请输入数量' type={'number'} allowClear />
      </Form.Item>
      <Form.Item label="单价">
        <span>{googdPrice || '-'}</span>
      </Form.Item>

      <Footer isLoading={isLoading} />
    </Form>
  )
}