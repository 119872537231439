import React, { useEffect, useState } from 'react'
import { Routes, Route } from 'react-router'
import { useLocation, Link } from 'react-router-dom' 
import { Layout, Menu } from 'antd';
import styled from '@emotion/styled';
import { DataIndex } from '../data-count/index';
import { StadiumIndex } from './../stadium-info/index';
import { MiniprojectStadiumDisplay } from './../miniproject-admin/index';
import { ManagerAdmin } from '../manager-admin/index';
import { AccountData } from './../miniproject-admin/account-data';
import { Records } from './../miniproject-admin/records';
import { PlayData } from './../data-count/play';
import { BuyData } from './../data-count/buy';
import { PointList } from './../manager-admin/point-list';
import { menuList } from './data'
import { BroadcastSetting } from './../manager-admin/broadcast-setting';
import { CostSetting } from './../manager-admin/cost-setting';
import { CostProject } from './../manager-admin/cost-project';
import { Appointment } from './../manager-admin/appointment';
import { DataList } from './../manager-admin/data-list';
import { OrderInfo } from './../manager-admin/order-info';
import { OrderNotice } from './order-notice';
import { EmailNotice } from './email-notice';
import { StatisticsCharts } from './../data-count/statistics-chart';
import { Administrators } from './../business-admin/administrators';
import { ChangePassword } from './../business-admin/change-password';
import { PlayerAdmin } from './../business-admin/index';
import { PlayerDetail } from './../business-admin/player-detail';
import './index.less'
import { GoodsInfo } from './../miniproject-admin/goods-info';
import { IncomeStatistics } from './../miniproject-admin/income-statistics';
import { PositionIcon, DataCountIcon, StadiumInfoIcon, BusinessIcon, MiniprojectIcon } from '../../component/custom-icon'
import { GoodsType } from '../miniproject-admin/goods-type';
import { IdentityAdmin } from './../business-admin/identity-type';
import { useIsLimit } from './../../utils/common';
import { LockScreenSetting } from './../manager-admin/lock-screen-setting';
import { CreateOrderPage } from './../manager-admin/create-order-page';

export const AdminIndex = () => {
  const { SubMenu } = Menu
  const { Content, Sider } = Layout;
  const location = useLocation()
  const name = location.pathname.split('/')[3]
  const menuItme = menuList.filter(item => item.router === name)
  const [selectkey, setSelectKey] = useState([]) //选中的
  const [openKey, setOpenKey] = useState([]) //展开的key
  //以下是权限控制
  const operateDataLimit = useIsLimit('club:operateData:query')
  const merchantUserLimit = useIsLimit('club:merchantUser:query')
  const userLimit = useIsLimit('*:*:*')
  const merchantDetailLimit = useIsLimit('wechat:merchant:detail')
  const goodsLimit = useIsLimit('wechat:goods:query')
  const settleLimit = useIsLimit('wechat:settle:query')
  const qrcodeLimit = useIsLimit('wechat:qrcode:query')

  useEffect(() => {
    const index = menuItme[0].id.toString()
    const sub = menuItme[0].sub
    const selectedKeys = index.split()
    const openKeys = sub.split()
    setSelectKey(selectedKeys)
    setOpenKey(openKeys)
  }, [])

  const onSelect = ({ selectedKeys }) => {
    setSelectKey(selectedKeys)
  }
  const onOpenChange = (openKeys) => {
    setOpenKey(openKeys)
  }
  return (
    <Layout>
      <Sider width={228} className="site-layout-background" data-sider="index_menu">
        <Menu
          selectedKeys={selectkey}
          forceSubMenuRender={true}
          openKeys={openKey}
          mode="inline"
          onSelect={onSelect}
          onOpenChange={onOpenChange}
        >
          <Menu.Item key="0" icon={<PositionIcon />}><Link to='index/manager'>打位管理</Link></Menu.Item>
          <SubMenu key="sub1" icon={<DataCountIcon />} title="数据统计">
            {
              !operateDataLimit ? <Menu.Item key="1"><Link to="data/operate">运营数据</Link></Menu.Item> : null
            }
            <Menu.Item key="2"><Link to="data/play">打球数据</Link></Menu.Item>
            <Menu.Item key="3"><Link to="data/buy">消费数据</Link></Menu.Item>
          </SubMenu>
          <Menu.Item key="4" icon={<StadiumInfoIcon />}><Link to={'index/stadium'}>球馆信息</Link></Menu.Item>
          <SubMenu key="sub2" icon={<BusinessIcon />} title="商户管理">
            {
              !merchantUserLimit ? <Menu.Item key="5"><Link to="business/player">会员管理</Link></Menu.Item> : null
            }
            {
              !userLimit ? <Menu.Item key="6"><Link to="business/administrators">管理员</Link></Menu.Item> : null
            }

            <Menu.Item key="7"><Link to="business/change_password">修改密码</Link></Menu.Item>
          </SubMenu>
          <SubMenu key="sub3" icon={<MiniprojectIcon />} title="小程序管理">
            {
              !merchantDetailLimit ? <Menu.Item key="8"><Link to="miniproject/display">球馆展示</Link></Menu.Item> : null
            }
            {
              !goodsLimit ? <Menu.Item key="9"><Link to="miniproject/goods_info">商品信息</Link></Menu.Item> : null
            }
            <Menu.Item key="10"><Link to="miniproject/income_statistics">预约金统计</Link></Menu.Item>
            {
              !settleLimit ? <Menu.Item key="11"><Link to="miniproject/account_data">结算数据</Link></Menu.Item> : null
            }
            {
              !qrcodeLimit ? <Menu.Item key="12"><Link to="miniproject/records">二维码</Link></Menu.Item> : null
            }
          </SubMenu>
        </Menu>
      </Sider>
      <PageMain>
        <Content className="site-layout-background">
          <Routes>
            <Route path={'/index'}>
              <Route path={'manager/*'} element={<ManagerAdmin />} />
              <Route path={'manager/pointList'} element={<PointList />} />
              <Route path={'manager/broadcast_setting'} element={<BroadcastSetting />} />
              <Route path={'manager/cost_setting/*'} element={<CostSetting />} />
              <Route path={'manager/cost_setting/project'} element={<CostProject />} />
              <Route path={'manager/appointment/*'} element={<Appointment />} />
              <Route path={'manager/data_list/*'} element={<DataList />} />
              <Route path={'manager/order_info/*'} element={<OrderInfo />} />
              <Route path={'manager/create_order/*'} element={<CreateOrderPage />} />
              <Route path={'manager/lock_screen_setting'} element={<LockScreenSetting />} />
              <Route path={'order_notice'} element={<OrderNotice />} />
              <Route path={'email_notice'} element={<EmailNotice />} />
            </Route>
            <Route path={'/data'} >
              <Route path={'operate/*'} element={<DataIndex />} />
              <Route path={'operate/charts'} element={<StatisticsCharts />} />
              <Route path={'play'} element={<PlayData />} />
              <Route path={'buy'} element={<BuyData />} />
            </Route>
            <Route path={'/index/stadium'} element={<StadiumIndex />} />
            <Route path={'/business'}>
              <Route path={'player/*'} element={<PlayerAdmin />} />
              <Route path={'player/detail/:id'} element={<PlayerDetail />} />
              <Route path={'player/identity_type'} element={<IdentityAdmin />} />
              <Route path={'administrators/*'} element={<Administrators />} />
              <Route path={'change_password'} element={<ChangePassword />} />
            </Route>
            <Route path={'/miniproject'} >
              <Route path={'display'} element={<MiniprojectStadiumDisplay />} />
              <Route path={'goods_info/*'} element={<GoodsInfo />} />
              <Route path={'goods_info/goods_type'} element={<GoodsType />} />
              <Route path={'income_statistics'} element={<IncomeStatistics />} />
              <Route path={'account_data'} element={<AccountData />} />
              <Route path={'records'} element={<Records />} />
            </Route>
          </Routes>
        </Content>
      </PageMain>
    </Layout>
  )
}

const PageMain = styled(Layout)`
min-height:calc(100vh - 6rem);
`
