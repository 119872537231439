

import { Tabs } from 'antd';
import { useQueryTabs } from '../../../utils/business-admin/admin';
import { AuthSelect } from './lib/auth-select';
import { useState } from 'react';
import { useEffect } from 'react';
import { GetErrorBox } from '../../../component/lib';
const { TabPane } = Tabs;

export const AuthorityManagement = ({visible,setVisible}) => {
  const { data,error } = useQueryTabs()
  const tabList = data?.rows
  const [activeKey,setActiveKey] = useState()
  const onChange = (activeKey) => {
    setActiveKey(activeKey)
  }
  useEffect(()=>{
    if(tabList?.length > 0){
      setActiveKey(tabList[0].roleId)
    }   
  },[tabList])
  return (
    <div className="authority_management_main">
      <GetErrorBox error={error} />
      <Tabs size='large' type="card" onChange={onChange}>
        {
          tabList?.map(item => {
            return <TabPane tab={item.roleName} key={item.roleId}>
            <AuthSelect activeKey={activeKey} visible={visible} setVisible={setVisible} />
          </TabPane>
          })
        }
      </Tabs>
    </div>
  )
}