import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

export const BreadComponent = ({ breadcrumbNameMap }) => {

  return (
    <Breadcrumb>
      <Breadcrumb.Item key="home">
        {/* <Link to="/">首页</Link> */}
      </Breadcrumb.Item>
      {
        breadcrumbNameMap?.map(item => {
          return (
            <Breadcrumb.Item key="home">
              <Link to={item.url}>{item.name}</Link>
            </Breadcrumb.Item>
          )
        })
      }
    </Breadcrumb>
  )
}