import React, { useContext, useState, useReducer, useEffect } from 'react';
import { useNoticeList } from './../utils/index/order-notice';

const OrderContext = React.createContext(undefined);

OrderContext.displayName = 'MyOrderContext'

export const OrderProvider = ({ children }) => {
  const [dot, setDot] = useState(false)
  const [state, dispatch] = useReducer((state, action) => ({ ...state, ...action }), {
    page: 1,
    pageSize: 10
  })
  const { data, isLoading: noticeIsLoading, retry: noticeRetry, error: noticeError } = useNoticeList(state)
  const noticeList = data?.rows

  useEffect(() => {
    setDot(noticeList?.length)
  }, [noticeList])

  return <OrderContext.Provider
    children={children}
    value={{ dot, setDot, noticeList, noticeIsLoading, noticeRetry, dispatch, noticeError }} />
}

export const useOrder = () => {
  const context = useContext(OrderContext)
  if (!context) {
    throw new Error('useAuth必须在AuthProvider中使用')
  }
  return context
}