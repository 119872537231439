import { useState } from 'react'
import { useCommonClubPlaceList, useLuxuryClubPlaceList } from "../../../utils/manager-admin";
import { Dropdown, Menu, Spin, message } from 'antd';
import { ButtonNoPadding } from '../../../component/lib'
import { useNavigate, Link } from 'react-router-dom';
import { ClubPlaceStatus } from './club-place-status';
import { useChangeClubPlaceStatus } from '../../../utils/manager-admin/index';
import { DrawerModal } from '../../../component/drawer-modal';
import { ChangePlaceEdit, LockEdit } from './lib/lock-edit';
import { PrivilegedButton } from './../../../component/privileged-button';
import { EmptyBox } from './../../../component/lib';
import { AppointIcon, LockIcon, DataIcon, UnLockIcon, UnAppointIcon, ChangePlaceIcon, UnChangePlaceIcon,CreateOrderIcon } from '../../../component/custom-icon';


export const ClubPlaceList = ({ state, type }) => {
  const navigate = useNavigate()
  const [visible, setVisible] = useState(false)
  const [placeType, setPlaceType] = useState('')
  const [placeData, setPlaceData] = useState()
  const { data: commonData, isLoading: isCommonLoading, retry: commonRetry } = useCommonClubPlaceList(state)  //查询普通打位
  const commonClubPlaceList = commonData?.rows
  const { data: luxuryData, isLoading: isLuxuryLoading, retry: luxuryRetry } = useLuxuryClubPlaceList(state)  //查询豪华打位
  const luxuryClubPlaceList = luxuryData?.rows
  // const { openDevice } = useOpenDevice() //上机

  //判断是普通还是豪华的
  const clubPlaceList = type === 'common' ? commonClubPlaceList : luxuryClubPlaceList
  const isLoading = type === 'common' ? isCommonLoading : isLuxuryLoading
  const retry = type === 'common' ? commonRetry : luxuryRetry

  const [clubId, setClubId] = useState() //点击的id
  const { changeClubPlace, isLoading: changeStatusLoading, error: changeStatusError, setError: setChangeStatusError } = useChangeClubPlaceStatus() //锁定和解锁

  return (
    <div className="normal_manager_main">
      <Spin size='default' spinning={isLoading}>
        {
          clubPlaceList?.length ?
            <ul className="general_list">
              {
                clubPlaceList?.map((item, index) => {
                  return <li key={index} className="place_item">
                    <div className="general_list_inner"
                      onClick={() => {
                        if (item.status === 3 || item.status === 4) {
                          message.error(item.status === 3 ? '该打位已锁定' : '该打位不可用')
                          return
                        }
                        if(item.status === 2){
                          navigate(`order_info/${item.id}?orderId=${item.orderId ? item.orderId : -1}`)
                        } else {
                          navigate(`create_order/${item.id}`)
                        }                      
                      }}>
                      <div className="more" onClick={(e) => { e.stopPropagation() }}>
                        <Dropdown placement="bottomRight" overlay={
                          <Menu>
                            <Menu.Item disabled={item.status === 3 ||item.status === 4 } key="0" icon={<CreateOrderIcon status={item.status === 1 ||item.status === 2 } />}>
                              <Link to={`create_order/${item?.id}`}>创建订单</Link>
                            </Menu.Item>
                            <Menu.Item key="1" disabled={item?.status === 4 ? true : false}
                              icon={item?.status === 4 ? <UnAppointIcon /> : <AppointIcon />}>
                              <Link to={`appointment/${item?.id}`}>预约列表</Link>
                            </Menu.Item>
                            <PrivilegedButton code="club:place:lock">
                              <div style={{ zIndex: '999' }} onClick={(e) => {
                                e.stopPropagation()
                                setClubId(item?.id)
                                if (item?.status === 1) {
                                  setVisible(true)
                                  setPlaceType('')
                                } else if (item?.status === 3) {
                                  //解锁
                                  changeClubPlace({
                                    id: item?.id,
                                    status: 1
                                  }).then(res => {
                                    if (res.code === 200) {
                                      message.success(res.msg)
                                      retry()
                                    }
                                  })
                                }
                              }}>
                                <Menu.Item disabled={item?.status === 1 || item?.status === 3 ? false : true} key="2"
                                  icon={item?.status === 1 || item?.status === 3 ? <LockIcon /> : <UnLockIcon />}>
                                  <span>{item?.status === 3 ? '解锁' : '锁定'}</span>
                                </Menu.Item>
                              </div>
                            </PrivilegedButton>
                            <Menu.Item key="3" icon={<DataIcon />}><Link to={`data_list/${item.id}`}>数据</Link></Menu.Item>
                            <Menu.Item
                              key="4"
                              disabled={item?.status !== 2}
                              icon={item?.status === 2 ? <ChangePlaceIcon /> : <UnChangePlaceIcon />}
                              onClick={() => {
                                setVisible(true)
                                setPlaceType(item.placeType)
                                setPlaceData(item)
                              }}
                            >换机</Menu.Item>
                          </Menu>
                        }
                        >
                          <ButtonNoPadding type="link">
                            <img style={{ width: '25px', height: '25px' }} src={require('../../../assets/imgs/manager/more.png')} alt="" />
                          </ButtonNoPadding>
                        </Dropdown>
                      </div>
                      <div className="inner_title">
                        <h3>{item?.placeName}</h3>
                        <span className="line"></span>
                      </div>
                      <ClubPlaceStatus clubPlaceList={clubPlaceList} clubData={item} retryPlaceList={retry} />
                    </div>
                  </li>
                }
                )
              }
            </ul>
            : <EmptyBox />
        }
      </Spin>
      <DrawerModal title={placeType ? '换机' : '锁定'} visible={visible} setVisible={setVisible}>
        {
          placeType ? <ChangePlaceEdit
            placeType={placeType}
            placeData={placeData}
            retry={retry}
            setVisible={setVisible}
            visible={visible}
          /> : <LockEdit
            changeStatusError={changeStatusError}
            setChangeStatusError={setChangeStatusError}
            setVisible={setVisible}
            visible={visible}
            id={clubId}
            changeStatusLoading={changeStatusLoading}
            changeClubPlace={changeClubPlace}
            retry={retry} />
        }

      </DrawerModal>
    </div>
  )
}