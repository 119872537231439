
import styled from '@emotion/styled';
import { Button, Form, Input, Switch, Spin, Space, Typography, InputNumber, message } from 'antd';
import { BreadComponent } from './../../component/breadcrumb';
import { useBroadcasts, useEditBroadcasts } from './../../utils/manager-admin/broadcast-setting';
import { useState, useEffect, useMemo } from 'react';
import { IdSelect } from './../../component/id-select';
import { useSelect } from './../../utils/use-select';
import { GetErrorBox, PutErrorBox } from './../../component/lib';
import { PrivilegedButton } from './../../component/privileged-button';

export const BroadcastSetting = () => {
  const breadcrumbNameMap = [
    {
      name: '打位管理',
      url: '/'
    },
    {
      name: '广播设置',
      url: ''
    }
  ]
  const [form] = Form.useForm()
  const { data: broadList, error, isLoading, retry } = useBroadcasts() //获取广播设置的信息
  const { data: options } = useSelect('club_trigger_countdown') //获取下拉倒计时
  const rows = useMemo(() => broadList?.rows, [broadList])
  const { editBroadcasts, isLoading: editLoading } = useEditBroadcasts()
  const [editError, setEditError] = useState()
  //提交
  const onFinish = (values) => {
    const keys = Object.keys(values)
    const newList = []
    rows?.forEach((item, index) => {
      const newObject = {}
      keys.forEach(key => {
        const name = key.split(String(index))[0]
        if (key.slice(-1) === String(index)) {
          newObject[name] = values[key]
          newObject.id = item.id
        }
      })
      newList.push(newObject)
    })
    //编辑广播设置
    editBroadcasts(newList).then(res => {
      if (res.code === 200) {
        retry()
        message.success(res.msg)
      } else {
        setEditError(res)
      }
    })
  }
  useEffect(() => {
    const keys = ['content', 'autoPlay', 'triggerTime', 'frequency']
    const temObject = {}
    rows?.forEach((row, index) => {
      keys.forEach(key => {
        const name = key + index
        temObject[name] = row[key]
      })
    })
    form.setFieldsValue(temObject)
  }, [rows, form])
  return (
    <div className='broadcast_setting_main grid_display'>
      <div className='breadcrumb'>
        <BreadComponent breadcrumbNameMap={breadcrumbNameMap} />
      </div>
      <BroadcastPage>
        <BroadcastMain>
          <GetErrorBox error={error} />
          <PutErrorBox error={editError} setError={setEditError} />
          <Spin spinning={isLoading} size='default'>
            <Form form={form} onFinish={onFinish} autoComplete="off">
              {
                rows?.map((row, index) => {
                  return (
                    <div className='ad_template' key={index}>
                      <Typography.Title level={5}>{row.name}</Typography.Title>
                      <Form.Item name={'content' + index} rules={[{ required: true, message: '请填写广播内容' }]}>
                        <Input.TextArea placeholder='请填写广播内容' />
                      </Form.Item>
                      <Form.Item name={'autoPlay' + index} label="自动播放" valuePropName="checked">
                        <Switch size='default' />
                      </Form.Item>
                      {
                        row.triggerTime ? (<Space size={[32, 0]}>
                          <Form.Item name={'triggerTime' + index} label="触发倒计时">
                            <IdSelect style={{ width: 150 }} options={options?.data || []} />
                          </Form.Item>
                          <Form.Item name={'frequency' + index} label="播放次数" rules={[() => (
                            {
                              validator(_, value) {
                                if (!value || value < 0) {
                                  return Promise.reject(new Error('请输入正确播放次数'));
                                } else {
                                  return Promise.resolve()
                                }
                              }
                            }
                          )]}>
                            <InputNumber style={{ width: 150 }} allowClear />
                          </Form.Item>
                        </Space>) : null
                      }
                    </div>
                  )
                })
              }
              {
                rows ?
                  <PrivilegedButton code="club:broadcast:edit">
                    <div className='flex-center'><Button type='primary' loading={editLoading} htmlType='submit'>保存</Button></div>
                  </PrivilegedButton>
                  : null
              }
            </Form>
          </Spin>
        </BroadcastMain>
      </BroadcastPage>
    </div>
  )
}

const BroadcastPage = styled.div`
  min-height:100%;
  padding:2.4rem;
`
const BroadcastMain = styled.div`
  background:#fff;
  padding:2.4rem 3.2rem 1.5rem;
  min-height:100%;
`