
import { Table, Typography, Button, Form, Input, Space, Divider, message } from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import { DrawerModal } from './../../../component/drawer-modal';
import { usePriceList, useEditRemark, useStadiumBasics } from './../../../utils/miniproject-admin/stadium-display';
import { PriceListEdit } from './lib/price-list-edit';
import { showDeleteConfirm } from '../../../utils/common';
import { useDeletePriceRow } from '../../../utils/miniproject-admin/stadium-display'
import { useAuth } from './../../../context/auth-context';
import { PutErrorBox } from '../../../component/lib';
import { useIsLimit } from './../../../utils/common';
import { PrivilegedButton } from './../../../component/privileged-button';

const PriceTable = ({ setVisible, data, isLoading, setPriceData, setIsAdd, retry }) => {
  const { deletePrice } = useDeletePriceRow() //删除
  const priceListUpdate = useIsLimit('wechat:merchant:priceListUpdate')
  const columns = [
    {
      title: '项目名称',
      dataIndex: 'projectName',
    },
    {
      title: '收费模式',
      dataIndex: 'typeDict',
    },
    // {
    //   title: '工作日价格',
    //   dataIndex: 'workdayPrice',
    //   render: (value, pro) => {
    //     return (
    //       <>{value}元 / {pro.type === 2 ? '小时' : '场'}</>
    //     )
    //   }
    // },
    {
      title: '工作日价格',
      dataIndex: 'workdayDiscountPrice',
      render: (value, pro) => {
        return (
          <>{value}元 / {pro.type === 2 ? '小时' : '场'}</>
        )
      }
    },
    // {
    //   title: '节假日价格',
    //   dataIndex: 'offdayPrice',
    //   render: (value, pro) => {
    //     return (
    //       <>{value}元 / {pro.type === 2 ? '小时' : '场'}</>
    //     )
    //   }
    // },
    {
      title: '节假日价格',
      dataIndex: 'offdayDiscountPrice',
      render: (value, pro) => {
        return (
          <>{value}元 / {pro.type === 2 ? '小时' : '场'}</>
        )
      }
    },
    {
      title: '操作',
      render: (value, project) => {
        return (
          <Space>
            <Typography.Link
              disabled={priceListUpdate}
              onClick={() => {
                setVisible(true)
                setPriceData(value)
                setIsAdd(false)
              }}>编辑</Typography.Link>
            <Divider type="vertical" />
            <Typography.Link
              disabled={priceListUpdate}
              onClick={() => {
                showDeleteConfirm().then(res => {
                  if (res.code === 'ok') {
                    deletePrice(value.id).then((res) => {
                      if (res.code === 200) {
                        message.success('删除成功')
                        retry()
                      }
                    })
                  }
                })
              }}>删除</Typography.Link>
          </Space>
        )
      }
    },

  ]
  const dataSource = data?.data
  return <Table columns={columns} rowKey={(record) => record.id} dataSource={dataSource} pagination={false} loading={isLoading} />
}

export const StadiumPriceList = () => {
  const { user } = useAuth()
  const merchantId = user?.user?.merchantId
  const { data, isLoading, retry } = usePriceList(merchantId) //价目表
  const { EditRemark, error, setError, isLoading: isRemarkLoading } = useEditRemark() //编辑备注
  const { data: stadiumBasics, retry: remarkRetry } = useStadiumBasics() //获取基本信息的备注
  const [editableStr, setEditableStr] = useState()
  const [showInput, setShowInput] = useState(false)
  const [isAdd, setIsAdd] = useState(false)
  const [visible, setVisible] = useState(false)
  const [priceData, setPriceData] = useState()

  const onChange = (e) => {
    setEditableStr(e.target.value)
  }
  //修改备注
  const onFinishRemark = () => {
    EditRemark({ remark: editableStr, merchantId }).then(res => {
      if (res.code === 200) {
        message.success(res.msg)
        setShowInput(false)
        remarkRetry()
      } else {
        setError(res)
      }
    })
  }
  //获取备注
  useEffect(() => {
    setEditableStr(stadiumBasics?.data?.remark)
  }, [stadiumBasics?.data])

  return (
    <div className="price_list_main">
      <PutErrorBox error={error} setError={setError} />
      <Typography.Title level={4}>价目表</Typography.Title>
      <div className='mb-30'>
        <PriceTable
          retry={retry}
          setVisible={setVisible}
          data={data}
          isLoading={isLoading}
          setPriceData={setPriceData}
          setIsAdd={setIsAdd}
        />
      </div>
      <PrivilegedButton code="wechat:merchant:priceListUpdate">
        <Button type="dashed" block icon={<PlusOutlined />} onClick={() => {
          setIsAdd(true)
          setVisible(true)
        }}>
          新增项目
        </Button>
      </PrivilegedButton>
      <Form>
        <div className='remarks flex-btw'>
          <Form.Item label="备注：">
            {
              !showInput ? <div className='remarks_editable'>{editableStr}
                <PrivilegedButton code='wechat:merchant:remarkUpdate'>
                  <span className='edit_icon' onClick={() => {
                    setShowInput(true)
                  }}><EditOutlined /></span>
                </PrivilegedButton>
              </div> : <Input placeholder="备注" value={editableStr} onChange={onChange} allowClear />
            }
          </Form.Item>
          {
            showInput ? <Space>
              <Button type='primary' onClick={onFinishRemark} loading={isRemarkLoading}>保存</Button>
              <Button onClick={() => setShowInput(false)}>取消</Button>
            </Space> : null
          }
        </div>
      </Form>
      <DrawerModal title={isAdd ? '价目表新增' : '价目表编辑'} visible={visible} setVisible={setVisible}>
        <PriceListEdit isAdd={isAdd} priceData={priceData} visible={visible} setVisible={setVisible} retry={retry} />
      </DrawerModal>
    </div>
  )
}

