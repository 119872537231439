
import { useAsync } from './../use-async';
import { useHttp } from '../http';
import { useEffect } from 'react';
import { useCallback } from 'react';


//查询新类型默认收费
export const useDefaultCostList = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/clubCostSetUp/findDefaultCost`), [client])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}
//编辑默认收费
export const useEditDefaultCost = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const editDefaultCost = (params) => {
    return run(client(`golfonlineClub/clubCostSetUp`, {
      method: 'PUT',
      data: params
    }))
  }
  return {
    ...result,
    editDefaultCost
  }
}

//查询类型收费列表
export const useTypeCostList = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/clubCostType/list`), [client])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}

//查询单个类型收费
export const useTypeCost = (params) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  useEffect(() => {
    run(client(`golfonlineClub/clubCostType`, {
      data: params
    }))
  }, [run, client, params])
  return result
}

//编辑按类型收费
export const useEditTypeCost = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const editTypeCost = (params) => {
    return run(client(`golfonlineClub/clubCostType`, {
      method: 'PUT',
      data: params
    }))
  }
  return {
    ...result,
    editTypeCost
  }
}

//按类型模式切换
export const useChangeTypeMode = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const changeTypeMode = (params) => {
    return run(client(`golfonlineClub/clubCostType/changeMode`, {
      method: 'PUT',
      data: params
    }))
  }
  return {
    ...result,
    changeTypeMode
  }
}

//按球场列表
export const useGroundCostList = (params) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/clubCostProject/list`,{data:params}), [client,params])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}

//查询按球场单个
export const useGroundCost = (params) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  useEffect(() => {
    run(client(`golfonlineClub/clubCostProject`, {
      data: params
    }))
  }, [run, client, params])
  return result
}

//编辑按球场
export const useEditGroundCost = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const editGroundCost = (params) => {
    return run(client(`golfonlineClub/clubCostProject`, {
      method: 'PUT',
      data: params
    }))
  }
  return {
    ...result,
    editGroundCost
  }
}

//按球场模式切换
export const useChangeGroundMode = () => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const changeGroundMode = (params) => {
    return run(client(`golfonlineClub/clubCostProject/changeMode`, {
      method: 'PUT',
      data: params
    }))
  }
  return {
    ...result,
    changeGroundMode
  }
}

//按身份折扣查询列表
export const useIdentityList = (merchantId) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/identity/list`, {
    data: {
      merchantId
    }
  }), [client, merchantId])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}