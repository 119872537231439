import { useAsync } from './../use-async';
import { useHttp } from '../http';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { clearObject } from './../index';

//查询运营数据列表
export const useOperationalData = (params) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/dataStatistics/queryOperateDataList`, {
    data: {
      ...clearObject(params)
    }
  }), [client, params])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}

//运营数据详情
export const useOperationalDetail = (params) => {
  const client = useHttp()
  const { run, ...result } = useAsync()
  const fetchPromise = useCallback(() => client(`golfonlineClub/dataStatistics/queryOperateDataDetailList`, {
    data: {
      ...clearObject(params)
    }
  }), [client, params])
  useEffect(() => {
    run(fetchPromise(), { retry: fetchPromise })
  }, [client, run, fetchPromise])
  return result
}