
import { Table } from 'antd';
import { useLoginUser } from '../../../utils/data-count/buy-data';
import { useShopBuyList } from '../../../utils/manager-admin/order';
import { usePlayDetail } from './../../../utils/data-count/buy-data';
import { useMerchantId } from './../../../utils/common';
import { useEffect, useState } from 'react';
import { formatTime } from './../../manager-admin/component/club-place-status';


//登录账号表格
export const UsersTable = ({ orderId }) => {
  const columns = [
    {
      title: '账号1',
      dataIndex: 'account1'
    },
    {
      title: '账号2',
      dataIndex: 'account2'
    },
    {
      title: '账号3',
      dataIndex: 'account3'
    },
    {
      title: '账号4',
      dataIndex: 'account4'
    },
    {
      title: '账号5',
      dataIndex: 'account5'
    },
    {
      title: '账号6',
      dataIndex: 'account6'
    },
    {
      title: '账号7',
      dataIndex: 'account7'
    },
    {
      title: '账号8',
      dataIndex: 'account8'
    },
  ]
  const { data, isLoading } = useLoginUser(orderId) //登录账号查询
  return (
    <Table rowKey={record => record.id} columns={columns} loading={isLoading} dataSource={data?.rows} size="middle" pagination={false} />
  )
}

//打球明细的表格
export const PlayDetailTable = ({ orderId }) => {
  const columns = [
    {
      title: '项目名称',
      // dataIndex: 'projectName'
      render: (value) => {
        return <>{value?.projectName ? value?.projectName : value.typeName}</>
      }
    },
    {
      title: '开始时间',
      dataIndex: 'startTime'
    },
    {
      title: '结束时间',
      dataIndex: 'endTime'
    },
    {
      title: '时长',
      dataIndex: 'duration',
      render: (value) => {
        return <>{formatTime(value)}</>
      }
    },
    {
      title: '费用',
      dataIndex: 'originalTotalPrice'
    },
  ]
  const { data } = usePlayDetail(orderId) //打球明细
  return (
    <Table rowKey={record => record.id} columns={columns} dataSource={data?.rows} size="middle" pagination={false} />
  )
}

//打球明细的表格
export const GoodsTable = ({ orderId }) => {
  const merchantId = useMerchantId()
  const columns = [
    {
      title: '商品名称',
      dataIndex: 'goodsName'
    },
    {
      title: '数量',
      render: (value) => {
        return <>{value.goodsNum - value.chargebackNum}</>
      }
    },
    {
      title: '费用',
      dataIndex: 'consumptionAmount'
    }
  ]
  const { data } = useShopBuyList(orderId, merchantId) //打球明细
  return (
    <Table rowKey={record => record.id} columns={columns} dataSource={data?.rows} size="middle" pagination={false} />
  )
}

//结算方式的表格
export const PlaySettlementMethod = ({ buyData }) => {
  const [payList, setPayList] = useState([])
  const columns = [
    {
      title: '原总费用',
      dataIndex: 'originalTotalPrice'
    },
    {
      title: '身份折扣',
      dataIndex: 'discount'
    },
    {
      title: '余额抵扣',
      dataIndex: 'cardDeduction'
    },
    {
      title: '赠送抵扣',
      dataIndex: 'giftDeduction'
    },
    {
      title: '实际付款',
      dataIndex: 'actualPayment'
    },
    {
      title: '修改后的金额',
      dataIndex: 'modifyTotalAmount'
    },
    {
      title: '是否修改',
      dataIndex: 'isModified',
      render:(value)=>{
        return <span>{value?'是':'否'}</span>
      }
    }
  ]
  useEffect(() => {
    const payList = []
    payList.push(buyData)
    setPayList(payList)
  }, [buyData])

  return (
    <Table columns={columns} dataSource={payList} size="middle" pagination={false} />
  )
}