/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useReducer, useState } from "react"
import { useMountRef } from './index';

const defaultInitialState = {
  error: null,
  data: null,
  stat: 'idle'
}
const defaultConfig = {
  throwError: false
}
const useSafeDispatch = (dispatch) => {
  const mountedRef = useMountRef()
  return useCallback((value) => (mountedRef.current ? dispatch(value) : void 0), [mountedRef, dispatch])
}

export const useAsync = (initialState, initialConfig) => {
  const config = { ...defaultConfig, ...initialConfig }
  const [state, dispatch] = useReducer((state, action) => ({ ...state, ...action }), { ...defaultInitialState, ...initialState })
  const safeDispatch = useSafeDispatch(dispatch)
  const [retry, setRetry] = useState()

  const setData = useCallback((data) => {
    safeDispatch({
      error: null,
      data,
      stat: 'success'
    })
  }, [safeDispatch])

  const setError = useCallback((error) => {
    safeDispatch({
      error,
      data: null,
      stat: 'error'
    })
  }, [safeDispatch])

  const run = useCallback((promise, retryConfig) => {
    if (!promise || !promise.then) {
      throw Error('请传入 Promise 类型的数据')
    }
    if (retryConfig) {
      setRetry(() => () => run(retryConfig?.retry(), retryConfig))
    }

    safeDispatch({ stat: 'loading' }) //前面写reducer函数的时候把 state 和 action = {stat: 'loading'}的值都返回了

    return promise
      .then((data) => {      
        setData(data)
        return data
      })
      .catch(error => {
        setError(error)   
        if (config.throwError) return Promise.reject(error)
        return error
      })
  }, [config.throwError, setData, setError, safeDispatch])

  return {
    isIdle: state.stat === 'idle',
    isLoading: state.stat === 'loading',
    isError: state.stat === 'error',
    isSuccess: state.stat === 'success',
    run,
    setData,
    dispatch,
    setError,
    retry,
    ...state
  }
}