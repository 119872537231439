export const localStorageKey = 'auth_provider_token'

export const apiUrl = process.env.REACT_APP_API_URL
// export const webSocketUrl = process.env.REACT_APP_WS_URL //websocket

export const getToken = () => window.localStorage.getItem(localStorageKey)

export const handleUserResponse = (data) => {
  window.localStorage.setItem(localStorageKey, data?.access_token || '')
  return data
}

export const login = (data) => {
  return fetch(`${apiUrl}golfonlineAuth/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then(async response => {
    const res = await response.json()
    if (response.ok) {
      if (res.code === 200) { 
        return handleUserResponse(res.data)
      } else {
        return Promise.reject(res)
      }
    } else {
      return Promise.reject(res)
    }
  })
}


export const logout = async () => {
  window.localStorage.removeItem(localStorageKey)
}
