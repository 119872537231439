
import { useEffect, useState, useRef } from 'react';
import ManagerLock from '../../../assets/imgs/manager/manager_lock.svg'
import TimeCountDown from '../../../assets/imgs/manager/time_count_down.svg'
import ManagerFree from '../../../assets/imgs/manager/manager_free.svg'
import Tourist from '../../../assets/imgs/manager/tourist.svg'
import Vip from '../../../assets/imgs/manager/vip.svg'
import { Button, Modal } from 'antd';
import { useShutDownPlace } from './../../../utils/manager-admin/index';
import { message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import AppointmentIcon from '../../../assets/imgs/manager/appointment.svg'
import { useOpenDevice } from '../../../utils/manager-admin/order';

export const formatTime = (space) => {
  const h = parseInt(space / 3600) >= 10 ? parseInt(space / 3600) : '0' + parseInt(space / 3600)

  const mRemainder = space % 3600 // 分钟
  const m = parseInt(mRemainder / 60) >= 10 ? parseInt(mRemainder / 60) : '0' + parseInt(mRemainder / 60)

  const sRemainder = mRemainder % 60  //秒  
  const s = parseInt(sRemainder) >= 10 ? parseInt(sRemainder) : '0' + parseInt(sRemainder)

  return `${h}:${m}:${s}`
}

export const ClubPlaceStatus = ({ clubData, retryPlaceList, clubPlaceList }) => {
  const [countTime, setCountTime] = useState() //正在打球倒计时
  const [lockTime, setLockTime] = useState() //锁屏状态倒计时
  const lockTimer = useRef()
  const timer = useRef() //正在打球计时器
  const [newClubData, setNewClubData] = useState()

  const { shutDownPlace } = useShutDownPlace() //关机

  //正在打球倒计时
  useEffect(() => {
    setNewClubData(clubData)
    let newClubData = { ...clubData }
    //正在打球状态
    if (newClubData?.status === 2) {
      const nowTime = new Date().getTime()
      const endTime = new Date(newClubData?.endTime).getTime() //结束时间
      let space = (endTime - nowTime) / 1000 //秒
      if (space > 0) {
        setCountTime(formatTime(space)) //正在打球
      }
      timer.current && clearInterval(timer.current)
      timer.current = setInterval(() => {
        //正在打球
        if (space > 0) {
          space = space - 1
          setCountTime(formatTime(space))
        } else {
          //打球结束改变状态和显示锁屏定时
          let lockSpace = ((endTime + 600000) - new Date().getTime())
          // console.log(endTime, lockSpace)
          if (lockSpace > 0) {
            newClubData.simulatorState = 1
            setLockTime(formatTime(lockSpace / 1000))
            setNewClubData(newClubData)
          } else {
            newClubData.simulatorState = 0
            newClubData.status = 1
            setNewClubData(newClubData)
            timer.current && clearInterval(timer.current)
          }
        }

      }, 1000)
    }
    //锁屏状态
    if (newClubData?.simulatorState === 1) {
      const simulatorUnlockTime = new Date(newClubData?.simulatorUnlockTime).getTime()
      let lockSpaceTime = (simulatorUnlockTime - new Date().getTime()) / 1000
      if (lockSpaceTime > 0) {
        setLockTime(formatTime(lockSpaceTime))
      }
      lockTimer.current && clearInterval(lockTimer)
      lockTimer.current = setInterval(() => {
        if (lockSpaceTime > 0) {
          lockSpaceTime = lockSpaceTime - 1
          setLockTime(formatTime(lockSpaceTime))
        } else {
          newClubData.simulatorState = 0
          newClubData.status = 1
          setNewClubData(newClubData)
          lockTimer.current && clearInterval(lockTimer.current)
        }
      }, 1000)
    }
    return () => {
      timer.current && clearInterval(timer.current)
      lockTimer.current && clearInterval(lockTimer.current)
    }
  }, [clubData])
  const { openDevice } = useOpenDevice() //上机

  return (
    <div className='place_status_container'>
      <div className='place_status'>
        {
          newClubData?.simulatorState === 1 ? <div>
            {
              newClubData?.appointmentStartTime ? null : <div className="manager_lock flex-center mb-5">
                <div className='flex-column-center place_status_img'>
                  <img src={ManagerFree} alt="" />
                  <h5 className=''>创建订单</h5>
                </div>
              </div>
            }

            <div className='lock_screen_box flex-center'>
            <div className='lock_screen_wrapper flex-btw'>
              <div className='lock_screen_time'>锁屏中 {lockTime || '00:00:00'}</div>
              <Button
                style={{ padding: '0 10px', fontSize: '12px' }}
                shape="round"
                type="primary"
                ghost
                size='small'
                onClick={(e) => {
                  e.stopPropagation()
                  Modal.confirm({
                    title: '确认关机?',
                    icon: <ExclamationCircleOutlined />,
                    okText: '确认',
                    cancelText: '取消',
                    onOk: () => {
                      shutDownPlace(newClubData.id).then(res => {
                        if (res.code === 200) {
                          retryPlaceList()
                          message.success(res.msg)
                        } else {
                          message.error(res.msg)
                        }
                      })
                    }
                  });

                }}
              >立即关机</Button>
            </div>
            </div>
          </div> : <>
            {
              newClubData?.status === 2 ?
                <div className='place_playing'>
                  <div className="flex playing_item">
                    <img className='place_icon' src={newClubData?.identity ? Vip : Tourist} alt="" />
                    <h4>{newClubData?.userName || '玩家'}</h4>
                  </div>
                  <div className="flex playing_item">
                    <img className='place_icon' src={TimeCountDown} alt="" />
                    <span>倒计时：{countTime || '00:00:00'}</span>
                  </div>
                </div>
                : newClubData?.status === 3 ? <div className="manager_lock flex-center">
                  <div className='flex-column-center place_status_img'>
                    <img src={ManagerLock} alt="" />
                    <h5>锁定中</h5>
                  </div>
                </div> : <div className="manager_lock flex-center">
                  <div className='flex-column-center place_status_img'>
                    <img src={ManagerFree} alt="" />
                    <h5 className=''>{newClubData?.status === 4 ? '不可用' : '创建订单'}</h5>
                  </div>
                </div>
            }
          </>
        }
      </div>
      {
        newClubData?.appointmentStartTime ? <div className="make_appoint">
          <div className='flex'>
            <img className='place_icon' src={AppointmentIcon} alt="" />
            <div>
              <p>预约：{newClubData?.appointmentStartTime} - {newClubData?.appointmentEndTime}</p>
              <p>客户：{newClubData.appointmentPerson}</p>
            </div>
          </div>
          {
            newClubData.status !== 1 ? <div style={{ height: '16px' }}></div> :
              <Button onClick={(e) => {
                e.stopPropagation()
                openDevice(newClubData.appointmentOrderId).then(res => {
                  if (res.code === 200) {
                    message.success(res.msg)
                    retryPlaceList()
                  } else {
                    message.error(res.msg)
                  }
                })

              }} style={{ width: '100%', marginTop: '8px' }} type="primary" shape="round">开始</Button>
          }
        </div> : newClubData?.simulatorState === 1 ? null : <div className='make_appoint_empty'></div>
      }
    </div>
  )
}
