
import { BreadComponent } from './../../component/breadcrumb';
import { Button, Table, Popconfirm, Form, message, Typography, Divider, Space, DatePicker, Input } from 'antd'
import { useState, useReducer } from 'react';
import { DrawerModal, Footer } from './../../component/drawer-modal';
import { useChangePlace, useClubAppointmentList, usePlaceName } from '../../utils/manager-admin/point-list';
import { onTableChange, useRouteId } from '../../utils/common'
// import { formatTime } from './component/club-place-status';
import { useCancelAppointment } from './../../utils/manager-admin/point-list';
import { GetErrorBox, PutErrorBox } from '../../component/lib';
import { Link } from 'react-router-dom';
import { IdSelect } from '../../component/id-select';
import { useEffect } from 'react';
// import { usePlaceNames } from './../../utils/miniproject-admin/records';
const { RangePicker } = DatePicker;

export const Appointment = () => {
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const placeId = useRouteId()
  const [state, dispatch] = useReducer((state, action) => ({ ...state, ...action }), {
    page: 1,
    pageSize: 10,
    placeId,
    startTime: '',
    endTime: ''
  })
  const [placeInfo, setPlaceInfo] = useState()
  const breadcrumbNameMap = [
    {
      name: '打位管理',
      url: '/'
    },
    {
      name: '预约',
      url: ''
    }
  ]

  const { data: appointmentData, isLoading, retry, error } = useClubAppointmentList(state) //获取预约列表
  const appiontList = appointmentData?.rows
  //手动释放
  const { cancelAppointment, isLoading: cancelLoading, error: cancelError, setError: setCancelError } = useCancelAppointment()
  // const { data: placeNames } = usePlaceNames() //查询打位
  //手动释放
  const onCancel = (id) => {
    cancelAppointment(id).then(res => {
      if (res.code === 200) {
        message.success(res.msg)
        retry()
      }
    })
  }
  const columns = [
    {
      title: '订单编号',
      dataIndex: 'orderNo',
      sorter: (a, b) => a.orderNo.localeCompare(b.orderNo)
    },
    {
      title: '消费时间',
      dataIndex: 'createTime',
      sorter: (a, b) => new Date(a.createTime).getTime() - new Date(b.createTime).getTime()
    },
    {
      title: '预约人',
      dataIndex: 'appointmentPerson',
      sorter: (a, b) => a.appointmentPerson.localeCompare(b.appointmentPerson)
    },
    {
      title: '电话号码',
      dataIndex: 'telephone',
      sorter: (a, b) => a.telephone - b.telephone
    },
    {
      title: '预约时间',
      dataIndex: 'appointmentTime',
      sorter: (a, b) => new Date(a.appointmentTime).getTime() - new Date(b.appointmentTime).getTime()
    },
    {
      title: '预约状态',
      dataIndex: 'statusDict',
      render: (value, pro) => {
        return <>
          <Typography.Text type={pro.status === 2 ? 'danger' : 'default'}>{value}</Typography.Text>
        </>
      }
    },
    {
      title: '打位状态',
      dataIndex: 'placeStatusDict',
    },
    // {
    //   title: '距打位释放',
    //   render: (value) => {
    //     const endTime = new Date(value.endTime).getTime()
    //     const nowTime = new Date().getTime()
    //     let space = (endTime - nowTime) / 1000
    //     let time = ''
    //     if (space > 0) {
    //       time = formatTime(space)
    //     }
    //     return (
    //       <div>
    //         {
    //           value.status === 4 || value.status === 2 ? <span className='release_time'>{time}</span> : null
    //         }
    //         {
    //           value.status === 1 || value.status === 2 ? <Popconfirm
    //             onConfirm={() => onCancel(value.id)}
    //             loading={cancelLoading}
    //             placement="bottomRight"
    //             title={'确定手动释放该打位？'}
    //             okText="确认"
    //             cancelText="取消">
    //             <Button type='primary' size="small">手动</Button>
    //           </Popconfirm> : null
    //         }
    //       </div>
    //     )
    //   }
    // },
    {
      title: '操作',
      render: (value) => {
        return (
          <div className='flex'>
            {
              value.status === 1 || value.status === 4 || value.status === 2 || value.status === 8 ? <>
                <Link to={`/home/index/manager/order_info/${value.placeId}?orderId=${value.id}`} component={Typography.Link}>订单详情</Link>
                <Divider type="vertical" />
                <Typography.Link disabled={value.status !== 4} onClick={() => {
                  setVisible(true)
                  setPlaceInfo(value)
                }}>换机</Typography.Link>
                <Divider type="vertical" />
                <Popconfirm
                  onConfirm={() => onCancel(value.id)}
                  loading={cancelLoading}
                  placement="bottomRight"
                  title={'确定取消该订单？'}
                  okText="确认"
                  cancelText="取消">
                  <Typography.Link disabled={value.status !== 2 && value.status !== 1}>取消订单</Typography.Link>
                </Popconfirm>
              </> : null
            }
          </div>
        )
      }
    },
  ];

  //搜索
  const onFinish = (values) => {
    const { time } = values
    let startTime = undefined
    let endTime = undefined
    if (values.time) {
      startTime = new Date(time[0]).getTime()
      endTime = new Date(time[1]).getTime()
    }
    dispatch({
      ...values,
      startTime,
      endTime,
      time: ''
    })
  }

  return (
    <div className="appointment_main grid_display">
      <div className='breadcrumb'>
        <BreadComponent breadcrumbNameMap={breadcrumbNameMap} />
      </div>
      <div className='page_main'>
        <div className='search_box'>
          <Form className='flex-btw' form={form} layout='inline' autoComplete="off" onFinish={onFinish}>
            <Space size={20}>
              <Form.Item name="time" label="预约时间:">
                <RangePicker />
              </Form.Item>
              <Form.Item label="预约人" name="appointmentPerson">
                <Input placeholder="输入预约人" allowClear />
              </Form.Item>
              <Form.Item label="电话" name="telephone">
                <Input placeholder="输入电话" allowClear />
              </Form.Item>
            </Space>
            <Space size={15}>
              <Button htmlType='reset'>重置</Button>
              <Button type='primary' htmlType='submit'>搜索</Button>
            </Space>
          </Form>
        </div>
        <div className='page_inner'>
          <GetErrorBox error={error} />
          <PutErrorBox error={cancelError} setError={setCancelError} />
          <Table
            loading={isLoading}
            columns={columns}
            dataSource={appiontList}
            rowKey={record => record.id}
            onChange={(pagination) => onTableChange(pagination, dispatch)}
            pagination={{ pageSize: state.pageSize, total: appointmentData?.total, showQuickJumper: true, pageSizeOptions: [5, 10, 20, 30, 40, 50] }}
          />
        </div>
      </div>
      <DrawerModal title={'换机'} visible={visible} setVisible={setVisible}>
        <ChangePlace visible={visible} setVisible={setVisible} placeInfo={placeInfo} placeId={Number(placeId)} retry={retry} />
      </DrawerModal>
    </div>
  )
}

const ChangePlace = ({ visible, setVisible, placeId, placeInfo, retry }) => {
  const [form] = Form.useForm()
  const { data: placeNames } = usePlaceName() //查询打位名称
  const { changePlace, isLoading, error, setError } = useChangePlace()

  const onFinish = (values) => {
    changePlace({ ...values, id: placeInfo.id }).then(res => {
      if (res.code === 200) {
        setVisible(false)
        retry()
      }
    })
  }

  useEffect(() => {
    form.setFieldsValue({ placeId })
  }, [visible, form, placeId])

  return <Form layout='horizontal' form={form} onFinish={onFinish}>
    <PutErrorBox error={error} setError={setError} />
    <Form.Item name="placeId" label="更改至">
      <IdSelect options={placeNames?.rows} />
    </Form.Item>
    <Footer isLoading={isLoading} />
  </Form>
}
