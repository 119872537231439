import { Form, Input, Space, Checkbox, Switch, Button, TimePicker, Typography, Spin, message, Upload } from "antd"
import { UploadFile } from './../../../component/upload-file';
import { useEffect, useState } from 'react';
import moment from 'moment'
import { useAuth } from './../../../context/auth-context';
import { useStadiumBasics, useEditStadiumBasics } from './../../../utils/miniproject-admin/stadium-display';
import { GetErrorBox, PutErrorBox } from './../../../component/lib';
import { useProvince, useCity } from './../../../utils/miniproject-admin/stadium-display';
import { IdSelect } from "../../../component/id-select";
import { PrivilegedButton } from './../../../component/privileged-button';
// import { SwapRightOutlined } from '@ant-design/icons';
// import ImgCrop from 'antd-img-crop';
// import { apiUrl } from "../../../auth-provider";


export const StadiumDisplayBasics = () => {
  const { user } = useAuth()
  const merchantId = user.user.merchantId
  const { data: stadiumBasicsDtata, isLoading, error, retry } = useStadiumBasics() //获取基本信息
  const stadiumBasics = stadiumBasicsDtata?.data
  const { mutate, error: editError, setError: setEditError, isLoading: isEditLoading } = useEditStadiumBasics() //编辑基本信息
  const { data: provinceData } = useProvince() //查询省份
  const [province, setProvince] = useState() //省份
  const { data: cityData } = useCity(province) //查询城市

  const [form] = Form.useForm()
  const [isNoEdit, setIsNoEdit] = useState(true) //是否能编辑

  const [canOrderEdit, setCanOrderEdit] = useState(false)

  
  const [rotationsList, setRotationsList] = useState([]) //轮播图
  const [thumbnailsList, setThumbnailsList] = useState([]) //缩略图

  //提交表单
  const onFinish = (value) => {
    // console.log(value)
    //处理时间
    const start = value.openHours ? new Date(value.openHours[0]).getTime() : ''
    const end = value.openHours ? new Date(value.openHours[1]).getTime() : ''
    const openHours = start + '-' + end

    let rotations = []
    let thumbnails = []

    //处理缩略图
    const oldThumbnails = [...thumbnailsList]
    // console.log(oldThumbnails)
    if (oldThumbnails.length) {
      if (oldThumbnails[0].response) {
        thumbnails.push(oldThumbnails[0].response.data)
      } else {
        thumbnails = oldThumbnails
      }
    }

    //处理轮播图的图片
    const oldRotations = [...rotationsList]
    // console.log(oldRotations)
    if (oldRotations.length) {
      rotations = oldRotations.map(item => {
        if (item.response) {
          return item.response.data
        } else {
          return item
        }
      })
    }

    const params = {
      ...value,
      openHours,
      rotations,
      thumbnails,
      merchantId
    }
    // console.log(params)
    mutate(params).then(res => {
      if (res.code === 200) {
        message.success(res.msg)
        retry()
        setIsNoEdit(true)
      } else {
        setEditError(res)
      }
    })

  }

  //初始化表单
  useEffect(() => {
    //处理时间   
    const newOpenHours = stadiumBasics?.openHours?.split('-')
    if (newOpenHours?.length) {
      const openHours = newOpenHours?.map(item => moment(Number((item))))
      form.setFieldsValue({
        ...stadiumBasics,
        openHours
      })
    }
  }, [stadiumBasics, form])
  //获取城市
  useEffect(() => {
    setProvince(stadiumBasics?.province)
  }, [stadiumBasics?.province])

  return (
    <Spin size="large" spinning={isLoading}>
      <div className="stadium_display_basics">
        <div className="flex-btw">
          <h5 className="table_hd">基本信息</h5>
          <PrivilegedButton code="wechat:merchant:update">
            <Typography.Link onClick={() => setIsNoEdit(false)}>编辑</Typography.Link>
          </PrivilegedButton>
        </div>
        <GetErrorBox error={error} />
        <PutErrorBox error={editError} setError={setEditError} />
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          layout='vertical'>
          <Form.Item name="merchantName" label="商户名称：" rules={[{ required: true, message: '请输入商户名称' }]}>
            <Input placeholder="请输入商户名" disabled={isNoEdit} maxLength={50} allowClear />
          </Form.Item>
          <Form.Item name="customerServiceTelephone" label="客服电话：" rules={[{ required: true, message: '请输入客服电话' }]} >
            <Input placeholder="请输入客服电话" disabled={isNoEdit} maxLength={11} allowClear />
          </Form.Item>
          <Form.Item name="countryArea" label="国家/地区：" rules={[{ required: true, message: '请选择国家/地区' }]} >
            <Input disabled={isNoEdit} maxLength={36} allowClear />
          </Form.Item>
          <Space size={[15, 0]}>
            <Form.Item name="province" label="所在省市：" rules={[{ required: true, message: '请选择所在省市：' }]} >
              <IdSelect style={{ width: '150px' }} options={provinceData?.data} disabled={isNoEdit}
                onChange={(value) => {
                  setProvince(value)
                  form.setFieldsValue({
                    city: ''
                  })
                }} />
            </Form.Item>
            <Form.Item name="city" label=" ">
              <IdSelect style={{ width: '150px' }} options={cityData?.data} disabled={isNoEdit} />
            </Form.Item>
          </Space>
          <Form.Item name="address" label="详细地址：" rules={[{ required: true, message: '请输入详细地址' }]} >
            <Input placeholder="请输入详细地址" disabled={isNoEdit} maxLength={50} allowClear />
          </Form.Item>
          <UploadFile
            accept={'.png, .jpg, .jpeg'}
            isNoEdit={isNoEdit}
            param="rotation"
            name="rotations"
            maxCount={5}
            fileLists={stadiumBasics?.rotations}
            fileList={rotationsList}
            setFileList={setRotationsList}
            label="轮播图：(支持批量上传，上传格式为jpg，jpeg，png文件，建议尺寸690*345像素，文件大小不超过5M)"
            aspect={690 / 345}
          />
          <UploadFile
            accept={'.png, .jpg, .jpeg'}
            isNoEdit={isNoEdit}
            maxCount={1}
            param="thumbnails"
            name="thumbnails"
            fileLists={stadiumBasics?.thumbnails}
            fileList={thumbnailsList}
            setFileList={setThumbnailsList}
            label="列表缩略图：(上传格式为jpg，jpeg，png文件，建议尺寸176*176像素，文件大小不超过5M)"
            aspect={176 / 176}
          />
          {/* <UploadFile name="multimediaList2" isNoEdit={isNoEdit} type="上传视频" label="宣传视频：(文件大小不超过100M)" /> */}
          <Space size={32}>
            <Form.Item
              name="openHours"
              label="营业时间：" >
              <TimePicker.RangePicker format={'HH:mm'} minuteStep={30} secondStep={60} disabled={isNoEdit} />
            </Form.Item>
            <Form.Item name='suspendBusiness' label=' ' valuePropName="checked">
              <Checkbox onChange={(e) => {
                form.setFieldsValue({
                  canOrder: !e.target.checked
                })
                setCanOrderEdit(e.target.checked)
              }} disabled={isNoEdit}>休业</Checkbox>
            </Form.Item>
          </Space>
          <Form.Item
            name="canOrder"
            valuePropName="checked"
            label="预约：" >
            <Switch disabled={isNoEdit || canOrderEdit} />
          </Form.Item>
          {
            isNoEdit ? null : <Space>
              <Button type='primary' htmlType='submit' loading={isEditLoading}>提交</Button>
              <Button onClick={() => {
                setIsNoEdit(true)
                retry()
              }}>取消</Button>
            </Space>
          }
        </Form>
      </div>
    </Spin >
  )
}