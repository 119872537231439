
import { Table } from 'antd';

//table表格里面的行
export const ExpandedRow = ({detailList}) => {
  // console.log(detailList)
  const expandedColumns = [
    { title: '洞', dataIndex: 'memberNick', key: 'memberNick' },
    { dataIndex: 'h1', title: '1h', key: '1h' },
    { dataIndex: 'h2', title: '2h', key: '2h' },
    { dataIndex: 'h3', title: '3h', key: '3h' },
    { dataIndex: 'h4', title: '4h', key: '4h' },
    { dataIndex: 'h5', title: '5h', key: '5h' },
    { dataIndex: 'h6', title: '6h', key: '6h' },
    { dataIndex: 'h7', title: '7h', key: '7h' },
    { dataIndex: 'h8', title: '8h', key: '8h' },
    { dataIndex: 'h9', title: '9h', key: '9h' },
    { dataIndex: 'h10', title: '10h', key: '10h' },
    { dataIndex: 'h11', title: '11h', key: '11h' },
    { dataIndex: 'h12', title: '12h', key: '12h' },
    { dataIndex: 'h13', title: '13h', key: '13h' },
    { dataIndex: 'h14', title: '14h', key: '14h' },
    { dataIndex: 'h15', title: '15h', key: '15h' },
    { dataIndex: 'h16', title: '16h', key: '16h' },
    { dataIndex: 'h17', title: '17h', key: '17h' },
    { dataIndex: 'h18', title: '18h', key: '18h' },
    { title: '合计', dataIndex: 'total', key: 'total' },
  ]

  return <Table
    columns={expandedColumns}
    dataSource={detailList}
    colSpan={0}
    pagination={false}
    bordered={true}
    size={'small'}
    rowKey={record => record.total}
  />;
}