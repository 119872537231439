
import { Form, Space, Button, DatePicker } from 'antd';
import { Table } from 'antd';
import { useReducer } from 'react';
import { onTableChange, useMerchantId } from './../../../utils/common';
import { useRecordData, useRecordTotalData } from '../../../utils/business-admin/index'
import { PutErrorBox } from '../../../component/lib';
import { useEffect } from 'react';
import moment from 'moment';
import { formatTime } from './../../manager-admin/component/club-place-status';
const { RangePicker } = DatePicker;

export const PlayerDetailStatistic = ({ memberUserId }) => {
  const [form] = Form.useForm()
  const merchantId = useMerchantId()
  const [state, dispatch] = useReducer((state, action) => ({ ...state, ...action }), {
    page: 1,
    pageSize: 10,
    merchantId,
    merchantUserId: memberUserId,
    startTime: '',
    endTime: ''
  })
  const { data: recordData, error: listError, setError: setListError, isLoading } = useRecordData(state) //获取信息统计列表
  const recordList = recordData?.rows
  const { data: totalData } = useRecordTotalData(state) //获取统计总数
  const recordTotal = totalData?.data
  // console.log(recordList, recordTotal)

  const columns = [
    {
      title: '登录时间',
      dataIndex: 'startTime',
    },
    {
      title: '登出时间',
      dataIndex: 'endTime',
    },
    {
      title: '订单号',
      dataIndex: 'orderNo',
    },
    {
      title: '时长/h',
      dataIndex: 'duration',
      render: (value) => {
        return <>{formatTime(value)}</>
      }
    },
    {
      title: '消费总额',
      dataIndex: 'totalAmount',
    }
  ]
  const onFinish = (value) => {
    const date = value.date
    dispatch({
      startTime: date ? new Date(date[0]).getTime() : undefined,
      endTime: date ? new Date(date[1]).getTime() : undefined
    })
  }
  useEffect(() => {
    const date = []
    const startTime = moment(new Date().getTime() - 24 * 3600 * 1000)
    const endTime = moment(new Date().getTime())
    date[0] = startTime
    date[1] = endTime
    dispatch({
      startTime: new Date().getTime() - 24 * 3600 * 1000,
      endTime: new Date().getTime()
    })
    form.setFieldsValue({
      date
    })
  }, [form])
  return (
    <div className='player_detail_statistic_main'>
      <div className='page_main'>
        <div className="search_box">
          <PutErrorBox error={listError} setError={setListError} />
          <Form form={form} layout='inline' onFinish={onFinish}>
            <Form.Item name='date'>
              <RangePicker />
            </Form.Item>
            <Space>
              <Button htmlType='reset'>重置</Button>
              <Button type='primary' htmlType='submit'>查询</Button>
            </Space>
          </Form>
        </div>
        <div className='page_inner'>
          <h5 className='table_hd'>信息统计</h5>
          <Table
            loading={isLoading}
            columns={columns}
            scroll={{ y: '46vh', x: true }}
            rowKey={record => record.id}
            dataSource={recordList}
            onChange={(pagination) => onTableChange(pagination, dispatch)}
            pagination={{ pageSize: state.pageSize, total: recordData?.total, showQuickJumper: true, pageSizeOptions: [5, 10, 20, 30, 40, 50] }}
          />
        </div>
      </div>
      <div className='footer_total footer flex-btw'>
        <p>纪录数: <span>{recordTotal?.total || 0}</span></p>
        <p>总时长：<span>{recordTotal?.durations ? (recordTotal?.durations / 3600).toFixed(2) : 0}小时</span></p>
        <p>总额：<span>{recordTotal?.amounts || 0}元</span></p>
      </div>
    </div>
  )
}